import { Button, Col, Input, Row, Steps, message } from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Close from "../../assets/icons/close.png";
import Comment from "../../assets/icons/comment.png";
import ItemsTableShow from "../Common/itemsTableShow";
import BillData from "./Bills/BillData";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomerOrderComments,
  CustomerOrdersApprove,
  CustomerOrdersCancel,
  CustomerOrdersReject,
  customer_order_comments,
  customer_orders_cancel,
  customer_orders_show,
  get_order_comments,
  order_approve,
  order_reject,
} from "../../actions/orders/orders";
import BaseCurrency from "../Common/BaseCurrency";
import { amountFormat, convertCurrency, reverseCurrencyConverter } from "../../utils";
import useHandleResponse from "../Cart/useHandleResponse";
import usePayAxios from "../../config/useAxios";
import { apis } from "../../config/APIs";
import moment from "moment";
import NoCommentsComponent from "../Common/NoComentsComponent";
import DeleteConfirm from "../Common/DeleteConfirm";
import CreditPdf from "../PDFTemplates/CreditPdf";
import { getSymbol } from "../../config/helper";
import History from "../../assets/icons/time_icon.png";
import endpointSettingsApi from "../../config/Axios";
const OrderPageDetails = ({ fromOrder, hasWriteAccess }) => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const location = useLocation()
  const [input, setInput] = useState("");
  const [tab, setTab] = useState("details");
  const dispatch = useDispatch();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [rejectConfirm, setRejectConfirm] = useState(false);
  const [hasExchange, setHasExchange] = useState(false);
  const [exchange, setExchange] = useState({});
  const [noExError, setNoExError] = useState("");
  const [cmp_cur_id, setCmpCurId] = useState("");
  const order = useSelector(
    (state) => state?.orders?.orders_show?.data?.order || {}
  );
  const cancelOrder = useSelector((state) => state?.orders?.orders_cancel);
  const approveOrder = useSelector((state) => state?.orders?.orders_approve);
  const rejectOrder = useSelector((state) => state?.orders?.orders_reject);
  const orderComments = useSelector((state) => state?.orders?.order_comments);
  const comments = useSelector((state) => state?.orders?.get_order_comments?.data?.response?.comments);

  const { data: prefix, onCall: getPrefix } = usePayAxios({ api: apis.createPrefixCustomer, method: "post" });
  const { onCall: createEstimate } = usePayAxios({
    api: apis.createInvoice,
    method: "post"
  })
  const setExchangeCurrency = (id) => {
    // const customer = customers?.find((ele) => ele.value === id);
    const customer = order;
    setNoExError("");
    if (customer?.customer_details?.company_currency_id) {
      setHasExchange(
        customer?.customer_details?.company_currency_id !==
        customer?.customer_details?.company_currency_id
      );
      setCmpCurId(customer?.currency_info?.currency_id);
      endpointSettingsApi
        .get(apis.getCompanyBaseCurrency, {
          params: { company_currency_id: customer?.customer_details?.company_currency_id },
        })
        .then((res) => {
          if (
            !res?.data?.data?.live_exchange_rates?.rate &&
            !res?.data?.data?.manual_exchange_rates?.rate
          ) {
            message.error(
              <span className="messageText">{res?.data?.message}</span>
            );
            setHasExchange(false);
            setNoExError(res?.data?.message);
          }
          setHasExchange(true);
          setExchange(res?.data?.data);
        })
        .catch((e) => {
          message.error(
            <span className="messageText">{e?.response?.data?.message}</span>
          );
        });
    } else {
      setHasExchange(false);
      setExchange({});
    }
  };
  const onMount = () => {
    dispatch(
      customer_orders_show({
        order_id: params?.id,
      })
    );
  };

  useEffect(() => {
    onMount();
    getPrefix({
      params: {
        module_type: "ESTIMATE"
      }
    })
    dispatch(get_order_comments({
      order_id: params?.id
    }))
  }, []);

  useEffect(() => {
    if (order?.customer_details?.id && !localStorage.getItem("isCustomer") && !localStorage.getItem("isVendor")) {
      setExchangeCurrency(order?.customer_details?.company_currency_id)
    }
  }, [order])

  useHandleResponse(
    CustomerOrdersCancel,
    "Cancelled order successfully.",
    "Failed to cancel order.",
    cancelOrder,
    () => {
      onMount()
      setDeleteConfirm(false)
    }
  );

  useHandleResponse(
    CustomerOrderComments,
    "Commented successfully.",
    "Failed to comment order.",
    orderComments,
    () => {
      dispatch(get_order_comments({
        order_id: params?.id
      }))
    }
  );

  useHandleResponse(
    CustomerOrdersApprove,
    "Approved order successfully.",
    "Failed to approve order.",
    approveOrder,
    () => {
      onMount();
      const initialValues = {
        beneficiary_id: "",
        customer_name: "",
        invoice_date: moment(),
        total_amount: 0,
        total_tax_amount: 0,
        total_discount: 0,
        total_payable_amount: 0,
        gl_category: "-",
        gl_code: "-",
        customer_invoice_items: [],
        customFields: [],
      };
      initialValues.beneficiary_id = order?.customer_details?.id
      initialValues.customer_name = order?.customer_details?.beneficiary_name
      initialValues.total_amount = order?.total_price
      initialValues.total_payable_amount = order?.total_price
      initialValues.total_payable_amount = order?.total_price
      initialValues.bc_total_amount = reverseCurrencyConverter(order?.total_price, exchange);
      initialValues.bc_total_tax_amount = 0;
      initialValues.bc_total_discount = 0;
      initialValues.company_id = localStorage.getItem("company_id")
      initialValues.currency = exchange?.convert_currency
      initialValues.currency_datetime = exchange?.auto_currency ? exchange?.live_exchange_rates?.date : exchange?.manual_exchange_rates?.date
      initialValues.customer_invoice_items = order?.line_items?.map(ele => ({
        item_name: ele["Item Name"],
        item_id: ele["Item Id"],
        item_type: 0,
        hsn_code: "",
        unit_price: ele["Unit Price"],
        quantity: ele["Quantity"],
        tax_type: "",
        discount_percentage: 0,
        discount_amount: 0,
        tax_amount: 0,
        total_amount: ele["Amount"],
        bc_total_amount: reverseCurrencyConverter(ele?.["Amount"], exchange),
        bc_tax_amount: 0,
        bc_unit_price: reverseCurrencyConverter(ele?.["Unit Price"], exchange),
        bc_discount_amount: 0,
      }))
      initialValues.invoice_no = prefix?.data
      initialValues.company_currency_id = order?.customer_details?.company_currency_id
      createEstimate({
        data: initialValues,
        params: {
          module_type: "ESTIMATE"
        }
      })
    }
  );

  useHandleResponse(
    CustomerOrdersReject,
    "Rejected order successfully.",
    "Failed to reject order.",
    rejectOrder,
    () => {
      onMount()
      setRejectConfirm(false)
    }
  );

  const handleAddComment = () => {
    if (input?.trim() && params.id) {
      dispatch(
        customer_order_comments({
          body: input,
          order_id: params.id,
        })
      );
      setInput("")
    } else {
      message.error(<span className="messageText">{t('comments_required')}</span>);
    }
  };

  const items = [
    {
      key: "1",
      label: t("details"),
    },
    {
      key: "1",
      label: t("items"),
    },
    {
      key: "2",
      label: t("comments"),
    },
    {
      key: "3",
      label: t("history"),
    },
  ];

  const pdfColumn = [{
    lable: "Item & Description",
    key: "Item Name",
    width: 30
  }, {
    lable: "Qty",
    key: "Quantity",
    width: 15
  }, {
    lable: "Unit Price",
    key: "Unit Price",
    width: 30,

  },
  {
    lable: "Discount",
    key: "discount_percentage",
    width: 15,
    suffix: "%"
  },
  {
    lable: "Total Amount",
    key: "Amount",
    width: 30,

  }]

  return (
    <>
      {(order?.reject_reason || order?.cancel_reason) && (
        <div className='strip red'>
          {order?.reject_reason ? t("reason_reject") : t("cancel_reason")}: {order?.reject_reason ? order?.reject_reason : order?.cancel_reason}
        </div>
      )}
      <Row
        wrap={false}
        className="new-report-detail"
        style={{ flexGrow: 1, display: "flex" }}
      >
        <Col
          flex="auto"
          style={{ width: "100%", display: "flex", flexGrow: "1" }}
        >
          <div style={{ flexGrow: 1 }}>
            <Row>
              <Col span={24}>
                <div className="flex-between">
                  <div className="report-head">
                    <div className="title-sec">
                      <h3>
                        {t("Order#")}
                        {order?.order_number}
                      </h3>
                      <span className={`status status_${order?.status}`}>
                        {order?.status?.replace(/_/g, " ")}
                      </span>
                    </div>
                    <p>
                      <strong>Po Number:</strong>
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "normal",
                          color: "#1C2B36",
                          opacity: "0.51",
                          paddingLeft: "1rem",
                        }}
                      >
                        {order?.po_number ? order?.po_number : "-"}
                      </span>
                    </p>
                    <p>
                      <strong>Ordered By:</strong>
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "normal",
                          color: "#1C2B36",
                          opacity: "0.51",
                          paddingLeft: "1rem",
                        }}
                      >
                        {order?.customer_details?.beneficiary_name}
                      </span>
                    </p>
                  </div>
                  <div className="report-btns">
                    {fromOrder && (
                      <>
                        {/* <button
              // disabled={!hasWriteAccess}
              className="pp-secondary-button"
            // onClick={handleEdit}
            >
              <span>{t("edit")}</span>
            </button> */}
                        {order?.status === "pending_approval" && <button
                          // disabled={!hasWriteAccess}
                          className="reject"
                          onClick={() => {
                            setDeleteConfirm(true)
                          }}
                        >
                          <span>{t("cancel")}</span>
                        </button>}
                      </>
                    )}
                    {!fromOrder && (
                      <>
                        {" "}
                        {order?.status === "pending_approval" && <button
                          // disabled={!hasWriteAccess}
                          className="approve"
                          onClick={() => {
                            dispatch(
                              order_approve({
                                order_id: params?.id,
                                invoice_no: prefix?.data,
                              })
                            );
                          }}
                          disabled={approveOrder?.loading}
                        >
                          <span>{t("approve")}</span>
                        </button>}
                        {order?.status === "pending_approval" && <button
                          // disabled={!hasWriteAccess}
                          className="reject"
                          disabled={rejectOrder?.loading}
                          onClick={() => {
                            setRejectConfirm(true)
                          }}
                        >
                          <span>{t("reject")}</span>
                        </button>}
                      </>
                    )}
                    <button>
                      <span
                        className=" cursor"
                        onClick={() => {
                          // history.goBack();
                          history.push(`${location?.state?.from}`);
                        }}
                        style={{ marginRight: "1rem" }}
                      >
                        <img src={Close} width={20} alt={"close"} />
                      </span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                {/* {true ? (
                  <div className="pdf shimmer">
                    <div className="loader"></div>
                  </div>
                ) : ( */}
                <CreditPdf
                  balanceAmount={1000}
                  creditNoteNo={order?.order_number}
                  title={"Order"}
                  creditDate={order?.created_at}
                  invoiceNo={order?.order_number}
                  currency={order?.currency_info?.iso_code}
                  invoiceDate={order?.requested_shipping_date}
                  lineItems={order?.line_items}
                  taxRates={0.5}
                  subTotal={""}
                  TDSAmt={100}
                  billingAddressCity={order?.company_details?.city}
                  billingAddressState={order?.company_details?.state}
                  billingAddresszip={order?.company_details?.pincode}
                  billPhone={order?.company_details?.primary_phone}
                  billAddress={order?.company_details?.address}
                  billingCountry={order?.company_details?.country}
                  type="ESTIMATE"
                  taxAmount={"00"}
                  totalAmount={order?.total_price}
                  noteTitle={"Order Number"}
                  balanceTitle={"Balance"}
                  numTitle={"Order Number"}
                  address={order?.company_details?.address}
                  gst={order?.company_details?.gst_no}
                  dateTitle={"Shipping Date"}
                  discount={"00"}
                  benficiaryGST={order?.customer_details?.gstin}
                  benefiName={order?.customer_details?.beneficiary_name}
                  benefiEmail={order?.customer_details?.email}
                  isOrder={true}
                  column={pdfColumn}
                />
                {/* )} */}
              </Col>
              <Col
                span={12}
                style={{
                  paddingRight: "10px",
                }}
              >
                <div>
                  <div className="tabs-sec mb1">
                    <ul>
                      {items.map(
                        (ele) =>
                          ele?.label && (
                            <li
                              onClick={() => setTab(ele.label?.toLowerCase())}
                              className={`${tab.toLowerCase() === ele.label?.toLowerCase()
                                ? "active"
                                : ""
                                }`}
                            >
                              {ele.label}
                            </li>
                          )
                      )}
                    </ul>
                  </div>

                  {tab === "details" ? (
                    <div className="pr1 pl1">
                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          padding: "25px 36px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1rem",
                            lineHeight: "20px",
                            color: "#58666E",
                            fontWeight: "normal",
                          }}
                        >
                          {t("Amount")}
                        </span>

                        <span
                          style={{
                            fontSize: "1.7rem",
                            lineHeight: "34px",
                            color: "#1C2B36",
                            fontWeight: "600",
                          }}
                        >
                          <BaseCurrency symbol_code={order?.currency_info?.iso_code} /> {amountFormat(order?.total_price)}
                        </span>
                      </div>

                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                          padding: "25px 36px",
                        }}
                      >
                        <BillData
                          style={{ fontSize: "1.1rem" }}
                          label={"Shipping Date"}
                          value={order?.requested_shipping_date}
                          isDate={true}
                          documenttype={"datetime"}
                        />
                        <BillData
                          style={{ fontSize: "1.1rem" }}
                          label={"Total Quantity"}
                          value={order?.total_quantity}
                        />
                      </div>
                    </div>
                  ) : null}

                  {tab === "history" ? (
                    order?.histories?.length ? (
                      <div className="commentsSection pr1 pl1">
                        <Steps
                          direction="vertical"
                          size="small"
                          current={order.histories.length + 1 || 100}
                        >
                          {order.histories &&
                            order.histories.map(
                              ({ body, created_at, id, curr_status, user_name }) => (
                                <Steps.Step
                                  key={id}
                                  title={body}
                                  description={`on ${created_at} | Action: ${curr_status?.replace(/_/, " ")} by ${user_name}`}
                                  icon={
                                    <img
                                      src={History}
                                      alt='history'
                                      width={18}
                                    />
                                  }
                                />
                              )
                            )}
                        </Steps>
                      </div>
                    ) : (
                      <NoCommentsComponent />
                    )

                  ) : null}

                  {tab === "comments" ? (
                    <>
                      <div
                        className="addComment mb1 pr1 pl1"
                        style={{ display: "flex" }}
                      >
                        <Input
                          placeholder={t("enter_comment")}
                          value={input}
                          onInput={(e) => setInput(e.target.value.trimStart())}
                        />
                        <Button
                          // disabled={!hasWriteAccess}
                          key="1"
                          className="formButton ml1"
                          onClick={() => handleAddComment()}
                        >
                          {t("send")}
                        </Button>
                      </div>

                      {comments?.length ? (
                        <>
                          <div className='commentsSection pr1 pl1'>
                            <Steps
                              direction='vertical'
                              size='small'
                              current={comments.length + 1 || 100}
                            >
                              {comments &&
                                comments.map(
                                  ({ body, created_at, id, user_name: name }) => (
                                    <Steps.Step
                                      key={id}
                                      title={body}
                                      description={`on ${created_at} | Commented by: ${name}`}
                                      icon={
                                        <img
                                          src={Comment}
                                          alt='history'
                                          width={18}
                                        />
                                      }
                                    />
                                  )
                                )}
                            </Steps>
                          </div>
                        </>
                      ) : (
                        <NoCommentsComponent />
                      )}
                    </>
                  ) : null}

                  {tab === "items" ? (
                    <div className="commentsSection pr1 pl1">
                      <ItemsTableShow
                        itemsTableShowData={order?.line_items}
                        fromOrder={true}
                        // currency={order?.currency_iso}
                        // currencyInfo={order?.currency}
                        isOrder={true}
                      />
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <DeleteConfirm
        open={deleteConfirm}
        onCancel={() => {
          setDeleteConfirm(false)
        }}
        onConfirm={(val) => {
          dispatch(
            customer_orders_cancel({
              order: {
                id: params?.id,
                cancel_reason: val
              },
            })
          );
        }}
        headerTitle={`${t("please_specify_reason")} cancel`}
        loading={cancelOrder?.loading}
        name={"order"}
        text={"cancel"}
        isReject={true}
      />

      <DeleteConfirm
        open={rejectConfirm}
        onCancel={() => {
          setRejectConfirm(false)
        }}
        onConfirm={(val) => {
          dispatch(
            order_reject({
              order_id: params?.id,
              reject_reason: val
            })
          );
        }}
        loading={cancelOrder?.loading}
        name={"order"}
        text={"reject"}
        isReject={true}
      />
    </>
  );
};

export default OrderPageDetails;
