import { Input, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const CompanyPrimaryModal = ({ company, formik }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const updateCompanyProfileResponse = useSelector(
    (state) => state.companySetting.updateCompanyProfile || {}
  );

  React.useEffect(() => {
    const { data: { status = "" } = {} } = updateCompanyProfileResponse;
    if (status) {
      setIsModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanyProfileResponse]);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        title="Edit Company Details"
        className="right-alinged-modal"
        getContainer={false}
      >
        <div className="pp-form-item">
          <label>Primary Contact Name</label>

          <Input
            className="pp-form-input"
            placeholder="Enter Primary Contact"
            value={formik?.values?.primary_contact_name}
            onChange={formik.handleChange}
            name="primary_contact_name"
          />
        </div>
        <div className="pp-form-item">
          <label>Primary Contact Email</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Primary Contact Email"
            value={formik?.values?.primary_contact_email}
            onChange={formik.handleChange}
            name="primary_contact_email"
          />
        </div>

        <div className="pp-form-item">
          <label>Primary Phone</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Primary Phone"
            value={formik?.values?.primary_phone}
            onChange={formik.handleChange}
            name="primary_phone"
          />
        </div>

        <div className="action-btns mt2">
          <button className="send-btn">Update Company Details</button>
          <button
            className="back-btn"
            type="button"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <div className="flex-between mb1">
        <h3>Primary Contact</h3>
        {/* <button
          onClick={() => {
            setIsModalVisible(true);
          }}
          type="button"
        >
          Edit
        </button> */}
      </div>
      <div className="company-details primary-contact">
        <div className="item">
          <label>Primary Contact Name</label>
          <span>{company?.primary_contact_name}</span>
        </div>
        <div className="item">
          <label>Primary Contact Email Address</label>
          <span>{company?.primary_contact_email}</span>
        </div>
        <div className="item">
          <label>Primary Contact Number</label>
          <span>{company?.primary_phone}</span>
        </div>
      </div>
    </>
  );
};

export default CompanyPrimaryModal;
