import React, { useEffect, useRef, useState } from "react";
import noImg from "../../assets/images/no-img.png";
import camera from "../../assets/icons/camera.svg";

const dataURLToBlob = (dataURL) => {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

const CapTure = ({
  label,
  horizontal,
  onlyImg,
  imageUrl: url,
  onCapture,
  keyC,
  hasVal,
  hasVerified,
}) => {
  const [captureImage, setCaptureImage] = useState("");
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);

  useEffect(() => {
    if (!hasVal) {
      const startCamera = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          streamRef.current = stream;
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        } catch (error) {
          console.error("Error accessing camera:", error);
        }
      };
      startCamera();
    }
    return () => {
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [hasVal]);

  // useEffect(() => {
  //   if (videoRef?.current) {
  //     videoRef?.current?.play();
  //   }
  // }, [videoRef]);

  const captureFrame = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
    const tim = setTimeout(() => {
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach((track) => track.stop());
        clearTimeout(tim);
      }
    }, 200);
    const capturedFrame = canvas.toDataURL("image/png");
    setCaptureImage(capturedFrame);
    const blob = dataURLToBlob(capturedFrame);
    onCapture(blob);
  };

  return (
    <div
      style={{
        alignItems: horizontal && "center",
        display: "flex",
        flexDirection: horizontal ? "row" : "column",
      }}
    >
      <span
        style={{
          color: "#3B3B3B",
          marginBottom: 5,
          fontWeight: 600,
        }}
      >
        {label}
      </span>
      {captureImage ? (
        <div className="mt2 capture">
          <img className="capImg" src={`${captureImage}`} />{" "}
          {hasVal ? null : (
            <button
              className="capture-btn mt1"
              onClick={() => {
                onCapture("");
                setCaptureImage("");
                const startCamera = async () => {
                  try {
                    const stream = await navigator.mediaDevices.getUserMedia({
                      video: true,
                    });
                    streamRef.current = stream;
                    if (videoRef.current) {
                      videoRef.current.srcObject = stream;
                    }
                  } catch (error) {
                    console.error("Error accessing camera:", error);
                  }
                };
                startCamera();
              }}
            >
              Reset
            </button>
          )}
        </div>
      ) : null}
      {!captureImage && (
        <div className="mt2 capture">
          <div className="border_btm">
            <span className="top"></span>
            <div className="capture-video">
              <video autoPlay playsInline ref={videoRef} />
              <div className="top-right"></div>
              <div className="bottom-left"></div>
            </div>
          </div>
          <canvas ref={canvasRef} style={{ display: "none" }} />
          <button
            className="capture-btn align-items-center justify-center mt2"
            onClick={captureFrame}
            type="button"
          >
            <img
              src={camera}
              alt=""
              width={20}
              style={{
                marginRight: 10,
              }}
            />{" "}
            Capture Photo
          </button>
        </div>
      )}
    </div>
  );
};

export default CapTure;
