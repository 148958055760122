import { message } from "antd";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { gridApis } from "../../../config/GridApis";
import usePayAxios from "../../../config/useAxios";
import { FormInput, FormSelect } from "../../inputs";
import ButtonUpload from "../../inputs/ButtonUpload";
import useDocumentAxios from "../../../config/useDocument";
import { apis } from "../../../config/APIs";
import { v4 as uuidv4 } from "uuid";
import rclose from "./assets/close.png";
import eye from "./assets/eye.png";

const AdditionalInfo = ({
  next,
  back,
  mainData,
  dbAcc,
  crAcc,
  setMainData,
}) => {
  const { t } = useTranslation();
  const [listFiles, setListFiles] = useState([]);
  const purposes = useSelector(
    (state) =>
      state?.z_accs?.purposes?.data?.data?.responseData?.map((ele) => ({
        label: ele.description,
        value: ele.id,
      })) ?? []
  );

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      beneficiary_id: "78f78c0b-8cca-4415-bf8c-f834b3bb6c7f",
      ourparty_account_id: "41e6aeb3-6330-431f-b64f-2df93023c99a",
      description: "new payment",
      notes: "Payment for shoes",
      amount: "500.00",
      currency_code: "USD",
      ourparty_display_name: "Facebook India",
      // "record_id":"",
      due_date: "2024-09-26",
      enable_card_payments: false,
      documents: [],
      mail_to_counterparty: false,
    },
  });

  const {
    onCall: DocumentCreate,
    reset: DocumentCreateReset,
    loading: DocumentCreateLoading,
  } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });

  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const { onCall, loading } = usePayAxios({
    type: "grid",
    api: gridApis.initialisePayment,
    method: "post",
  });

  const { onCall: DocumentDelete, loading: DocumentDeleteLoading } =
    useDocumentAxios({
      api: apis.deleteDocument,
      method: "delete",
      baseURL: "document",
    });

  const onSubmit = (data) => {
    const payload = {
      amount: Number(mainData?.amount),
      currency_code: crAcc?.currency_code,
      sender_account_id: dbAcc?.id,
      beneficiary_id: crAcc?.id,
      detail_name: data.notes,
      recipient_amount: Number(mainData?.recipient_amount),
      sender_amount: mainData?.sender_amount ?? 0,
      exchange_fee: mainData?.exchange_fee ?? 0,
      exchange_rate: mainData?.exchange_rate ?? 0,
      purpose_code: data?.purpose ?? 1,
      account_mode_type:
        mainData?.method === "WITHIN_BANK" ? "WITHINBANK" : mainData?.method,
    };

    onCall({ data: payload })
      .then((res) => {
        if (res?.data?.endToEndId) {
          setMainData((b) => ({
            ...b,
            paymentId: res?.data?.endToEndId,
          }));
          next();
        } else if (res?.error) {
          message.error(<span className="messageText">{res?.message}</span>);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        }
      });
  };

  const handleFileUpload = async (fieldName, uploadFile) => {
    let uniqueNo = uuidv4();

    const formData = new FormData();
    formData.append("files", uploadFile?.file?.originFileObj);
    formData.append("name", fieldName);
    formData.append("record_type", "Document");
    formData.append("record_id", uniqueNo);

    const attachId = await DocumentCreate({
      data: formData,
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });
    if (attachId?.error == false) {
      await getBase64(uploadFile.file?.originFileObj, (url) => {
        setValue(fieldName, attachId?.data[0]);
        setListFiles((prevData) => {
          const newData = { ...prevData };
          const currentArray = newData[fieldName] || [];

          // Add new item
          currentArray.push({
            fieldName: fieldName,
            attachment_id: attachId?.data[0],
            uuid: uniqueNo,
            url: url,
            uploadFileName: uploadFile?.file?.name,
          });

          newData[fieldName] = currentArray?.filter(
            (items) => items?.attachment_id
          );
          return newData;
        });
      });
      DocumentCreateReset();
    }
  };

  const handleFileDelete = async (targetKey, targetValue, isRemove = false) => {
    const deleteData = await DocumentDelete({
      data: {
        ids: [targetValue],
      },
      params: {
        record_id: getObjectByFieldNameAndValue(targetKey, targetValue)?.uuid,
      },
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });

    if (deleteData?.error == false) {
      if (!isRemove) {
        setValue(targetKey, "");
      }
      setListFiles((prevData) => {
        return {
          ...prevData,
          [targetKey]: (prevData[targetKey] || []).map((item, i) => {
            if (item[targetKey] == targetValue) {
              return {
                ...item,
                attachment_id: "",
                url: "",
                uploadFileName: "",
                fieldName: "",
                uuid: "",
              };
            }
            return item;
          }),
        };
      });
    }
  };

  const getObjectByFieldNameAndValue = (targetKey, targetValue) => {
    return listFiles[targetKey]?.find(
      (ele) => ele.attachment_id === targetValue
    );
  };

  return (
    <div>
      <form className="w40 ml3" onSubmit={handleSubmit(onSubmit)}>
        <div className="pp-form-item">
          <FormSelect
            inline
            control={control}
            {...register("purpose")}
            errors={errors}
            label={t("Transfer purpose")}
            options={purposes}
            hideCreateOption={true}
          />
        </div>
        <div className="pp-form-item">
          <FormInput
            control={control}
            required={true}
            {...register("notes", {
              required: "Notes is required.",
            })}
            errors={errors}
            label={t("notes")}
          />
        </div>

        <div style={{ marginTop: "40px" }}>
          <ButtonUpload
            control={control}
            {...register(`payment_files`, {
              onChange: (e) => {
                handleFileUpload("payment_files", e.target.value);
              },
            })}
            errors={errors}
            label={t("attach_payments")}
            multiple={false}
          />
          <div className="imgs-wrap">
            {listFiles["payment_files"]?.map((ele) => {
              return (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="img">
                    <img src={ele.url} />
                    <img src={eye} alt="" className="file-eye" />
                  </div>
                  <img
                    src={rclose}
                    alt=""
                    className="file-close"
                    onClick={() => {
                      handleFileDelete("payment_files", watch("payment_files"));
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt3">
          <button type="button" className="back-btn mr2" onClick={() => back()}>
            Back
          </button>
          <button className="send-btn" disabled={loading}>
            Send Money
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdditionalInfo;
