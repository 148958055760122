import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import {
  Payments,
  GET_PAYMENT_DETAILS_BY_ID,
  CREATE_PAYMENT_REQUEST,
  SUBMIT_PAYMENT,
  REJECT_PAYMENT,
  APPROVE_PAYMENT,
  RECALL_PAYMENT,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
  EDIT_PAYMENT,
} from "./payments";
import { gridApis } from "../../config/GridApis";

function getAllPayments(req) {
  const page = req.payload?.page_number;
  const view = req.payload?.view;
  const txns = req.payload?.txns;
  const scheduled = req.payload?.scheduled;
  let request = txns
    ? gridApis.fetchAccountTransactions
    : apis.getAllPayments + `?module_type=${req.payload?.type}&view=${view}`;

  if (scheduled) {
    request = request + `&schedule=${scheduled}`;
  }
  if (page) {
    request = request + `&page_number=${page}`;
  }
  delete req?.payload?.type;
  delete req?.payload?.page_number;
  delete req?.payload?.view;
  delete req?.payload?.txns;
  delete req?.payload?.scheduled;
  return sagaFunctions(Payments, "post", request, req.payload, {}, true)();
}

function getPaymentDetailsByID(req) {
  const request = req?.payload.bulk
    ? apis.getBulkPaymentListByID
    : apis.getPaymentDetailsByID;
  delete req?.payload.bulk;
  return sagaFunctions(
    GET_PAYMENT_DETAILS_BY_ID,
    "get",
    request,
    req.payload,
    {},
    true
  )();
}

function createPaymentRequest(req) {
  const type = req.payload?.type;
  const request = apis.createPaymentRequest + `?module_type=${type}`;
  // Include other potential parameters here if needed
  return sagaFunctions(
    CREATE_PAYMENT_REQUEST,
    "post",
    request,
    req.payload,
    {},
    true
  )();
}

function submitPayment(req) {
  const type = req.payload?.type;
  let request =
    (type?.includes("BULK") ? apis.submitBulkPayment : apis.submitPayment) +
    `?module_type=${type}`;
  // Include other potential parameters here if needed
  delete req?.payload?.type;
  return sagaFunctions(
    SUBMIT_PAYMENT,
    "post",
    request,
    req.payload,
    {},
    true
  )();
}

function rejectPayment(req) {
  const type = req.payload?.type;
  let request =
    (type?.includes("BULK") ? apis.rejectBulkPayment : apis.rejectPayment) +
    `?module_type=${type}`;
  // Include other potential parameters here if needed
  delete req.payload?.type;
  return sagaFunctions(
    REJECT_PAYMENT,
    "post",
    request,
    req.payload,
    {},
    true
  )();
}

function approvePayment(req) {
  const type = req.payload?.type;
  let request =
    (type?.includes("BULK") ? apis.approveBulkPayment : apis.approvePayment) +
    `?module_type=${type}`;
  // Include other potential parameters here if needed
  delete req?.payload?.type;
  return sagaFunctions(
    APPROVE_PAYMENT,
    "post",
    request,
    req.payload,
    {},
    true
  )();
}

function recallPayment(req) {
  const type = req.payload?.type;
  let request =
    (type?.includes("BULK") ? apis.recallBulkPayment : apis.recallPayment) +
    `?module_type=${type}`;
  // Include other potential parameters here if needed
  delete req?.payload?.type;
  return sagaFunctions(
    RECALL_PAYMENT,
    "post",
    request,
    req.payload,
    {},
    true
  )();
}

function editPayment(req) {
  const type = req.payload?.type;
  let request =
    (type?.includes("BULK") ? apis.recallBulkPayment : apis.editPayment) +
    `?module_type=${type}`;
  // Include other potential parameters here if needed
  delete req?.payload?.type;
  return sagaFunctions(EDIT_PAYMENT, "get", request, req.payload, {}, true)();
}

function updatePayment(req) {
  const type = req.payload?.type;
  let request =
    (type?.includes("BULK") ? apis.recallBulkPayment : apis.updatePayment) +
    `?module_type=${type}`;
  // Include other potential parameters here if needed
  delete req?.payload?.type;
  return sagaFunctions(
    UPDATE_PAYMENT,
    "post",
    request,
    req.payload,
    {},
    true
  )();
}

function deletePayment(req) {
  const type = req.payload?.type;
  let request =
    (type?.includes("BULK") ? apis.recallBulkPayment : apis.deletePayment) +
    `?module_type=${type}`;
  // Include other potential parameters here if needed
  delete req?.payload?.type;
  return sagaFunctions(DELETE_PAYMENT, "get", request, req.payload, {}, true)();
}

export function* paymentsWatcher() {
  yield takeLatest(Payments.REQUEST, getAllPayments);
  yield takeLatest(GET_PAYMENT_DETAILS_BY_ID.REQUEST, getPaymentDetailsByID);
  yield takeLatest(CREATE_PAYMENT_REQUEST.REQUEST, createPaymentRequest);
  yield takeLatest(SUBMIT_PAYMENT.REQUEST, submitPayment);
  yield takeLatest(REJECT_PAYMENT.REQUEST, rejectPayment);
  yield takeLatest(APPROVE_PAYMENT.REQUEST, approvePayment);
  yield takeLatest(RECALL_PAYMENT.REQUEST, recallPayment);
  yield takeLatest(EDIT_PAYMENT.REQUEST, editPayment);
  yield takeLatest(UPDATE_PAYMENT.REQUEST, updatePayment);
  yield takeLatest(DELETE_PAYMENT.REQUEST, deletePayment);
}
