import React from "react";
import { FormInput, FormSelect } from "../../inputs";
import DateInput from "../../inputs/DateInput";
import UploadField from "../../inputs/UploadField";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Close from "../../../assets/icons/close.png";
import { useDispatch, useSelector } from "react-redux";
import { vendorsByName } from "../../../actions/purchases";
import moment from "moment";
import { convertCurrency, deleteFile, objectToFormData } from "../../../utils";
import ItemsTable from "./ItemsTable";
import CustomField from "./CustomFields";
import { Col } from "antd";
const NewBillForm = (props) => {
  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    errors,
    isPO,
    handleBack,
    setExchangeCurrency,
    vendorsByNameData,
    onVendorSearch,
    handlerNewVendor,
    exchange,
    setIsVisible,
    listFiles,
    setListFiles,
    hasExchange,
    c_fields,
    dataBeneficiary,
    setRemovedItemsData,
    setLoadingRef,
  } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const categoriesResponse = useSelector(
    (state) => state?.categories?.categories?.data?.data ?? []
  );
  const payment_terms = useSelector((state) =>
    state?.seed_data?.payment_terms?.data?.length
      ? state?.seed_data?.payment_terms?.data?.map((ele) => ({
          label: ele.term_name,
          value: parseInt(ele.id),
          number_of_days: parseInt(ele.number_of_days),
        }))
      : []
  );

  const place_of_supply = useSelector((state) =>
    state?.seed_data?.place_of_supply?.data?.length
      ? state?.seed_data?.place_of_supply?.data?.map((ele) => ({
          label: ele.seed_value,
          value: ele.seed_value,
        }))
      : []
  );
  const handlerNewPayTerms = () => {
    setIsVisible(true);
  };
  return (
    <div>
      <div className="head">
        <label>
          {isPO
            ? location?.state?.isEdit
              ? t("edit_purchase")
              : t("new_purchase")
            : location?.state?.isEdit
            ? t("edit_bill")
            : t("new_bill")}
        </label>
        <span className="close cursor mr2">
          <img
            src={Close}
            width={20}
            alt={"close"}
            onClick={() => handleBack()}
          />
        </span>
      </div>
      <div className="w40 pl2">
        <div className="pp-form-item">
          <FormSelect
            inline
            control={control}
            {...register("beneficiary_id", {
              required: "Vendor is required",
              onChange: (e) => {
                setExchangeCurrency(e.target.value);
                setValue(
                  "vendor_name",
                  vendorsByNameData.data?.data?.find(
                    (ele) => ele.id === e.target.value
                  )?.beneficiary_name
                );
              },
            })}
            required
            errors={errors}
            label={t("vendor_name")}
            placeholder={t("select_vendor")}
            onSearch={onVendorSearch}
            options={
              Array.isArray(vendorsByNameData.data?.data)
                ? vendorsByNameData.data?.data?.map((item) => ({
                    value: item.id,
                    label: item.beneficiary_name,
                  }))
                : []
            }
            onAddItem={handlerNewVendor}
            onFocus={() => {
              dispatch(
                vendorsByName({
                  beneficiary_name: "",
                  type: "vendor",
                  status: "active",
                })
              );
            }}
          />
        </div>
        {watch("beneficiary_id") && (
          <div
            style={{
              backgroundColor: "#F5F7FA",
              padding: "30px 27px",
              marginTop: "20px",
              fontSize: "17px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "17px",
                  lineHeight: "26px",
                  color: "#000000",
                }}
              >
                {t("Vendor Details")}
              </span>

              {/* <a style={{ color: '#ED3B41', fontSize: '17px' }}>Edit</a> */}
            </div>

            <Col style={{ paddingTop: "10px" }}>
              <div>
                {t("name")} -{" "}
                {
                  vendorsByNameData.data?.data?.find(
                    (ele) => ele.id === getValues().beneficiary_id
                  )?.beneficiary_name
                }
                <br />
                {t("email")} -{" "}
                {
                  vendorsByNameData.data?.data?.find(
                    (ele) => ele.id === getValues().beneficiary_id
                  )?.email
                }
                <br />
                {/* Address - {customers?.find(ele => ele.value === getValues().beneficiary_id)?.email} */}
              </div>
            </Col>
          </div>
        )}
        <div className="pp-form-item">
          <DateInput
            control={control}
            {...register("billing_date", {
              required: `${
                isPO ? "Purchase Date is required" : "Billing Date is required"
              }`,
            })}
            errors={errors}
            required={true}
            label={isPO ? t("purchase_date") : t("bill_date")}
            isTime={true}
          />
        </div>
        {!isPO && (
          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register("billing_no", {
                required: "Billing No is required",
              })}
              required={true}
              errors={errors}
              label={t("bill_no_hash")}
            />
          </div>
        )}
        <div className="pp-form-item">
          <FormInput
            control={control}
            {...register(isPO ? "billing_no" : "ref_purchase_order_no", {
              required: isPO ? "Purchase Oreder No is required" : false,
            })}
            required={isPO ? true : false}
            errors={errors}
            label={t("order_purchase")}
          />
        </div>

        <div className="pp-form-item">
          <FormSelect
            inline
            control={control}
            {...register("payment_terms", {
              required: "Payment terms is required",
              onChange: (e) => {
                let num = payment_terms?.find(
                  (ele) => ele.value === e.target.value
                )?.number_of_days;
                setValue(
                  "due_date",
                  moment(getValues().billing_date).add(num, "days")
                );
              },
            })}
            errors={errors}
            required={true}
            label={t("payment_term")}
            // placeholder="Select"
            options={[...payment_terms, { label: "Custom", value: "custom" }]}
            onAddItem={handlerNewPayTerms}
          />
        </div>

        <div className="pp-form-item">
          <FormSelect
            inline
            control={control}
            {...register("place_of_supply", {
              // onChange: (e) => {
              //   setValue(
              //     "due_date",
              //     moment(getValues().billing_date).add(e.target.value, "days")
              //   );
              // },
            })}
            errors={errors}
            label={t("place_of_supply")}
            placeholder={t("select")}
            options={[...place_of_supply]}
            hideCreateOption={true}
          />
        </div>
        <div className="pp-form-item">
          <DateInput
            control={control}
            {...register("due_date", {
              onChange: (date) => {
                setValue("payment_terms", "custom");
              },
            })}
            errors={errors}
            label={isPO ? t("expected_delivery_date") : t("payment_due_date")}
            minDate={getValues().billing_date}
            isTime={true}
          />
        </div>

        <>
          <div className="pp-form-item">
            <FormSelect
              inline
              control={control}
              {...register("gl_category", {
                required: "Category is required",
              })}
              required={true}
              errors={errors}
              label={t("category")}
              placeholder={t("select")}
              hideCreateOption
              // options={fields ? fields?.find(ele => ele.field_name === "category_id")?.default_values?.map(ele => ({ label: ele.value, value: ele.id })) : []}
              options={
                Array.isArray(categoriesResponse)
                  ? categoriesResponse
                      ?.filter((eachCategory) => eachCategory?.is_enable)
                      ?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))
                  : []
              }
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register("gl_code")}
              errors={errors}
              label={t("gl_code")}
            />
          </div>
        </>
        {c_fields?.length ? (
          <div style={{ marginTop: "40px" }}>
            <span className="info">{t("custom_fields")}</span>
            <CustomField
              setValue={setValue}
              name={"customFields"}
              watch={watch}
              fields={c_fields}
              register={register}
              control={control}
              errors={errors}
              type={isPO ? "PURCHASE_ORDER" : "BILL"}
              loadingRef={setLoadingRef}
            />
          </div>
        ) : null}
      </div>
      {hasExchange && exchange?.base_currency !== exchange?.convert_currency ? (
        <div className="mt1" style={{ position: "relative" }}>
          <div className="exchange-rate">
            <h5>Currency Exchange Rates: </h5>
            <p key={exchange?.convert_currency}>
              1 {exchange?.convert_currency} = {convertCurrency(1, exchange)}{" "}
              {exchange?.base_currency}
            </p>
          </div>
        </div>
      ) : null}
      <div className="p2">
        <ItemsTable
          exchange={exchange}
          hasExchange={hasExchange}
          isEdit={location?.state?.isEdit}
          isConvertOrClone={
            location?.state?.convert || location?.state?.isClone
          }
          control={control}
          errors={errors}
          register={register}
          name="vendor_billing_items"
          items={dataBeneficiary?.billing_items}
          watch={watch}
          setValue={setValue}
          vals={getValues()}
          setRemovedItemsData={setRemovedItemsData}
        />
      </div>
      <div className="w40 pl2 mb2">
        <div className="pp-form-item mb2">
          <FormInput
            control={control}
            {...register("notes")}
            errors={errors}
            label={t("notes")}
            type={"textarea"}
            rows={3}
          />
        </div>
        <UploadField
          title={t("attach_bill_file")}
          desc={t("material_desc")}
          subDescripcion={t("bill_desc")}
          acceptType={".jpg,.pdf,.png"}
          onSelected={(fileInfo) => {
            let listAux = [...listFiles];
            listAux.push(fileInfo);
            setListFiles(listAux);
          }}
          filesList={listFiles}
          onRemove={(index) => {
            let listAux = [...listFiles];
            if (listAux[index].isEdit) {
              deleteFile(
                objectToFormData({
                  id: location.state?.id,
                  attachment_id: listAux[index].uid,
                  record_type: "vendor_billing_detail",
                })
              );
            }
            listAux.splice(index, 1);
            setListFiles(listAux);
          }}
        />
      </div>
    </div>
  );
};

export default NewBillForm;
