import { Divider } from "antd";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

const CardReplacementForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [shippingAddress, setShippingAddress] = useState({
    companyAddress: "3713 Irving Park Ave, Beachwood, Ohio (OH), 44122",
    useDifferentAddress: false,
    customAddress: "",
  });

  const watchUseDifferentAddress = watch("useDifferentAddress", false);

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    console.log(
      "Shipping Address:",
      data.useDifferentAddress
        ? data.customAddress
        : shippingAddress.companyAddress
    );
  };

  return (
    <form className="block-card-form" onSubmit={handleSubmit(onSubmit)}>
      <h3>Choose Reason</h3>
      <p>Replace Physical card •• 5546</p>
      <div className="mt2">
        <label className="label">What Happened to your card ?</label>
        <div className="reason-radios">
          <label>
            <input
              type="radio"
              value="Lost"
              {...register("reason", { required: true })}
            />
            Lost
          </label>
          <label>
            <input
              type="radio"
              value="Stolen"
              {...register("reason", { required: true })}
            />
            Stolen
          </label>
          <label>
            <input
              type="radio"
              value="Damaged"
              {...register("reason", { required: true })}
            />
            Damaged
          </label>
          <label>
            <input
              type="radio"
              value="Never Received"
              {...register("reason", { required: true })}
            />
            Never Received
          </label>
          <label>
            <input
              type="radio"
              value="Other"
              {...register("reason", { required: true })}
            />
            Other
          </label>
        </div>
      </div>
      {errors.reason && (
        <p style={{ color: "red" }}>Please select a reason for replacement</p>
      )}

      <p className="title">
        We’ll cancel your existing card and issue a new card to you.
      </p>
      <Divider />

      <h3>Confirm Shipping Address</h3>
      <div className="mt2">
        <div className="merchant-lock">
          <input
            type="checkbox"
            {...register("useDifferentAddress")}
            className="hidden-checkbox"
            id="limit-check"
          />
          <span
            className="custom-checkbox-box"
            onClick={() => {
              document.getElementById("limit-check").click();
            }}
          ></span>
          <p className="title">Card wants to replace</p>
        </div>

        {watchUseDifferentAddress && (
          <div className="ship-addr mt2">
            <label>Ship Physical card to:</label>
            <div className="mail">
              <p>
                <span className="title">Company mailing address</span>
                {shippingAddress.companyAddress}
                <span className="link">Use Different Address</span>
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="action-btns mt2">
        <button className="back-btn " type="submit">
          Cancel
        </button>
        <button className="send-btn " type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default CardReplacementForm;
