import { MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Space,
  Switch,
  Table,
  message,
  Badge,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateCompanyMultipleCurrency,
  getAllCompanyDetails,
  getCompanyMultipleCurrency,
  toggleExchangeRate,
} from "../../../actions/companySettings";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import AddNewIcon from "../../../assets/icons/add_new_white.png";
import DeleteIcon from "../../../assets/icons/delete.png";
import EditIcon from "../../../assets/icons/edit.png";
import ViewImage from "../../../assets/icons/eye.svg";
import { apis } from "../../../config/APIs";
import useForgoCardsAxios from "../../../config/useForgoCardsAxios";
import AddCurrencies from "./AddCurrencies";
import AddExhangeRate from "./AddExchangeRate";
import "./currencies.css";
import ViewExchangeRates from "./ViewExchangeRates";
import { formatDate } from "../../Sales/Common";
import { amountFormat } from "../../../utils";
import endpointSettingsApi from "../../../config/Axios";
import country from "./country.json";
import TableComponent from "../../Common/TableComp/TableComp";

const Currencies = ({ hasWriteAccess }) => {
  const { onCall } = useForgoCardsAxios({
    api: apis.deleteCompanyCurrencyAPIs,
    method: "post",
    payApi: "settings",
  });
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExchangeRateVisible, setIsExchangeRateVisible] = useState(false);
  const [isViewExchangeRates, setIsViewExchangeRates] = useState(false);
  const [isClick, setIsClick] = useState(true);
  const [isExchangeModalOpen, setIsExchangeModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRecordId, setIsRecordId] = useState();
  const [isRecord, setIsRecord] = useState();
  const [baseCurrency, setBaseCurrency] = useState({});
  const [isSwitch, setIsSwitch] = useState(false);
  const getMultiCurrencyResponse = useSelector(
    (state) => state?.companySetting?.getCompanyMultipleCurrency?.data?.data
  );
  const createCurrencyResponse = useSelector(
    (state) => state?.companySetting?.createCompanyMultipleCurrency?.data
  );
  const companyDetails = useSelector(
    (state) => state?.companySetting?.getAllCompanyDetails?.data?.data
  );

  const getCompanyBaseCurrency = async () => {
    const result = await endpointSettingsApi.get(apis.getCompanyBaseCurrency, {
      params: { base_currency: true },
    });
    setBaseCurrency(result?.data?.data?.base_currency);
  };

  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Currencies",
        description: "",
      })
    );
    dispatch(getCompanyMultipleCurrency());
    dispatch(getAllCompanyDetails());
    getCompanyBaseCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsSwitch(companyDetails?.auto_currency_enabled);
  }, [companyDetails]);

  useEffect(() => {
    if (createCurrencyResponse?.status === 200) {
      message.success(
        <span className="messageText">{createCurrencyResponse?.message}</span>
      );
      dispatch({
        type: CreateCompanyMultipleCurrency.RESET,
      });
      dispatch(getCompanyMultipleCurrency());
      setIsModalVisible(false);
      setIsExchangeModalOpen(false);
    }
    if (createCurrencyResponse?.error) {
      message.error(
        <span className="messageText">{createCurrencyResponse?.message}</span>
      );
      dispatch({
        type: CreateCompanyMultipleCurrency.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCurrencyResponse]);

  const handleDisable = () => {
    setIsClick(!isClick);
    setIsExchangeModalOpen(false);
    dispatch(toggleExchangeRate({ enable: !isSwitch }));
    setIsSwitch(!isSwitch);
  };
  const handleDelete = (id) => {
    setIsRecordId(id);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteCurrency = () => {
    onCall({
      data: { id: isRecordId },
    })
      .then((data) => {
        setIsDeleteModalOpen(false);
        message.success(<span className="messageText">{data?.message}</span>);
        setTimeout(() => {
          dispatch(getCompanyMultipleCurrency());
        }, 100);
      })
      .catch((error) => {
        message.error(
          <span className="errorMsg">{error?.response?.data?.message}</span>
        );
        setIsDeleteModalOpen(false);
      });
  };

  const handleEditCurrecy = (record) => {
    setIsRecord(record);
    setIsModalVisible(true);
  };
  const handleAddCurrency = () => {
    setIsRecord("");
    setIsModalVisible(true);
  };

  const handleViewExchangeRates = (record) => {
    setIsViewExchangeRates(true);
    setIsRecord(record);
  };

  const handleAddExchangeRate = (record) => {
    setIsExchangeRateVisible(true);
    setIsRecord(record);
  };

  const columns = [
    {
      title: "Currency Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          <img
            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${record?.currency?.country_code?.toLowerCase()}.svg`}
            className="mr1"
            style={{
              width: "2rem",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
            }}
          />
          <div
            style={{
              display: "inline-flex",
            }}
          >
            {text}
            {record?.base_currency && (
              <div className="ml1">
                <Tag color="#00B148">Base Currency</Tag>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Currency Code",
      dataIndex: "iso_code",
      key: "iso_code",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbols",
    },
  ];

  const ExhangeRateColumn = !isSwitch
    ? [
        {
          title: `Exchange Rate (${baseCurrency?.iso_code})`,
          dataIndex: "exchange_rates",
          key: "exchange_rates",
          render: (exchange_rates) => (
            <div>{amountFormat(exchange_rates?.[0]?.exchange_rate ?? 0)}</div>
          ),
        },
        {
          title: " As of Date",
          dataIndex: "exchange_rates",
          key: "exchange_rates",
          render: (exchange_rates) => (
            <div>{formatDate(exchange_rates?.[0]?.exchange_date) ?? "-"}</div>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <Space key={record.id} size="middle">
              <button
                disabled={!hasWriteAccess}
                onClick={() => handleEditCurrecy(record)}
              >
                <img src={EditIcon} alt={"logo"} className="action-logo" />
              </button>
              {/* {record.key !== '1' ?
                        <button
                            onClick={() => handleDelete(record.id)}
                        ><img src={DeleteIcon} alt={"logo"} className="action-logo"
                            /></button> : null} */}
            </Space>
          ),
        },
        {
          title: "",
          key: "action",
          render: (_, record) =>
            !record?.base_currency && (
              <Space
                size="middle"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() =>
                          hasWriteAccess && handleAddExchangeRate(record)
                        }
                      >
                        <img src={EditIcon} alt={""} className="action-logo" />
                        {"Add Exchange Rate"}
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          hasWriteAccess && handleViewExchangeRates(record)
                        }
                      >
                        <img
                          src={ViewImage}
                          alt={"View Details"}
                          className="action-logo"
                        />
                        {"View Exchange Rates"}
                      </Menu.Item>
                      {!record?.base_currency && (
                        <Menu.Item
                          onClick={() => {
                            hasWriteAccess && handleDelete(record.id);
                          }}
                        >
                          <img
                            src={DeleteIcon}
                            alt={"logo"}
                            className="action-logo"
                          />{" "}
                          Delete
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button
                    shape="default"
                    icon={<MoreOutlined />}
                    style={{ border: "none", color: "#5B87F9" }}
                  />
                </Dropdown>
              </Space>
            ),
        },
      ]
    : [
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <Space key={record.id} size="middle">
              <button
                disabled={!hasWriteAccess}
                onClick={() => handleEditCurrecy(record)}
              >
                <img src={EditIcon} alt={"logo"} className="action-logo" />
              </button>
              {record.key !== "1" && !record?.base_currency ? (
                <button onClick={() => handleDelete(record.id)}>
                  <img src={DeleteIcon} alt={"logo"} className="action-logo" />
                </button>
              ) : null}
            </Space>
          ),
        },
      ];
  return (
    <>
      <Modal
        title={`${isSwitch ? "Disable" : "Enable"} Automatic Exchange Rates`}
        open={isExchangeModalOpen && hasWriteAccess}
        onCancel={() => setIsExchangeModalOpen(false)}
        maskClosable={false}
      >
        <p className="modal-text">
          {isSwitch
            ? "You will have to manually enter the exchange rates for each currency, if you disable this feature."
            : "The exchange rates for the currencies will automatically be fetched in real time."}
        </p>

        <div
          className="form-btm mt2"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <button
            type="primary"
            htmlType="submit"
            className="send-btn "
            id="save"
            onClick={handleDisable}
          >
            <span>{isSwitch ? "Disable" : "Enable"}</span>
          </button>
          <button
            className="back-btn"
            style={{ marginLeft: "2rem" }}
            onClick={() => setIsExchangeModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal
        title="Delete Currency"
        open={isDeleteModalOpen && hasWriteAccess}
        onCancel={() => setIsDeleteModalOpen(false)}
        maskClosable={false}
      >
        <p className="modal-text">
          Are you sure you want to delete this currency?
        </p>

        <div
          className="form-btm mt2"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="pp-main-button "
            id="save"
            onClick={handleDeleteCurrency}
          >
            <span>Delete</span>
          </Button>
          <Button
            className="pp-secondary-button"
            style={{ marginLeft: "2rem" }}
            onClick={() => setIsDeleteModalOpen(false)}
          >
            <span>Cancel</span>
          </Button>
        </div>
      </Modal>
      {isModalVisible && (
        <AddCurrencies
          open={isModalVisible && hasWriteAccess}
          onCancel={() => setIsModalVisible(false)}
          record={isRecord ? isRecord : ""}
        />
      )}
      <AddExhangeRate
        open={isExchangeRateVisible && hasWriteAccess}
        onCancel={() => setIsExchangeRateVisible(false)}
        record={isRecord ? isRecord : ""}
        baseCurrency={baseCurrency}
      />
      {isViewExchangeRates && (
        <ViewExchangeRates
          open={isViewExchangeRates && hasWriteAccess}
          onCancel={() => setIsViewExchangeRates(false)}
          record={isRecord ? isRecord : ""}
          hasWriteAccess
          baseCurrency={baseCurrency}
        />
      )}
      <div>
        <div className="btn-section">
          <button
            disabled={!hasWriteAccess}
            onClick={handleAddCurrency}
            className="mb1 send-btn"
          >
            New Currency{" "}
          </button>

          <Button
            disabled={!hasWriteAccess}
            className="exchange-rates-btn"
            onClick={() => setIsExchangeModalOpen(true)}
          >
            {" "}
            <Switch
              size="small"
              checked={isSwitch}
              className="switch-btn"
            />{" "}
            {isSwitch ? "Disable" : "Enable"} Automatic Exchange Rates
          </Button>
        </div>
        <div className="exchange-note">
          Live exchange rates are automatically fetched. To manually update
          exchange rate for each currency, disable automatic exchange rates.
        </div>
        <TableComponent
          rowKey="id"
          columns={[...columns, ...ExhangeRateColumn]}
          data={
            Array.isArray(getMultiCurrencyResponse)
              ? getMultiCurrencyResponse
              : []
          }
          className="settingsTable"
          hasCheckbox={false}
        />
      </div>
    </>
  );
};

export default Currencies;
