import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { transactions } from "../../actions/cards/cards";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import sync from "../../assets/icons/sync.png";
import BaseCurrency from "../Common/BaseCurrency";
import TableComponent from "../Common/TableComp/TableComp";
import TableHeader from "../Sales/TableHeader";

const CardTxns = ({ cardData, hasWriteAccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const modalColumns = [
    {
      title: t("trans_id"),
      dataIndex: "vendor_transaction_id",
      key: "vendor_transaction_id",
    },
    {
      title: t("dateAndTime"),
      dataIndex: "transaction_date",
      key: "transaction_date",
    },
    {
      title: t("term_id"),
      dataIndex: "terminalId",
      key: "terminalId",
    },
    // {
    //   title: "Wallet Type",
    //   dataIndex: "remaining",
    //   key: "remaining",
    //   render: (text) => <div>Meal Wallet</div>,
    // },
    {
      title: t("merchant_name"),
      dataIndex: "merchant",
      key: "merchant",
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <div>
          <img
            src={record?.transaction_type === "credit" ? credit : debit}
            width={10}
            alt="credit"
          />{" "}
          <BaseCurrency /> {text}
        </div>
      ),
    },
    {
      title: t("wallet_name"),
      dataIndex: "wallet_name",
      key: "wallet_name",
    },
    {
      title: t("transaction_nature"),
      dataIndex: "channelType",
      key: "channelType",
      render: (text, record) => <div>{record?.data?.txnType}</div>,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },
  ];
  return (
    <section>
      <div className="setting_header">
        <div className="pt1 pl1 pr1">
          <TableHeader noAddNewBtn={true} />
        </div>
      </div>
      <div className="paycraft-table">
        <div className="p1">
          <TableComponent
            rowKey="id"
            columns={modalColumns}
            placeholder={t("search_by_transaction_id")}
            btnsOnly={true}
            btnsComponent={
              <>
                <button
                  className="nm-btn"
                  onClick={() => {
                    // dispatch(
                    //   analytics_cards_export({
                    //     page,
                    //     card_id: cardData.id,
                    //   })
                    // );
                  }}
                >
                  {t("export_pdf")}
                </button>
                <button
                  disabled={!hasWriteAccess}
                  className="ml1 nm-btn"
                  onClick={() => {
                    if (cardData.id) {
                      dispatch(
                        transactions({
                          card_id: cardData.id,
                          refresh: true,
                        })
                      );
                    }
                  }}
                >
                  <img src={sync} alt="" width="14" className="mr1" />
                  {t("sync")}
                </button>
              </>
            }
            hasCheckbox={false}
          />
        </div>
      </div>
    </section>
  );
};

export default CardTxns;
