import { Input, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const CompanyAddressModal = ({ company, formik }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const updateCompanyProfileResponse = useSelector(
    (state) => state.companySetting.updateCompanyProfile || {}
  );

  React.useEffect(() => {
    const { data: { status = "" } = {} } = updateCompanyProfileResponse;
    if (status) {
      setIsModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanyProfileResponse]);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        title="Edit Company Details"
        className="right-alinged-modal"
        getContainer={false}
      >
        <div className="pp-form-item">
          <label>Address</label>

          <Input.TextArea
            className="pp-form-input"
            placeholder="Enter Address"
            value={formik?.values?.address}
            onChange={formik.handleChange}
            name="address"
          />
        </div>
        <div className="pp-form-item">
          <label>City</label>
          <Input
            className="pp-form-input"
            placeholder="Enter City"
            value={formik?.values?.city}
            onChange={formik.handleChange}
            name="city"
          />
        </div>

        <div className="pp-form-item">
          <label>State</label>
          <Input
            className="pp-form-input"
            placeholder="Enter State"
            value={formik?.values?.state}
            onChange={formik.handleChange}
            name="state"
          />
        </div>

        <div className="pp-form-item">
          <label>Country</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Country"
            value={formik?.values?.country}
            onChange={formik.handleChange}
            name="country"
          />
        </div>

        <div className="pp-form-item">
          <label>Zipcode</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Pincode"
            defaultValue={company?.pincode}
            onChange={formik.handleChange}
            name="pincode"
          />
        </div>

        <div className="pp-form-item">
          <label>Office Number</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Office Number"
            defaultValue={company?.office_no}
            onChange={formik.handleChange}
            name="office_no"
          />
        </div>
        <div className="action-btns mt2">
          <button className="send-btn">Update Company Address</button>
          <button
            className="back-btn"
            type="button"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <div className="flex-between mb1">
        <h3>Company Address</h3>
        {/* <button
          onClick={() => {
            setIsModalVisible(true);
          }}
          type="button"
        >
          Edit
        </button> */}
      </div>
      <div className="company-address company-details">
        <div className="item">
          <label>Company Legal Address</label>
          <span>
            {company?.address && (
              <>
                {company.address?.[0]?.street_one}
                <br />
              </>
            )}
            {company?.address?.[0]?.city && (
              <>
                {company?.address?.[0]?.city}
                <br />
              </>
            )}
            {company?.address?.[0]?.state && (
              <>
                {company?.address?.[0]?.state}
                <br />
              </>
            )}
            {company?.address?.[0]?.country && (
              <>
                {company?.address?.[0]?.country}
                <br />
              </>
            )}
            {company?.address?.[0]?.zipcode && <>{company?.address?.[0]?.zipcode}</>}
          </span>
        </div>
        {/* <div className="item">
          <label>Company Mailing Address</label>
          <span>
            {company?.address && (
              <>
                {company.address}
                <br />
              </>
            )}
            {company?.city && (
              <>
                {company.city}
                <br />
              </>
            )}
            {company?.state && (
              <>
                {company.state}
                <br />
              </>
            )}
            {company?.country && (
              <>
                {company.country}
                <br />
              </>
            )}
            {company?.pincode && <>{company.pincode}</>}
          </span>
        </div> */}
      </div>
    </>
  );
};

export default CompanyAddressModal;
