import React from "react";
import d_icon from "../../../../assets/icons/d_icon.png";
import down from "../../../../assets/icons/down.png";
const documents = [
  {
    title: "Passport",
    description: "Upload a photo or scan of your passport photo page.",
  },
  {
    title: "ID Card",
    description: "Upload a photo or scan of your passport photo page.",
  },
  {
    title: "Driver License",
    description: "Upload a photo or scan of your passport photo page.",
  },
  {
    title: "Resident Permit",
    description: "Upload a photo or scan of your passport photo page.",
  },
];

const DocumentList = ({ setDoc }) => {
  return (
    <div className="document-list">
      {documents.map((doc, index) => (
        <div
          key={index}
          className="document-card"
          onClick={() => {
            setDoc(doc);
          }}
        >
          <div className="document-icon">
            <img src={d_icon} />
          </div>
          <div className="document-info">
            <h3>{doc.title}</h3>
            <p>{doc.description}</p>
          </div>
          <div className="document-action">
            <span className="arrow-icon">
              <img src={down} width={12} />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DocumentList;
