import {
  AppstoreOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { currencyMapper } from "../../config/helper";
import { amountFormat, hasAccess } from "../../utils";
import AccTxnTable from "../Dashboard2/AccTxnTable";
import BankAccount from "../Dashboard2/BankAccountSec";
import AccountCard from "./AccountCard";
import search from "../../assets/ippo_icons/search.png";
import plus_b from "../../assets/images/plus_circle_b.png";
import { gridApis } from "../../config/GridApis";
import usePayAxios from "../../config/useAxios";

const Text = Typography.Text;
const AccountDetail = () => {
  const location = useLocation();
  const [detailView, setDetailView] = useState(false);
  const [showList, setShowList] = useState(true);
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );

  const { onCall, data } = usePayAxios({
    api: gridApis.getByID,
    method: "get",
  });
  const account = location?.state?.account;
  const columns = [
    {
      title: "Account",
      dataIndex: "accountName",
      key: "accountName",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumbers",
      key: "accountNumbers",
      render: (text) => {
        return text?.[0]?.accountNumber?.value;
      },
    },
    {
      title: "Account Type",
      dataIndex: "accountTypeCode",
      key: "accountTypeCode",
    },
    {
      title: "Routing number",
      dataIndex: "accountNumbers",
      key: "accountNumbers",
      render: (text) => {
        return text?.[0]?.financialInstitutionId?.value;
      },
    },
    {
      title: "Balance",
      dataIndex: "balances",
      key: "balances",
      render: (text) => {
        return `${currencyMapper[text?.[0]?.currencyCode] ?? ""} ${amountFormat(
          text?.[0]?.availableBalanceAmount
        )}`;
      },
    },
    {
      title: "Status",
      key: "statusCode",
      dataIndex: "statusCode",
      render: (status) => {
        let color = "gold";
        if (status === "ACTIVE") {
          color = "green";
        } else if (status === "CLOSED") {
          color = "red";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
  ];
  useEffect(() => {
    onCall({
      params: {
        account_id: location?.state?.account?.id,
      },
    }).then((res) => {
      setDetailView(res?.data);
    });
  }, []);

  return (
    <div className="account-details">
      <div style={{ padding: "20px" }}>
        <Row justify="end" align="middle">
          <Col>
            <Row gutter={16} align="middle">
              <Col>
                <div>
                  <Input
                    className="pp-input"
                    size="large"
                    id="search-input"
                    placeholder="Search..."
                    prefix={
                      <img src={search} alt="" style={{ height: "14px" }} />
                    }
                    style={{
                      backgroundColor: "#FAFAFA",
                      border: "1px solid #EAEAEA",
                      borderRadius: "5px",
                      minWidth: "22rem",
                    }}
                  />
                </div>
              </Col>
              <Col>
                <button className="send-btn" type="primary">
                  Transfer Funds
                </button>
              </Col>

              <Col>
                <button className="send-btn">
                  <img src={plus_b} width={15} />
                  Add New
                </button>
              </Col>

              {/* Grid Icon */}
              <Col style={{ paddingRight: 0 }}>
                <button
                  className="sec-btn"
                  onClick={() => {
                    setShowList((b) => !b);
                  }}
                >
                  {showList ? <UnorderedListOutlined /> : <AppstoreOutlined />}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* {detailView ? ( */}
      <>
        <BankAccount acc={detailView} fromDetail={true} />
        <div className="dashboard-title">
          <span className="card-title">Recent Transactions</span>
        </div>
        <AccTxnTable
          hasWriteAccess={hasAccess("beneficiary_accounts", "write")}
          account_number={detailView?.id}
        />
      </>
      {/*  ) : (
         <>
           {account?.balances?.map((balance) => (
             <div className="bal card w100 mb2">
               <Row
                 style={{
                   gap: "4rem",
                   alignItems: "center",
                 }}
                 className="bal-details"
               >
                 <Col
                   style={{
                     position: "relative",
                     marginRight: 40,
                   }}
                 >
                   <div className="bal-sec">
                     <Text className="avl-bal">Available Balance</Text>
                     <h3>
                       {`${
                         currencyMapper[balance?.currencyCode] ?? "$"
                       } ${amountFormat(balance?.availableBalanceAmount)}`}
                     </h3>
                     <Text className="avl-bal">USD • Main</Text>
                   </div>
                   <div className="v-line" />
                 </Col>
                 <Col>
                   <Text className="avl-bal">Type</Text>
                   <div className="value">{account?.accountTypeCode}</div>
                 </Col>
                 <Col>
                   <Text className="avl-bal">Balance</Text>
                   <div className="value">
                     {`${currencyMapper[balance?.currencyCode] ?? "$"}
                   ${amountFormat(balance?.balanceAmount)}`}
                   </div>
                 </Col>
                 <Col>
                   <Text className="avl-bal">Reserved Amount</Text>
                   <div className="value">{`${
                     currencyMapper[balance?.currencyCode] ?? "$"
                   }
                   ${amountFormat(balance?.reservedAmount)}`}</div>
                 </Col>
               </Row>
             </div>
           ))}
           {showList ? (
             <AccountCard
               onClick={(data) => setDetailView(data)}
               data={bankAcc}
             />
           ) : (
             <div className="paycraft-table">
               <Table
                 columns={columns}
                 dataSource={bankAcc?.filter(
                   (ele) =>
                     (ele.masterAccountId === account?.accountId ||
                       ele.accountId === account?.accountId) &&
                     ele.statusCode === "ACTIVE"
                 )}
                 pagination={false}
                 onRow={(data) => {
                   return {
                     onClick: (_event) => setDetailView(data),
                   };
                 }}
               />
             </div>
           )}
         </>
       )} */}
    </div>
  );
};

export default AccountDetail;
