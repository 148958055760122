import React from "react";
import { amountFormat } from "../../../utils";
import { p_types } from "./Recipients";
import usePayAxios from "../../../config/useAxios";
import { gridApis } from "../../../config/GridApis";
import { message } from "antd";

const Review = ({ dbAcc, crAcc, mainData, toStart, setMainData, paid }) => {
  const { onCall, loading } = usePayAxios({
    api: gridApis.confirmPayment,
    method: "post",
    type: "grid",
  });

  const { onCall: cancel } = usePayAxios({
    api: gridApis.cancelPayment,
    method: "post",
    type: "grid",
  });

  return (
    <div className="payment-container">
      {paid ? (
        <>
          <h2>
            You’ve sent an{" "}
            {p_types?.find((ele) => ele.value === mainData?.method).label}{" "}
            payment
          </h2>
          <p>The money should arrive within 1-5 business days.</p>
        </>
      ) : null}

      <div className="payment-details mb2">
        <div className="section-header">
          {paid ? null : <h2>Review payment details</h2>}
          <div className="mt1">
            <p>
              {p_types?.find((ele) => ele.value === mainData?.method).label} to{" "}
              {crAcc?.beneficiary_name}
            </p>
            <div className="amount">$ {amountFormat(mainData?.amount)}</div>
          </div>
        </div>

        <div className="details">
          {paid && (
            <div className="detail-row">
              <span className="label">Payment ID</span>
              <span className="value">{mainData?.payment_id}</span>
            </div>
          )}
          <div className="detail-row">
            <span className="label">Recipient</span>
            <span className="value">{crAcc?.beneficiary_name}</span>
          </div>
          <div className="detail-row">
            <span className="label">Bank</span>
            <span className="value">{crAcc?.bank_accounts?.[0]?.branch}</span>
          </div>
          <div className="detail-row">
            <span className="label">SWIFT code</span>
            <span className="value">
              {crAcc?.bank_accounts?.[0]?.swift_code ?? "-"}
            </span>
          </div>
          <div className="detail-row">
            <span className="label">Account number</span>
            <span className="value">
              {crAcc?.bank_accounts?.[0]?.account_number}
            </span>
          </div>
          <div className="detail-row">
            <span className="label">Bank location</span>
            <span className="value">
              {crAcc?.billing_addresses?.[0]?.country}
            </span>
          </div>
          <div className="detail-row">
            <span className="label">Recipient's address</span>
            <span className="value">
              {crAcc?.billing_addresses?.[0]?.address}
              <br />
              {crAcc?.billing_addresses?.[0]?.city}
              <br />
              {crAcc?.billing_addresses?.[0]?.state},{" "}
              {crAcc?.billing_addresses?.[0]?.country},{" "}
              {crAcc?.billing_addresses?.[0]?.zipcode}
            </span>
          </div>
        </div>

        {paid ? (
          <>
            <div className="memo detail-row">
              <div class="email-receipt w80">
                <label>Email receipt to</label>
                <div className="d-flex">
                  <input type="email" className="w100" />
                  <button class="send-btn ml2">Send</button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>

      {paid ? (
        <div className="actions mt3">
          <button
            className="back-btn mr2"
            onClick={() => {
              toStart();
            }}
          >
            Make Another Payment
          </button>
        </div>
      ) : (
        <div class="actions mt3">
          <button
            type="button"
            className="back-btn mr2"
            onClick={() => {
              cancel({
                data: {
                  tranx_reference_number: mainData?.paymentId,
                  comments: "",
                },
              }).then(() => {
                toStart();
              });
            }}
          >
            Cancel
          </button>
          <button
            class="send-btn"
            onClick={() => {
              onCall({
                data: {
                  tranx_reference_number: mainData?.paymentId,
                  comments: "PAYMENT",
                },
              }).then((res) => {
                if (res?.data?.responseData?.errors?.length) {
                  message.error(
                    <span className="messageText">
                      {res?.data?.responseData?.errors?.[0]}
                    </span>
                  );
                } else {
                  if (res?.data)
                    setMainData((b) => ({
                      ...b,
                      ...res?.data,
                      paid: true,
                    }));
                  message.success(
                    <span className="messageText">
                      Payment Initiated Successfully
                    </span>
                  );
                }
              });
            }}
            disabled={loading}
          >
            Confirm
          </button>
        </div>
      )}
    </div>
  );
};

export default Review;
