import { countries } from "../../../Settings/Organisation/country";
export const menu = {
  estimates_settings: ["read", "write", "read", "write"],
  sales_orders_settings: ["read", "write", "read", "write"],
  invoices_settings: ["read", "write", "read", "write"],
  cards: ["read", "write", "read", "write"],
  transfers: ["read", "write", "read", "write"],
  transactions: ["read", "write", "read", "write"],
  company_master_account: [
    "read",
    "write",
    "write",
    "read",
    "read",
    "write",
    "write",
    "read",
  ],
  analytics: ["read", "write", "read", "write"],
  estimates: ["read", "write", "read", "write"],
  sales_orders: ["read", "write", "read", "write"],
  invoices: ["read", "write", "read", "write"],
  purchase_orders: ["read", "write", "read", "write"],
  bills: ["read", "write", "read", "write"],
  purchase_orders_settings: ["read", "write", "read", "write"],
  bills_settings: ["read", "write", "read", "write"],
  organisation_settings: ["read", "write", "read", "write"],
  categories: ["read", "write", "read", "write"],
  expense_settings: ["read", "write", "read", "write"],
  reports_settings: ["read", "write", "read", "write"],
  advances_settings: ["read", "write", "read", "write"],
  trips_settings: ["read", "write", "read", "write"],
  projects: ["read", "write", "read", "write"],
  cost_center: ["read", "write", "read", "write"],
  merchants: ["read", "write", "read", "write"],
  company: ["read", "write", "read", "write"],
  reimbursements: ["write", "read", "write", "read"],
  items: ["read", "write", "read", "write"],
  taxes: ["read", "write", "read", "write"],
  tds: ["read", "write", "read", "write"],
  customers: ["read", "write", "read", "write"],
  vendors: ["read", "write", "read", "write"],
  company_accounts: ["read", "write", "read", "write"],
  beneficiary_accounts: ["read", "write", "read", "write"],
  history: ["read", "write", "read", "write"],
  manage_beneficiaries: ["read", "write", "read", "write"],
  bank_accounts: ["write", "read", "write", "read"],
  bulk_payment_links: ["read", "write", "read", "write"],
  payments_made: [
    "read",
    "write",
    "read",
    "write",
    "read",
    "write",
    "read",
    "write",
  ],
  payments_received: [
    "write",
    "write",
    "read",
    "read",
    "write",
    "write",
    "read",
    "read",
  ],
  inventory: ["write", "read", "write", "read"],
  account_transactions: ["write", "read", "write", "read"],
  payment_links: ["read", "write", "read", "write"],
  expense_reports: ["read", "write", "read", "write"],
  trips: ["read", "write", "read", "write"],
  advances: ["read", "write", "read", "write"],
  users_and_controls: ["read", "write", "read", "write"],
  policies_and_limits: ["read", "write", "read", "write"],
  approvals: ["read", "write", "read", "write"],
  reports_approvals: ["read", "write", "read", "write"],
  trips_approvals: ["read", "write", "read", "write"],
  advances_approvals: ["read", "write", "read", "write"],
  all_cards: ["read", "write", "read", "write"],
  quick_transfers: ["read", "write", "read", "write"],
  bulk_transfers: ["read", "write", "read", "write"],
  transfer_logs: ["read", "write", "read", "write"],
  all_card_transactions: ["read", "write", "read", "write"],
  primary_accounts: ["read", "write", "read", "write"],
  users: ["read", "write", "read", "write"],
  roles_and_permissions: ["read", "write", "read", "write"],
  department_and_grades: ["read", "write", "read", "write"],
  custom_fields: ["read", "write", "read", "write"],
  expense_policy: ["read", "write", "read", "write"],
  cards_policy: ["read", "write", "read", "write"],
  payments_policy: ["read", "write", "read", "write"],
  reports_approvals_settings: ["read", "write", "read", "write"],
  trips_approvals_settings: ["read", "write", "read", "write"],
  advances_approvals_settings: ["read", "write", "read", "write"],
  bills_approvals_settings: ["read", "write", "read", "write"],
  invoices_approvals_settings: ["read", "write", "read", "write"],
  sales_order_approvals_settings: ["read", "write", "read", "write"],
  estimates_approvals_settings: ["read", "write", "read", "write"],
  purchase_orders_approvals_settings: ["read", "write", "read", "write"],
  currencies: ["read", "write", "read", "write"],
  payment_received_settings: ["read", "write", "read", "write"],
  payment_made_settings: ["read", "write", "read", "write"],
  credit_note_settings: ["read", "write", "read", "write"],
  estimates_approvals: ["read", "write", "read", "write"],
  sales_orders_approvals: ["read", "write", "read", "write"],
  invoices_approvals: ["read", "write", "read", "write"],
  credit_notes: ["read", "write", "read", "write"],
  purchase_order_approvals: ["read", "write", "read", "write"],
  my_purchases: ["read", "write", "read", "write"],
  bill_approvals: ["read", "write", "read", "write"],
  my_bills: ["read", "write", "read", "write"],
  utility_bills: ["read", "write", "read", "write"],
  payments_made_pending: ["read", "write", "read", "write"],
  payments_made_paid: ["read", "write", "read", "write"],
  bulk_transfer_upload_payments: ["read", "write", "read", "write"],
  bulk_transfers_history: ["read", "write", "read", "write"],
  bulk_upload_payment_links: ["read", "write", "read", "write"],
  bulk_payment_history: ["read", "write", "read", "write"],
  payroll: ["read", "write", "read", "write"],
  banks: ["read", "write", "read", "write"],
  staff_permission_cards_vendors: ["read", "write", "read", "write"],
  bulk_payment_approvals_settings: ["read", "write", "read", "write"],
  payment_approvals_settings: ["read", "write", "read", "write"],
  customer_portal_settings: ["read", "write", "read", "write"],
  vendor_portal_settings: ["read", "write", "read", "write"],
  integrations: ["read", "write", "read", "write"],
};
export const page_names = {
  3: "USER_INFO_DETAILS",
  4: "USER_IDENTITY_DETAILS",
  5: "USER_SELFIE_DETAILS",
  6: "SETUP_USER",
};
export const fe_steps = {
  3: [
    {
      name: "are_you_us_citizen",
      label: "Are you a US Citizen?",
      type: "radio",
      validation: {},
      defaultValue: "yes",
      radio_fields: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
      ],
    },
    {
      name: "ssn_details",
      label: "SSN",
      placeholder: "Enter SSN",
      validation: { required: "SSN is required" },
      width: 100,
    },
    {
      name: "govt_issued_id_type",
      label: "Government Issued ID Type",
      placeholder: "Enter Government ID",
      validation: {},
      width: 100,
    },
    {
      name: "govt_issued_id",
      label: "Government Issued ID",
      placeholder: "Enter Government ID",
      validation: {},
      width: 100,
    },
    {
      name: "choose_residence_country",
      label: "Choose Residence Country",
      placeholder: "Select Country",
      validation: { required: "Residence Country is required" },
      width: 100,
      type: "dropdown",
      filterKey: "value",
      options: countries?.map((item) => ({
        value: item.value,
        label: (
          <span
            style={{
              color: "#212121",
              fontSize: 15,
            }}
          >
            <img
              src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.code?.toLowerCase()}.svg`}
              className="mr1"
              width={25}
              style={{
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
              }}
            />
            {`${item?.label}`}
          </span>
        ),
      })),
    },

    { type: "divider", label: "Address" },
    {
      name: "address.street_one",
      label: "Street 1",
      placeholder: "Enter Street Address",
      validation: { required: "Street Address is required" },
      width: 100,
    },
    {
      name: "address.city",
      label: "City",
      placeholder: "Enter City",
      validation: { required: "City is required" },
      width: 50,
    },
    {
      name: "address.state",
      label: "State",
      placeholder: "Enter State",
      validation: { required: "State is required" },
      width: 50,
    },
    {
      name: "address.zipcode",
      label: "Zipcode",
      placeholder: "Enter Zipcode",
      validation: { required: "Zipcode is required" },
      width: 50,
    },
    {
      name: "address.country",
      label: "Country",
      placeholder: "Select Country",
      validation: { required: "Country is required" },
      width: 50,
      type: "dropdown",
      filterKey: "value",
      options: countries?.map((item) => ({
        value: item.code,
        label: (
          <span
            style={{
              color: "#212121",
              fontSize: 15,
            }}
          >
            <img
              src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.code?.toLowerCase()}.svg`}
              className="mr1"
              width={25}
              style={{
                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
              }}
            />
            {`${item?.label}`}
          </span>
        ),
      })),
    },
    {
      name: "address.address_type",
      label: "Address Type",
      placeholder: "Select Address Type",
      validation: { required: "Address Type is required" },
      width: 100,
      type: "dropdown",
      options: [{ label: "Residence", value: "Residence".toUpperCase() }],
    },
  ],
  4: [
    {
      name: "document_id",
      label: "Document ID (Front)",
      type: "document",
      validation: { required: "Document front is required" },
      width: 100,
    },
    {
      name: "document_id_back",
      label: "Document ID (Back)",
      type: "document",
      validation: { required: "Document back is required" },
      width: 100,
    },
    {
      name: "document_number",
      label: "Document Number",
      placeholder: "Enter Document Number",
      validation: { required: "Document Number is required" },
      width: 50,
    },
    {
      name: "document_expiry",
      label: "Document Expiry",
      placeholder: "MM/YY",
      validation: {
        required: "Document Expiry is required",
        pattern: {
          value: /^(0[1-9]|1[0-2])\/\d{2}$/,
          message: "Invalid expiry format",
        },
      },
      type: "date",
      dateFormat: "MM/YY",
      width: 50,
      picker: "month",
    },
  ],
  5: [
    {
      name: "selfie_attachment_id",
      label: "Selfie Attachment",
      type: "capture",
      validation: { required: "Selfie is required" },
      width: 100,
    },
  ],
  6: [
    {
      name: "username",
      label: "Username",
      validation: { required: "Username is required" },
      mandotary: true,
      width: 100,
      placeholder: "Enter Username",
    },
    {
      name: "password",
      label: "Password",
      placeholder: "Enter Password",
      validation: { required: "Password is required" },
      width: 100,
      type: "password",
    },
    {
      name: "confirm_password",
      label: "Confirm Password",
      placeholder: "Enter Confirm Password",
      validation: { required: "Confirm Password is required" },
      width: 50,
      type: "password",
    },
  ],
};
