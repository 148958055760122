import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { hasAccess } from "../../utils";
import Categories from "./Categories/Categories";
import Organisation from "./Organisation";
import Expense from "./PoliciesLimits/Expense/Expense";
import CustomField from "./UsersControls/CustomField/CustomField";
import Grades from "./UsersControls/GradesDesignation/Grades";
import Roles from "./UsersControls/Roles/Roles";
import Users from "./UsersControls/Users";
import AddUsers from "./UsersControls/Users/AddUser";
import "./styles/setting.css";
// import Reimbursements from "./Customisation/Reimbursements/Reimbursements";
import Currencies from "./Currencies/Currencies";
import HasPermissions from "./HasPermissions";
import AddNewOrg from "./Organisation/AddNewOrg";
import ManageOrganization from "./Organisation/ManageOrganization";
import OrganisationHistory from "./RootFi-Integration/OrganisatioHistory";
import RootFiIntegration from "./RootFi-Integration/RootFiIntegration";
import Alerts from "./Alerts";
import Security from "./Security";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SideMenu } from "../../actions/masterdata/masterdata";

const Settings = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location?.pathname) {
      let locationSplit = location?.pathname?.split("/");
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: false,
          childrenMenu: "",
        },
      });
    }
  }, []);
  return (
    <Row>
      {/* <Col flex="256px">{renderSettingMenu()}</Col> */}
      <Col flex="1 1">
        <div className="content-div">
          <Switch>
            <Route
              exact
              path="/settings/profile/"
              component={() => (
                <HasPermissions
                  role={"organisation_settings"}
                  name="Organisation Profile"
                >
                  <Organisation
                    hasWriteAccess={hasAccess("organisation_settings", "write")}
                  />
                </HasPermissions>
              )}
            />
            {/* Rootfi Routes */}
            <Route
              path="/settings/rootFi-integration"
              component={() => (
                <RootFiIntegration
                  hasWriteAccess={hasAccess("integrations", "write")}
                />
              )}
            />
            <Route
              exact
              path="/settings/rootFi-integration-history"
              component={() => <OrganisationHistory />}
            />

            <Route
              path="/settings/currencies/"
              component={() => (
                <Currencies hasWriteAccess={hasAccess("currencies", "write")} />
              )}
            />
            <Route
              path="/settings/branches/add"
              component={() => <AddNewOrg />}
            />
            <Route
              path="/settings/branches"
              component={() => (
                <ManageOrganization
                  hasWriteAccess={
                    [null, "null"].includes(localStorage?.getItem("parentID"))
                      ? true
                      : false
                  }
                />
              )}
            />
            <Route
              path="/settings/users/"
              exact
              component={() => (
                <Users hasWriteAccess={hasAccess("users", "write")} />
              )}
            />
            <Route
              path="/settings/users/add_user/"
              component={() => <AddUsers />}
            />
            <Route
              path="/settings/roles/"
              component={() => (
                <Roles
                  hasWriteAccess={hasAccess("roles_and_permissions", "write")}
                />
              )}
            />
            <Route
              path="/settings/grades/"
              component={() => (
                <Grades
                  hasWriteAccess={hasAccess("department_and_grades", "write")}
                />
              )}
            />
            <Route
              path="/settings/customfields/"
              component={() => (
                <CustomField
                  hasWriteAccess={hasAccess("custom_fields", "write")}
                />
              )}
            />
            <Route
              path="/settings/categories/"
              component={() => (
                <Categories hasWriteAccess={hasAccess("categories", "write")} />
              )}
            />
            <Route
              path="/settings/policies/expense/"
              component={() => (
                <Expense
                  hasWriteAccess={hasAccess("expense_policy", "write")}
                />
              )}
            />
            <Route
              path="/settings/alerts/"
              exact
              component={() => <Alerts />}
            />
            <Route
              path="/settings/security/"
              exact
              component={() => <Security />}
            />
          </Switch>
        </div>
      </Col>
    </Row>
  );
};

export default Settings;
