import { OnBoardEnglish } from "../Language/English";
import Close from "../../../assets/icons/close.png";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../actions/auth";

const KYCHeader = ({ Header, SubHeader }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const reject_reason = useSelector(
    (state) => state?.on_board?.onboardStep?.data?.data?.reject_reason
  );
  const onboardStepResponse = useSelector(
    (state) => state?.on_board?.onboardStep?.data
  );

  return (
    <>
      <Modal
        title={"Are you sure you'd like to close the tab?"}
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={[]}
        className={"center-aligned-modal"}
      >
        <div className="close_modal_section">
          <div className="close_modal_container">
            <div className="close_body">
              <h4>Closing this tab will result in the loss of your data.</h4>
            </div>
            <div className="close_button">
              <Button
                key="2"
                className="cancelButton mr1 mt1"
                htmlType="button"
                onClick={() => setIsModalOpen(false)}
              >
                {t("cancel")}
              </Button>
              <Button
                key="1"
                className="formButton mt1"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                {t("confirm")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="kyc-header-section mb2">
        <div className="kyc-header-container">
          {reject_reason && (
            <div className="strip red w60">{reject_reason}</div>
          )}
          {onboardStepResponse?.data?.kyc_status === "kyc_approved" &&
            !reject_reason && (
              <div className="flex-between strip green w60">
                <span>
                  Your KYC status has updated. Please logout and login again.
                </span>
                <button
                  onClick={() => {
                    localStorage.clear();
                    dispatch(logout());
                  }}
                >
                  Logout
                </button>
              </div>
            )}

          <div className="kyc-header w70">
            <h4 className="header-text fw-M mb0">{Header}</h4>
            <span className="sub-header-text fw-M fs-18">{SubHeader}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default KYCHeader;
