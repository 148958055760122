import { Divider, Input, Modal, Popover, Radio, Select } from "antd";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormRadio from "../inputs/FormRadio";
import { FormInput, FormSelect } from "../inputs";
import { useSelector } from "react-redux";
import { currencyMapper } from "../../config/helper";
import { amountFormat } from "../../utils";

const CreditCard = () => {
  const { control, handleSubmit, watch, register, errors } = useForm({
    defaultValues: {
      cardType: "total",
    },
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAcc, setSelectedAcc] = useState({});
  const selectedAmountType = watch("cardType");
  const data1 = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const showModal = () => {
    setIsModalVisible(true);
  };
  const onSubmit = (data) => {
    console.log("Form Data:", data);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Payment Form"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className="right-alinged-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Payment Method */}
          <div className="pp-form-item">
            <label style={{ color: "#3B3B3B" }}>Pay Via</label>
            <div style={{ display: "flex", gap: "10px" }}>
              <button className="send-btn">Current/Savings Account</button>
              <button className="back-btn">Other Banks</button>
            </div>
          </div>

          {/* Select Payment Amount */}
          <div className="pp-form-item">
            <FormRadio
              inline
              label={"Select"}
              control={control}
              options={[
                { label: "Total Due Amount", value: "total" },
                { label: "Minimum Due", value: "minimum" },
                { label: "Enter Amount", value: "amount" },
              ]}
              required={true}
              {...register("cardType", {
                value: selectedAmountType,
                required: "Please select the card type.",
              })}
              errors={errors}
              hideCreateOption={true}
              defaultValue={selectedAmountType}
              radio_name={"card_type"}
            />
          </div>

          {/* Amount Field */}
          <div className="pp-form-item">
            {selectedAmountType === "amount" ? (
              <FormInput
                inline
                label={"Amount"}
                control={control}
                required={true}
                {...register("amount", {
                  required: "Please enter the amount.",
                })}
                errors={errors}
              />
            ) : (
              <div className="amount-display">
                {selectedAmountType === "minimum" ? (
                  <>
                    <label style={{ color: "#3B3B3B" }}>
                      Minimim Due Amount
                    </label>
                    <h1>₹8,645.00</h1>
                  </>
                ) : (
                  <>
                    <label style={{ color: "#3B3B3B" }}>Due Amount</label>
                    <h1>₹86,435.00.00</h1>
                  </>
                )}
                <p>Twenty Two Thousand Dollars</p>
              </div>
            )}
          </div>

          {/* Select Account */}
          <div className="pp-form-item">
            <FormSelect
              inline
              label={"Select Account"}
              control={control}
              options={data1?.map((ele) => ({
                label: `${ele?.account_name} - ${ele?.account_number}`,
                value: ele?.id,
              }))}
              required={true}
              {...register("send_from", {
                required: "Please select Send From",
                onChange: (e) => {
                  setSelectedAcc(
                    data1?.find((ele) => ele.id === e.target.value)
                  );
                },
              })}
              errors={errors}
              hideCreateOption={true}
            />
            {selectedAcc?.balance?.balance && (
              <p style={{ marginTop: "10px" }}>
                Available Balance: {currencyMapper["INR"]}{" "}
                {amountFormat(selectedAcc?.balance?.balance)}
              </p>
            )}
          </div>
          <div className="action-btns mt3">
            <button className="back-btn">Cancel</button>
            <button className="send-btn">Confirm</button>
          </div>
        </form>
      </Modal>
      <div className="credit-card">
        <div className="card-header">
          <h4>Credit Card</h4>
          <button className="send-btn" onClick={showModal}>
            Pay
          </button>
        </div>
        <div className="amount">
          <h1>
            {currencyMapper["INR"]}
            {amountFormat(137500)}
          </h1>
        </div>
        <div className="balance-bar">
          <div className="balance-filled"></div>
          <div className="balance-pending"></div>
        </div>
        <div className="balance-info">
          <span>
            Balance
            <em className="col1">●</em>
          </span>
          <span>
            Pending
            <em className="col2">●</em>
          </span>
          <Popover
            overlayClassName="dark"
            content={
              <ul className="credit">
                <li>
                  <label>Total Credit</label>
                  <span>
                    {currencyMapper["INR"]}
                    {amountFormat(350000)}
                  </span>
                </li>
                <li>
                  <label>Utilized</label>
                  <span>
                    {currencyMapper["INR"]}
                    {amountFormat(137500)}
                  </span>
                </li>
                <li>
                  <label>Due Payment</label>
                  <span>
                    {currencyMapper["INR"]}
                    {amountFormat(86435)}
                  </span>
                </li>
                <Divider />
                <li>
                  <label>Available</label>
                  <span>
                    {currencyMapper["INR"]}
                    {amountFormat(212500)}
                  </span>
                </li>
              </ul>
            }
            trigger={["hover"]}
            placement="right"
          >
            <span className="available">
              Available:{" "}
              <strong>
                {currencyMapper["INR"]}
                {amountFormat(212500)}
              </strong>
            </span>
          </Popover>
        </div>
      </div>
    </>
  );
};

export default CreditCard;
