import { Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { customers } from "../../../actions/customers";
import { get_person_accounts } from "../../../actions/z_accounts";
import close from "../../../assets/icons/close.png";
import CopyLink from "./CopyLink";
import CreateRequest from "./CreateRequest";
import Review from "./Review";
import { fetchPaymentLinks } from "../../../actions/transfers";
import { get_currencies } from "../../../actions/masterdata/masterdata";

const RequestMoney = ({ setOpenAddNew }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [dbAcc, setDBAcc] = useState({});
  const [cAcc, setCAcc] = useState({});
  const [mainData, setMainData] = useState({});
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      beneficiary_id: "",
      ourparty_account_id: "",
      description: "",
      notes: "",
      amount: "",
      currency_code: "",
      ourparty_display_name: "",
      due_date: "",
      enable_card_payments: false,
      documents: [],
      mail_to_counterparty: true,
    },
  });

  useEffect(() => {
    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
    dispatch(
      get_currencies({
        all: true,
      })
    );
    dispatch(
      customers({
        type: "individual",
      })
    );
  }, []);

  console.log({ mainData });

  const steps = [
    {
      title: "Create Request",
      content: (
        <>
          <CreateRequest
            next={() => {
              setCurrentStep((b) => b + 1);
            }}
            cancel={() => {
              setOpenAddNew(false);
            }}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setDBAcc={setDBAcc}
            setCAcc={setCAcc}
            errors={errors}
            watch={watch}
            setValue={setValue}
            setMainData={setMainData}
            step={currentStep}
            mainData={mainData}
          />
        </>
      ),
    },
    {
      title: "Review",
      content: (
        <>
          <Review
            dbAcc={dbAcc}
            mainData={mainData}
            next={() => {
              setCurrentStep((b) => b + 1);
            }}
            prev={() => {
              setCurrentStep((b) => b - 1);
            }}
            setMainData={setMainData}
            cAcc={cAcc}
          />
        </>
      ),
    },
    {
      title: "Copy Link",
      content: (
        <>
          <CopyLink
            back={() => {
              setCurrentStep(0);
            }}
            close={() => {
              setOpenAddNew(false);
              dispatch(fetchPaymentLinks());
            }}
            cAcc={cAcc}
            mainData={mainData}
          />
        </>
      ),
    },
  ];

  return (
    <div className="upload_payment_parent send-payment request-money">
      <div className="flex-between sec-head">
        <div className="tabsSection">
          <span className="active">Request Money</span>
        </div>
        <img
          src={close}
          width={20}
          onClick={() => {
            dispatch(fetchPaymentLinks());
            setOpenAddNew(false);
          }}
        />
      </div>
      <div className="p2">
        <Steps current={currentStep}>
          {steps.map((item, index) => (
            <Steps.Step key={index} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content w50" style={{ marginTop: 20 }}>
          {steps[currentStep].content}
        </div>
      </div>
    </div>
  );
};

export default RequestMoney;
