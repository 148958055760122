import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  SideMenu,
  get_currencies,
  headerTitles,
} from "../../actions/masterdata/masterdata";
import { get_person_accounts } from "../../actions/z_accounts";
import { currencyMapper, currencyNames } from "../../config/helper";
import { amountFormat } from "../../utils";
import TableComponent from "../Common/TableComp/TableComp";
import AddNewPrimaryAcc from "./AddNewPrimaryAcc";
import AddAccount from "./AddAccount";
import Transfer from "./Transfer";

const PrimaryAcc = ({ hasWriteAccess }) => {
  const [openNewAcc, setOpenNewAcc] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const { t } = useTranslation();
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );

  const bankAccLoading = useSelector(
    (state) => state?.z_accs?.accounts?.loading
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [showBalance, setShowBalance] = useState({});

  const columns = [
    {
      title: t("Account Name"),
      dataIndex: "given_name",
      key: "given_name",
    },
    {
      title: t("Account Type"),
      dataIndex: "account_type",
      key: "account_type",
      render: (text) => <Tag>{text}</Tag>,
    },
    {
      title: t("Currency"),
      dataIndex: "currency_code",
      key: "currency_code",
      render: (rec, data) => {
        return (
          <div className="d-flex align-items-center">
            {rec} ({currencyNames[rec]})
          </div>
        );
      },
    },
    {
      title: t("Account Number"),
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: t("ac_balance"),
      dataIndex: "balance",
      key: "balance",
      render: (record, data) => {
        return record?.balance ? (
          <>
            {currencyMapper[record?.currency_code]}

            {showBalance[data.id]
              ? amountFormat(record?.balance)
              : "xx.xx".toUpperCase()}
            {!showBalance[data.id] ? (
              <EyeInvisibleOutlined
                className="ml1"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowBalance({
                    [data.id]: !showBalance[data.id],
                  });
                }}
              />
            ) : (
              <EyeOutlined
                className="ml1"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowBalance({
                    [data.accountId]: !showBalance[data.accountId],
                  });
                }}
              />
            )}
          </>
        ) : (
          "-"
        );
      },
    },
  ];

  useEffect(() => {
    if (location.state?.success) {
      alert(t("bank_account_added"));
      history.replace(location.pathname);
    }

    if (location.state?.success === false) {
      alert(t("failed_add_accounts"));
      history.replace(location.pathname);
    }

    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
    dispatch(
      headerTitles({
        title: "Accounts",
        description: "Manage all your global accounts.",
      })
    );
    let locationSplit = location?.pathname?.split("/");
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: "/" + locationSplit?.[2],
        showChildren: true,
        childrenMenu: "/" + locationSplit?.[3],
      },
    });
    dispatch(
      get_currencies({
        all: true,
      })
    );
  }, [location.state]);

  return (
    <>
      {/* <AddNewPrimaryAcc
        open={openNewAcc && hasWriteAccess}
        onClose={() => {
          setOpenNewAcc(false);
        }}
      /> */}
      {openNewAcc && (
        <Modal
          visible={openNewAcc}
          className="right-alinged-modal"
          onCancel={() => {
            setOpenNewAcc(false);
          }}
          title={t("Add Account")}
        >
          <AddAccount
            setOpenAddNew={setOpenNewAcc}
            cb={() => {
              dispatch(
                get_person_accounts({
                  page_number: 1,
                  page_size: 50,
                  person_id: "ID-1146",
                })
              );
            }}
          />
        </Modal>
      )}
      <Modal
        visible={openTransfer}
        className="right-alinged-modal"
        onCancel={() => {
          setOpenTransfer(false);
        }}
        title={t("Transfer")}
      >
        {openTransfer && (
          <Transfer
            setOpenAddNew={setOpenTransfer}
            cb={() => {
              dispatch(
                get_person_accounts({
                  page_number: 1,
                  page_size: 50,
                  person_id: "ID-1146",
                })
              );
            }}
          />
        )}
      </Modal>
      <>
        <Row wrap={false} style={{ flexGrow: 1, display: "flex" }}>
          <Col flex="auto" style={{ display: "flex", flexGrow: "1" }}>
            <div style={{ flexGrow: 1 }}>
              <div style={{ flexGrow: 1, paddingTop: "22px" }}>
                <div
                  style={{
                    paddingLeft: "7px",
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flexGrow: 1 }}></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div className="mr1">
                      <Input
                        className="pp-input"
                        size="large"
                        onChange={(e) => {
                          setSearchTerm(e.target.value?.toLowerCase());
                        }}
                        placeholder={t("searchh")}
                        prefix={<SearchOutlined style={{ color: "#B9B9B9" }} />}
                        style={{
                          backgroundColor: "#FAFAFA",
                          border: "1px solid #EAEAEA",
                          borderRadius: "5px",
                          width: "20rem",
                        }}
                      />
                    </div>
                    <div className="d-flex action-btns">
                      <Button
                        disabled={!hasWriteAccess}
                        type="primary"
                        className="pp-main-button"
                        onClick={() => setOpenTransfer(true)}
                      >
                        <span>{t("Transfer")}</span>
                      </Button>
                      <Button
                        disabled={!hasWriteAccess}
                        type="primary"
                        className="pp-main-button"
                        icon={
                          <div style={{ paddingRight: "10px" }}>
                            <PlusCircleFilled />
                          </div>
                        }
                        onClick={() => setOpenNewAcc(true)}
                      >
                        <span>{t("addNew")}</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={bankAccLoading ? "shimmer" : ""}
                style={{ flexGrow: 1, paddingTop: "22px" }}
              >
                <TableComponent
                  hasCheckbox={false}
                  columns={columns}
                  data={Array.isArray(bankAcc) ? bankAcc : []}
                  loading={bankAccLoading}
                  rowKey="bill_no"
                  handleRowClick={(_e, data) => {
                    history.push("/account/detail", {
                      account: data,
                    });
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </>
    </>
  );
};

export default PrimaryAcc;
