import { useContext } from "react";
import { LeftNavContext } from "../../../../LeftNavProvider";
import CheckIcon from "../../assets/icons/check-Icon.svg";
import LOGO from "../../assets/icons/zenus.png";
import "./onBoardLeftNavigation.css";
export const onboardPageDetails = [
  "Personal Information",
  "Personal Identity",
  "Document and Selfie",
  "Setup Username",
];
const OnBoardLeftNavigation = () => {
  const { formStep } = useContext(LeftNavContext);
  
  return (
    <>
      <div className="navigation-section">
        <div className="navigation-conatiner">
          <div className="justify-center mb4">
            <img
              src={LOGO}
              style={{
                width: 250,
              }}
            />
          </div>
          {onboardPageDetails?.map((element_page_details, page_index) => {
            return (
              <ul
                className={`list-section ${
                  formStep > page_index + 3 ? "completed-sec" : ""
                }`}
              >
                <li>
                  {formStep > page_index + 3 ? (
                    <span
                      className="bg-circle"
                      style={{
                        border: "1.4px solid #2FCC46",
                        borderRadius: "50%",
                        width: "1.5rem",
                        height: "1.5rem",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        zIndex: 10,
                      }}
                    >
                      <img
                        src={CheckIcon}
                        alt="check-icon"
                        style={{
                          width: "18px",
                          height: "8px",
                        }}
                      />
                    </span>
                  ) : (
                    <span
                      className="bg-circle header-circle"
                      style={{
                        position: "relative",
                        zIndex: 10,
                      }}
                    >
                      {page_index + 1}
                    </span>
                  )}
                  <span className="header-text">{element_page_details}</span>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default OnBoardLeftNavigation;
