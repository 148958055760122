import { Select } from "antd";
import React from "react";
import { useForm } from "react-hook-form";

const countries = [
  { value: "US", label: "United States", flagCode: "US" },
  { value: "GB", label: "United Kingdom", flagCode: "GB" },
  { value: "IN", label: "India", flagCode: "IN" },
  { value: "JP", label: "Japan", flagCode: "JP" },
];
const PaymentForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // Handle your payment logic here
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="payment-form">
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          {...register("email", { required: "Email is required" })}
          placeholder="Enter Email"
        />
        {errors.email && <span className="error">{errors.email.message}</span>}
      </div>

      <div className="card-info form-group">
        <label>Card information</label>
        <div className="inputs">
          <input
            type="text"
            placeholder="1234 1234 1234 1234"
            {...register("cardNumber", { required: "Card number is required" })}
            className="bb"
          />
          <div className="d-flex">
            <input
              type="text"
              placeholder="MM / YY"
              {...register("expiryDate", {
                required: "Expiry date is required",
              })}
              className="br"
            />
            <input
              type="text"
              placeholder="CVC"
              {...register("cvc", { required: "CVC is required" })}
            />
          </div>
        </div>
        {errors.cardNumber && (
          <span className="error">{errors.cardNumber.message}</span>
        )}
        {errors.expiryDate && (
          <span className="error">{errors.expiryDate.message}</span>
        )}
        {errors.cvc && <span className="error">{errors.cvc.message}</span>}
      </div>

      <div className="form-group">
        <label>Cardholder name</label>
        <input
          type="text"
          {...register("cardholderName", {
            required: "Cardholder name is required",
          })}
          placeholder="Enter card holder name"
        />
        {errors.cardholderName && (
          <span className="error">{errors.cardholderName.message}</span>
        )}
      </div>

      <div className="form-group">
        <label>Country or region</label>
        <Select
          dropdownStyle={{
            backgroundColor: "#04122B",
            "--bg-option": "#ffffff10",
          }}
          placeholder="Select a country"
        >
          {countries.map((country) => (
            <Select.Option key={country.value} value={country.value}>
              <span>
                <img
                  src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${country?.flagCode?.toLowerCase()}.svg`}
                  className="mr1"
                  width={25}
                  style={{
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                  }}
                />
                {country.label}
              </span>
            </Select.Option>
          ))}
        </Select>
      </div>

      <button type="submit" className="send-btn w100 flex-center">
        Pay
      </button>
    </form>
  );
};

export default PaymentForm;
