import { Button, Space, Typography } from "antd";
import { default as React } from "react";
import copy from "./assets/copy.svg";
import bank from "./assets/bank.png";
import { handleCopy } from "../../../utils";

const { Title, Text, Paragraph } = Typography;
const ACHPay = ({ paymentDetails }) => {
  return (
    <div className="ach-section">
      <Paragraph className="sub-text">
        Use these details to send both domestic wires and ACH transfers to Mark
        Samuel's Zenus account.
      </Paragraph>

      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Space
          direction="horizontal"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          <div>
            <Text type="secondary">Routing number</Text>
            <div className="ach attachment-wrap">
              <Text strong>{paymentDetails?.ourparty_routing_code}</Text>
              <Button
                onClick={() => {
                  handleCopy(paymentDetails?.ourparty_routing_code);
                }}
                icon={<img src={copy} width={15} />}
                type="link"
              />
            </div>
          </div>
          <div>
            <Text type="secondary">Account number</Text>
            <div className="ach attachment-wrap">
              <Text strong>{paymentDetails?.ourparty_account_number}</Text>
              <Button
                onClick={() => {
                  handleCopy(paymentDetails?.ourparty_account_number);
                }}
                icon={<img src={copy} width={15} />}
                type="link"
              />
            </div>
          </div>
        </Space>
        <div className="ben-details attachment-wrap">
          {/* Beneficiary */}
          <div className="attachment-item">
            <Title level={5}>
              <img src={bank} width={18} />
              Beneficiary
            </Title>
            <div>
              <Paragraph>
                <strong>Beneficiary Name:</strong>{" "}
                {paymentDetails?.counterparty_name}
              </Paragraph>
              <Paragraph>
                <strong>Type of account:</strong> Company
              </Paragraph>
              <Paragraph>
                <strong>Beneficiary Address:</strong>
                {paymentDetails?.counterparty_address}
              </Paragraph>
            </div>
          </div>
          {/* Receiving Bank Details */}
          <div className="attachment-item">
            <Title level={5}>
              <img src={bank} width={18} />
              Receiving Bank Details
            </Title>
            <div>
              <Paragraph>
                <strong>Bank name:</strong>
                <div>
                  {paymentDetails?.ourparty_bank_name}
                  {/* <p className="dim mtHalf">
                    Zenus uses Choice Financial Group as a banking partner.
                  </p> */}
                </div>
              </Paragraph>
              <Paragraph>
                <strong>Bank Address:</strong>
                {paymentDetails?.ourparty_address}
              </Paragraph>
            </div>
          </div>
        </div>
      </Space>
    </div>
  );
};

export default ACHPay;
