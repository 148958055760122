import { Input, Modal } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const CompanyDetailsModal = ({ company, formik }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const updateCompanyProfileResponse = useSelector(
    (state) => state.companySetting.updateCompanyProfile || {}
  );

  React.useEffect(() => {
    const { data: { status = "" } = {} } = updateCompanyProfileResponse;
    if (status) {
      setIsModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanyProfileResponse]);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        title="Edit Company Details"
        className="right-alinged-modal"
        getContainer={false}
      >
        <div className="pp-form-item">
          <label>Leagal Name</label>

          <Input
            className="pp-form-input"
            placeholder="Enter Company Legal Name"
            value={formik?.values?.name}
            onChange={formik.handleChange}
            name="name"
          />
        </div>
        <div className="pp-form-item">
          <label>City</label>
          <Input
            className="pp-form-input"
            placeholder="Enter City"
            value={formik?.values?.city}
            onChange={formik.handleChange}
            name="city"
          />
        </div>

        <div className="pp-form-item">
          <label>State</label>
          <Input
            className="pp-form-input"
            placeholder="Enter State"
            value={formik?.values?.state}
            onChange={formik.handleChange}
            name="state"
          />
        </div>

        <div className="pp-form-item">
          <label>Country</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Country"
            value={formik?.values?.country}
            onChange={formik.handleChange}
            name="country"
          />
        </div>

        <div className="pp-form-item">
          <label>Federal EIN</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Federal EIN"
            defaultValue={company?.gst_no}
            onChange={formik.handleChange}
            name="gst_no"
          />
        </div>

        <div className="pp-form-item">
          <label>Office Number</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Office Number"
            defaultValue={company?.office_no}
            onChange={formik.handleChange}
            name="office_no"
          />
        </div>

        <div className="pp-form-item">
          <label>TAN No.</label>
          <Input
            className="pp-form-input"
            placeholder="Enter TAN No."
            defaultValue={company?.tan_no}
            onChange={formik.handleChange}
            name="tan_no"
          />
        </div>
        <div className="action-btns mt2">
          <button className="send-btn">Update Company Details</button>
          <button
            className="back-btn"
            type="button"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <div className="flex-between mb1">
        <h3>Your Details</h3>
        {/* <button
          onClick={() => {
            setIsModalVisible(true);
          }}
          type="button"
        >
          Edit
        </button> */}
      </div>
      <div className="company-details">
        <div className="item">
          <label>Name</label>
          <span>{company?.profile?.first_name}</span>
        </div>
        <div className="item">
          <label>DOB</label>
          <span>{moment(company?.profile?.dob).format("DD/MM/YYYY")}</span>
        </div>
        <div className="item">
          <label>City</label>
          <span>{company?.address?.[0]?.city}</span>
        </div>
        <div className="item">
          <label>State</label>
          <span>{company?.address?.[0]?.state}</span>
        </div>
        <div className="item">
          <label>Country</label>
          <span>{company?.address?.[0]?.country}</span>
        </div>
        <div className="item">
          <label>Phone Number</label>
          <span>{company?.profile?.primary_phone}</span>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailsModal;
