import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { get_all_payments } from "../../actions/z_accounts";
import { currencyMapper, payment_status } from "../../config/helper";
import BankShow from "../Common/BankShow";
import BaseCurrency from "../Common/BaseCurrency";
import Table from "../Common/Table/Table";
import { p_types } from "../Payments/SendMoney/Recipients";
import SendMoney from "../Payments/SendMoney/SendMoney";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import { useLocation, useHistory } from "react-router-dom";
import { Badge, Button, Dropdown, Menu, Space } from "antd";
// import Base from "antd/lib/typography/Base";

const AccountTransaction = ({ sendMoney, user, txns }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [page, setPage] = useState(1);
  const masterHistoryResponse = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );
  const totalRecords = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.total_count ?? []
  );

  console.log({ txns });

  React.useEffect(() => {
    // dispatch(accountTxns(({ page_number: 1 })));
    dispatch(
      headerTitles(
        sendMoney
          ? {
              title: "Send Money",
              description: "Payments Transfer to Recipients Accounts",
            }
          : {
              title: "account_transactions",
              description: "View all your global account transactions",
            }
      )
    );
    if (!sendMoney) {
      setOpenAddNew(false);
    }

    if (!openAddNew)
      dispatch(
        get_all_payments({
          params: {
            view: user ? undefined : "admin",
          },
          txns,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMoney, openAddNew, txns]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("txn_id"),
      dataIndex: "transaction_ref_no",
      key: "transaction_ref_no",
      render: (text, record) => (
        <>
          {record?.schedule_payment_date && (
            <div className="base_badge">
              <Badge.Ribbon
                text={`Schedule on ${record?.schedule_payment_date}`}
                placement={"start"}
                color="#3f81f4"
              />
            </div>
          )}
          {text}
        </>
      ),
    },
    {
      title: t("Sender Name"),
      dataIndex: "sender_account_details",
      key: "sender_account_details",
      render: (text) => text?.account_name,
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "beneficiary_details",
      key: "beneficiary_details",
      render: (text, rec) =>
        text?.beneficiary_name
          ? text?.beneficiary_name
          : rec?.counterparty_account_name,
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
    },
    {
      title: t("Date and Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: "Payment Method",
      dataIndex: "transfer_mode",
      key: "transfer_mode",
      render: (text) => p_types?.find((ele) => ele.value === text)?.label,
    },

    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, rec) => (
        <div>
          {currencyMapper[rec.currency]}
          {text}
        </div>
      ),
    },
    {
      title: "Txn Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => (
        <span
          style={{
            color: "#333",
            textTransform: "capitalize",
          }}
        >
          <img src={text === "credit" ? credit : debit} width={10} /> {text}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span className={`status status_${text?.toLowerCase()}`}>{text}</span>
      ),
    },
  ];

  const ben_columns = [
    {
      title: t("Transaction Id"),
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "payee_name",
      key: "payee_name",
    },
    {
      title: t("Beneficiary account"),
      dataIndex: "payee_account_no",
      key: "payee_account_no",
      render: (text) => <div>XXXX{text?.substr(4)}</div>,
    },
    {
      title: t("Mode of Payment"),
      dataIndex: "mode_of_payment",
      key: "mode_of_payment",
      render: () => "IMPS / RTGS",
    },

    {
      title: t("dateAndTime"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, r) => (
        <div>
          {r.currency_code} {text}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "role_status",
      key: "role_status",
      render: (text, record) => {
        return user ? (
          <div
            className={`status status_${record?.flow_status?.toLowerCase()}`}
          >
            {record?.flow_status?.replace(/_/g, " ")}
          </div>
        ) : (
          <div className={`status status_${text?.toLowerCase()}`}>
            {text?.replace(/_/g, " ")}
          </div>
        );
      },
    },
    {
      title: t("payment_status"),
      dataIndex: "payment_status",
      key: "payment_status",
      render: (text) => (
        <div className={`status status_${text?.toLowerCase()}`}>
          {text?.replace(/_/g, " ")}
        </div>
      ),
    },
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space
    //       size="middle"
    //       className="myspace"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //       }}
    //     >
    //       {record.flow_status === "unsubmitted" && (
    //         <Dropdown
    //           overlay={
    //             <Menu>
    //               <Menu.Item onClick={() => onClickEdit(record)}>
    //                 <img src={EditIcon} alt={""} className="action-logo" />
    //                 {t("edit")}
    //               </Menu.Item>
    //               <Menu.Item
    //                 onClick={() => {
    //                   setDeletePaymentModal(true);
    //                   setRecord(record);
    //                 }}
    //               >
    //                 <img
    //                   src={DeleteIcon}
    //                   alt={"logo"}
    //                   className="action-logo"
    //                 />{" "}
    //                 {t("delete")}
    //               </Menu.Item>
    //             </Menu>
    //           }
    //         >
    //           <Button
    //             shape="default"
    //             icon={<MoreOutlined />}
    //             style={{ border: "none", color: "#5B87F9" }}
    //           />
    //         </Dropdown>
    //       )}
    //     </Space>
    //   ),
    // },
  ];
  return openAddNew ? (
    <>
      <SendMoney
        setOpenAddNew={setOpenAddNew}
        cb={() => {
          dispatch(
            get_all_payments({
              params: {
                view: user ? undefined : "admin",
              },
              txns,
            })
          );
        }}
      />
    </>
  ) : (
    <>
      <BankShow
        noBack={true}
        noDownload={true}
        addBtn={sendMoney}
        setOpenAddNew={setOpenAddNew}
        text={"Send Money"}
        callAction={(acc, s_date, e_date) => {
          dispatch(
            get_all_payments({
              params: {
                account_id: acc,
                view: user ? undefined : "admin",
              },
              txns,
              filters: {
                created_at:
                  s_date && e_date
                    ? {
                        start_date: s_date,
                        end_date: e_date,
                      }
                    : undefined,
              },
            })
          );
        }}
      />
      <br />
      <Table
        rowKey="id"
        columns={txns ? columns : ben_columns}
        data={Array.isArray(masterHistoryResponse) ? masterHistoryResponse : []}
        isTopFilter={false}
        className="tableStyled"
        topFilters={[
          { label: t("all_trans"), action: () => {} },
          { label: t("advance_search"), action: () => {} },
        ]}
        scroll={{
          x: 1350,
        }}
        pagination={{
          total: totalRecords || 0,
          current: page,
          onChange: (pagee) => {
            dispatch(
              get_all_payments({
                params: {
                  view: user ? undefined : "admin",
                  page_number: pagee,
                },
                txns,
              })
            );

            setPage(pagee);
          },
        }}
      />
    </>
  );
};

export default AccountTransaction;
