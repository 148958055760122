import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const LineChart = () => {
  const svgRef = useRef();

  useEffect(() => {
    const data = [
      { date: new Date(2023, 0, 1), value: 0 },
      { date: new Date(2023, 0, 7), value: 0 },
      { date: new Date(2023, 0, 14), value: 10 },
      { date: new Date(2023, 0, 21), value: 40 },
      { date: new Date(2023, 0, 28), value: 35 },
    ];

    const width = 450;
    const height = 190;
    const margin = { top: 20, right: 30, bottom: 30, left: 40 };

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.date))
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const area = d3
      .area()
      .x((d) => xScale(d.date))
      .y0(height - margin.bottom)
      .y1((d) => yScale(d.value))
      .curve(d3.curveLinear);

    const line = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))
      .curve(d3.curveLinear);

    // Add the area
    svg.append("path").datum(data).attr("fill", "#E1F9F4").attr("d", area);

    // Add the line
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#8FE9D4")
      .attr("stroke-width", 1)
      .attr("d", line);

    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(width / 80)
          .tickSize(0) // Remove ticks

      )
      .select(".domain")
      .remove();

    // Add horizontal grid lines (y-axis grid lines without the domain)
    svg
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(
        d3
          .axisLeft(yScale)
          .ticks(4)
          .tickSize(-width + margin.left + margin.right)
          .tickFormat("") // Remove y-axis labels
      )
      .attr("opacity", 0.05)
      .select(".domain")
      .remove(); // Remove the domain line (y-axis line)
  }, []);

  return <svg ref={svgRef}></svg>;
};

export default LineChart;
