import { Button } from "antd";
import React, { useEffect } from "react";
import success_icon from "../../assets/icons/success_icon.svg";
import success_gif from "../../assets/icons/success_gif.gif";
import { useHistory } from "react-router-dom";
const SuccessPage = ({ accountDetails }) => {
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("reject_reason")) {
      localStorage.removeItem("reject_reason");
    }
  }, []);
  return (
    <div className="success_section">
      <div className="success_container d-flex-center flex-col ">
        <div className="image_section mb2">
          <img src={success_gif} alt="success page icon" />
        </div>
        <div className="application">
          <h4>Congratulations! Your Account is now opened and activated</h4>
        </div>
        {accountDetails?.account_number && (
          <div className="d-flex">
            <div
              className="application"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="application_note">
                <span>Your account number is: </span>
              </div>
              <h4>{accountDetails?.account_number}</h4>
            </div>
          </div>
        )}
        <div className="application_note">
          <span>
            Your Debit Card will be dispatched to your mailing address within 72
            hours
          </span>
        </div>
        <div className="button_section mt3">
          <Button
            type="primary"
            className="pp-main-button "
            onClick={() => {
              localStorage.clear();
              history.push("/");
            }}
          >
            <span>Sign In</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
