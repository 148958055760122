import React from "react";
import { Card, Tag, Row, Col, Divider } from "antd";

// Merchant restrictions data
const merchants = [
  { name: "Uber", restricted: false },
  { name: "United Airlines", restricted: false },
  { name: "Marriott", restricted: false },
  { name: "Lyft", restricted: false },
  { name: "Jetblue", restricted: false },
  { name: "Delta", restricted: false },
];

// Category restrictions data
const categories = [
  { name: "Alcohol and Bars", restricted: true },
  { name: "Entertainment", restricted: true },
  { name: "Gambling", restricted: true },
  { name: "Clubs and memberships", restricted: true },
];

// Countries to whitelist data
const countries = [
  { name: "USA", flag: "US" },
  { name: "UK", flag: "GB" },
  { name: "UAE", flag: "AE" },
  { name: "India", flag: "IN" },
];

// Merchant specific limits
const limits = {
  domesticPOS: "$10,000 per Day",
  internationalPOS: "$10,000 per Transaction",
  domesticATM: "$100 per Day",
  internationalATM: "$22,000 per Transaction",
  domesticOnline: "$50,000 per Month",
  internationalOnline: "$50,000 per Transaction",
};

const AdvanceCardControls = () => {
  return (
    <div className="other-info advanced-controls">
      {/* Merchant Restrictions */}
      <label className="title">Merchant Restrictions</label>
      <Row gutter={[16, 16]}>
        {merchants.map((merchant) => (
          <Col key={merchant.name}>
            <Tag color={merchant.restricted ? "red" : "green"}>
              {merchant.name}
            </Tag>
          </Col>
        ))}
      </Row>
      <Divider />

      {/* Category Restrictions */}
      <label className="title">Category Restrictions</label>
      <Row gutter={[16, 16]}>
        {categories.map((category) => (
          <Col key={category.name}>
            <Tag color="red">{category.name}</Tag>
          </Col>
        ))}
      </Row>

      <Divider />
      {/* Countries to Whitelist */}
      <label className="title">Countries to Whitelist</label>
      <Row gutter={[16, 16]}>
        {countries.map((country) => (
          <Col key={country.name}>
            <Tag color="green">
              <img
                src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${country?.flag?.toLowerCase()}.svg`}
                className="mr1"
                width={30}
                style={{
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                }}
              />
              {country.name}
            </Tag>
          </Col>
        ))}
      </Row>

      <Divider />
      {/* Merchant Specific Limits */}
      <label className="title">Merchant Specific Limits</label>
      <Row gutter={[16]} className="other-info mb2">
        <Col span={6}>
          <p>
            <label>Domestic POS Transactions: </label>
          </p>
          <p>{limits.domesticPOS}</p>
        </Col>
        <Col span={6}>
          <p>
            <label>Domestic ATM Withdrawal: </label>
          </p>
          <p>{limits.domesticATM}</p>
        </Col>
        <Col span={6}>
          <p>
            <label>Domestic Online Transactions: </label>
          </p>
          <p>{limits.domesticOnline}</p>
        </Col>
      </Row>
      <Row gutter={[16]}>
        <Col span={6}>
          <p>
            <label>International POS Transactions: </label>
          </p>
          <p>{limits.internationalPOS}</p>
        </Col>
        <Col span={6}>
          <p>
            <label>International Online Transactions: </label>
          </p>
          <p>{limits.internationalOnline}</p>
        </Col>
        <Col span={6}>
          <p>
            <label>International ATM Withdrawal: </label>
          </p>
          <p>{limits.internationalATM}</p>
        </Col>
      </Row>
    </div>
  );
};

export default AdvanceCardControls;
