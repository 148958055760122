import { Modal, Upload, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import png_icon from "../../../../assets/icons/pdf_file_icon.png";
import { apis } from "../../../../config/APIs";
import useDocumentAxios from "../../../../config/useDocument";
import { getFileExtension } from "../../../../utils";
import { FormInput, FormSelect } from "../../../inputs";
import ButtonUpload from "../../../inputs/ButtonUpload";
import DateInput from "../../../inputs/DateInput";
import FormRadio from "../../../inputs/FormRadio";
import KYCHeader from "../../CommonScreens/KYCHeader";
import delete_cross from "../../assets/icons/delete_cross_icon.svg";
import preview_icon from "../../assets/icons/preview_icon.svg";
import { onboardPageDetails } from "../OnBoardLeftNavigation/OnBoardLeftNavigation";
import { fe_steps } from "./steps";
import DocumentList from "./DocList";
import CapTure from "../../../inputs/CapTure";
import PasswordCheck from "../../../Common/PasswordCheck";
import usePayAxios from "../../../../config/useAxios";
import { gridApis } from "../../../../config/GridApis";

const BusinessKYCFields = ({
  control,
  register,
  errors,
  setValue,
  watch,
  setFileData,
  fileData,
  formStep,
}) => {
  const { onCall } = usePayAxios({
    type: "grid",
    api: gridApis.userDetails,
    method: "get",
  });

  const fieldObject = fe_steps[formStep];
  const [showDoc, setShowDoc] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
  });
  const {
    onCall: DocumentCreate,
    reset: DocumentCreateReset,
    loading: DocumentCreateLoading,
  } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });
  const { onCall: DocumentDelete, loading: DocumentDeleteLoading } =
    useDocumentAxios({
      api: apis.deleteDocument,
      method: "delete",
      baseURL: "document",
    });

  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleBeforeUpload = async (file, fieldType) => {
    const fileName = getFileExtension(file?.name);
    if ([...fieldType]?.includes(fileName)) {
    } else {
      message.error(
        <span className="messageText">{`Please Upload ${fieldType?.join(
          " or "
        )} files`}</span>
      );
      return Upload.LIST_IGNORE;
    }
    return false;
  };
  const handleFileDelete = async (targetKey, targetValue, isRemove = false) => {
    const deleteData = await DocumentDelete({
      data: {
        ids: [targetValue],
      },
      params: {
        record_id: getObjectByFieldNameAndValue(targetKey, targetValue)?.uuid,
      },
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });

    if (deleteData?.error == false) {
      if (!isRemove) {
        setValue(targetKey, "");
      }
      setFileData((prevData) => {
        return {
          ...prevData,
          [targetKey]: (prevData[targetKey] || []).map((item, i) => {
            if (item[targetKey] == targetValue) {
              return {
                ...item,
                attachment_id: "",
                url: "",
                uploadFileName: "",
                fieldName: "",
                uuid: "",
              };
            }
            return item;
          }),
        };
      });
    }
  };
  const handleFileUpload = async (fieldName, uploadFile) => {
    let uniqueNo = uuidv4();

    const formData = new FormData();
    formData.append("files", uploadFile?.file);
    formData.append("name", fieldName);
    formData.append("record_type", "Document");
    formData.append("record_id", uniqueNo);

    const attachId = await DocumentCreate({
      data: formData,
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });
    if (attachId?.error == false) {
      await getBase64(uploadFile.file, (url) => {
        setValue(fieldName, attachId?.data[0]);
        setFileData((prevData) => {
          const newData = { ...prevData };
          const currentArray = newData[fieldName] || [];

          // Add new item
          currentArray.push({
            fieldName: fieldName,
            attachment_id: attachId?.data[0],
            uuid: uniqueNo,
            url: url,
            uploadFileName: uploadFile?.file?.name,
          });

          newData[fieldName] = currentArray?.filter(
            (items) => items?.attachment_id
          );
          return newData;
        });
      });
      DocumentCreateReset();
    }
  };

  const getObjectByFieldNameAndValue = (targetKey, targetValue) => {
    return fileData[targetKey]?.find(
      (ele) => ele.attachment_id === targetValue
    );
  };

  useEffect(() => {
    if (fieldObject?.length) {
      fieldObject?.forEach((ele) => {
        if (ele.defaultValue) setValue(ele.name, ele.defaultValue);
      });
    }
  }, [fieldObject]);

  useEffect(() => {
    if (formStep >= 3) {
      onCall({
        params: {
          step: formStep,
        },
      }).then((res) => {
        fieldObject?.forEach((ele) => {
          if (res?.data?.user_identity_details?.[ele.name]) {
            setValue(ele.name, res?.data?.user_information_details?.[ele.name]);
          } else if (
            res?.data?.user_address_details?.[
              ele.name?.replace("address.", "")
            ] &&
            ele.name?.includes("address.")
          )
            setValue(
              ele.name,
              res?.data?.user_address_details?.[
                ele.name?.replace("address.", "")
              ]
            );
          else if (
            res?.data?.user_information_details?.[ele.name] ||
            ele.name === "are_you_us_citizen"
          ) {
            if (ele.name === "are_you_us_citizen")
              if (res?.data?.user_information_details?.[ele.name])
                setValue(ele.name, "yes");
              else setValue(ele.name, "no");
            else
              setValue(
                ele.name,
                res?.data?.user_information_details?.[ele.name]
              );
          }
        });
      });
    }
  }, [formStep]);

  const are_you_us_citizen = watch("are_you_us_citizen");
  const password = watch("password");
  const type_of_id = watch("type_of_id");
  return (
    <>
      {(DocumentCreateLoading || DocumentDeleteLoading) && (
        <div className="text-center loadingDiv">
          <PulseLoader color="#fff" />
        </div>
      )}
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <img
            src={isPreviewOpen?.url}
            alt="preview image"
            style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
          />
        )}
      </Modal>
      <div>
        <div className="business-registration-section">
          <div className="top-section">
            <KYCHeader Header={onboardPageDetails[formStep - 3]} />
          </div>
          <div className="business-registration-container">
            {!showDoc && formStep == 4 && !type_of_id ? (
              <DocumentList
                setDoc={(d) => {
                  setValue("type_of_id", d.title);
                  setShowDoc(d);
                }}
              />
            ) : (
              <>
                {fieldObject?.map((fieldElement) => {
                  switch (fieldElement?.type) {
                    case "capture":
                      return (
                        <>
                          <div className="pp-form-item businessFields">
                            <CapTure
                              label={fieldElement?.label}
                              imageUrl={
                                getObjectByFieldNameAndValue(
                                  fieldElement?.name,
                                  watch(fieldElement.name)
                                )?.url
                              }
                              onlyImg={getObjectByFieldNameAndValue(
                                fieldElement?.name,
                                watch(fieldElement.name)
                              )}
                              hasVal={watch(fieldElement.name)}
                              onCapture={(data) => {
                                handleFileUpload(fieldElement.name, {
                                  file: data,
                                });
                              }}
                            />
                          </div>
                        </>
                      );
                    case "divider":
                      return (
                        <div>
                          {fieldElement?.label && (
                            <div className="divider-label">
                              {fieldElement?.label}
                            </div>
                          )}
                        </div>
                      );
                    case "dropdown":
                      return are_you_us_citizen === "yes" &&
                        fieldElement.name ===
                          "choose_residence_country" ? null : (
                        <>
                          <div className="pp-form-item businessFields">
                            <FormSelect
                              inline
                              control={control}
                              {...register(
                                fieldElement.name,
                                fieldElement.validation
                              )}
                              errors={errors}
                              label={fieldElement.label}
                              placeholder={fieldElement.placeholder}
                              //   onSearch={onVendorSearch}
                              options={fieldElement.options}
                              //   onAddItem={handlerNewVendor}
                              hideCreateOption={true}
                              required={true}
                              noPaddingTop={true}
                              filterKey={fieldElement?.filterKey}
                            />
                          </div>
                        </>
                      );
                    case "date":
                      return (
                        <>
                          <div className="pp-form-item businessFields">
                            <DateInput
                              control={control}
                              {...register(
                                fieldElement.name,
                                fieldElement.validation
                              )}
                              errors={errors}
                              label={fieldElement.label}
                              placeholder={fieldElement.placeholder}
                              required={true}
                              isTime={false}
                              picker={fieldElement?.picker}
                              dateFormat={
                                fieldElement.dateFormat
                                  ? [fieldElement.dateFormat]
                                  : ["DD-MM-YYYY"]
                              }
                            />
                          </div>
                        </>
                      );
                    case "document":
                      return are_you_us_citizen === "yes" &&
                        fieldElement.name === "govt_issued_id" ? null : (
                        <>
                          <div className="pp-form-item buttonUpload pt0">
                            <ButtonUpload
                              control={control}
                              {...register(fieldElement?.name, {
                                ...fieldElement?.validation,
                                onChange: (e) => {
                                  handleFileUpload(
                                    fieldElement?.name,
                                    e.target?.value
                                  );
                                },
                              })}
                              required={fieldElement.is_mandatory}
                              errorMessage={
                                errors?.[fieldElement?.name]?.message
                              }
                              errors={errors}
                              label={fieldElement?.label}
                              multiple={false}
                              beforeUpload={(e) =>
                                handleBeforeUpload(
                                  e,
                                  fieldElement?.file_type,
                                  fieldElement?.name
                                )
                              }
                              isBusinessKYC={true}
                              accept={fieldElement?.file_type}
                              width={"60%"}
                              hasId={
                                getObjectByFieldNameAndValue(
                                  fieldElement?.name,
                                  watch(fieldElement.name)
                                )
                                  ? true
                                  : false
                              }
                              renderUploaded={
                                Object.keys(fileData).includes(
                                  fieldElement?.name
                                ) &&
                                watch(fieldElement.name) && (
                                  <div className="business_image_section mt3">
                                    <div className="upload-sec mb1">
                                      <div className="left_section d-flex">
                                        <div className="image_section mr1">
                                          {(() => {
                                            const fileobject =
                                              getObjectByFieldNameAndValue(
                                                fieldElement?.name,
                                                watch(fieldElement.name)
                                              );
                                            if (
                                              ["pdf"]?.includes(
                                                getFileExtension(
                                                  fileobject?.uploadFileName
                                                )?.toLowerCase()
                                              )
                                            ) {
                                              return <img src={png_icon} />;
                                            } else {
                                              return (
                                                <img src={fileobject?.url} />
                                              );
                                            }
                                          })()}
                                        </div>
                                        <div className="image_name">
                                          <h4 className="file_name">
                                            {
                                              getObjectByFieldNameAndValue(
                                                fieldElement?.name,
                                                watch(fieldElement.name)
                                              )?.uploadFileName
                                            }
                                          </h4>
                                          {/* <span className='file_verify'>Verify</span> */}
                                        </div>
                                      </div>
                                      <div className="right_section">
                                        <div className="active_section d-flex">
                                          <div
                                            className="preview_section mr1"
                                            onClick={() => {
                                              let fileobject =
                                                getObjectByFieldNameAndValue(
                                                  fieldElement?.name,
                                                  watch(fieldElement.name)
                                                );
                                              setIsPreviewOpen({
                                                open: true,
                                                fileType: getFileExtension(
                                                  fileobject?.uploadFileName
                                                ),
                                                url: getObjectByFieldNameAndValue(
                                                  fieldElement?.name,
                                                  watch(fieldElement.name)
                                                )?.url,
                                              });
                                            }}
                                          >
                                            <img
                                              src={preview_icon}
                                              alt="preview-icon"
                                              className="preview_img w100 h100"
                                            />
                                          </div>
                                          <div
                                            className="delete_section"
                                            onClick={() => {
                                              handleFileDelete(
                                                fieldElement?.name,
                                                watch(`${fieldElement?.name}`)
                                              );
                                            }}
                                          >
                                            <img
                                              src={delete_cross}
                                              alt="delete-icon"
                                              className="delete_img w100 h100"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            />
                          </div>
                        </>
                      );
                    case "radio":
                      return (
                        <>
                          <div
                            className="pp-form-item businessFields"
                            style={{ display: "block" }}
                          >
                            <FormRadio
                              control={control}
                              {...register(
                                fieldElement.name,
                                fieldElement.validation
                              )}
                              errors={errors}
                              label={fieldElement?.label}
                              options={fieldElement?.radio_fields}
                            />
                          </div>
                        </>
                      );
                    default:
                      return (are_you_us_citizen === "no" &&
                        fieldElement.name === "ssn_details") ||
                        (are_you_us_citizen === "yes" &&
                          fieldElement.name === "govt_issued_id") ||
                        (are_you_us_citizen === "yes" &&
                          fieldElement.name ===
                            "govt_issued_id_type") ? null : (
                        <>
                          <div className="pp-form-item businessFields">
                            <FormInput
                              control={control}
                              {...register(
                                fieldElement.name,
                                fieldElement.validation
                              )}
                              errors={errors}
                              label={fieldElement.label}
                              placeholder={fieldElement.placeholder}
                              type={fieldElement.type}
                              required={true}
                              number={
                                fieldElement.type === "number" ? true : false
                              }
                            />
                          </div>
                        </>
                      );
                  }
                })}
              </>
            )}
            {password && <PasswordCheck password={password} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessKYCFields;
