import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../Common/TableComp/TableComp";
import { cards, userCards } from "./../../actions/cards/cards";
// import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import json2csv from "json2csv";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { headerTitles } from "../../actions/masterdata/masterdata";
import card_icon from "../../assets/icons/card.png";
import { apis } from "../../config/APIs";
import endpoint from "../../config/Axios";
import TableHeader from "../Sales/TableHeader";
import CardPDF from "./CardPDF";
import AddCard from "./AddCard";
import { debitData } from "./DebitData";
import { amountFormat } from "../../utils";
import { Tag } from "antd";

export const status = {
  Activated: "approved",
  Y: "approved",
  COMPLETED: "approved",
  N: "pending_approval",
  "Permanent Hotlist": "rejected",
  "Issued not active": "pending_approval",
  "Generated not issued": "pending_reimbursement",
};

export const statuses = {
  Y: "Completed",
  N: "Pending",
};

const data = [
  {
    id: "3d5a2739-457d-46fc-852e-a342a32732f0",
    status: "Activated",
    card_reference_no: "MWC000000010",
    card_number: "8174 **** **** 0106",
    mobile_no: "9890601183",
    correspondence_mobile: "9890601183",
    employee_details: {
      employee_id: "PS002",
      name: "DHANASHRI RANJEET CHAVAN",
      email: "dhanashri.chavan@paycraftsol.com",
      mobile: "9890601183",
    },
    balance: "3343.00",
    expense_wallet: 0,
    reimburse_wallet: 0,
    company_name: "S office technologie",
    data: {
      firstName: "DHANASHRI",
      lastName: "CHAVAN",
      accountNumber: "MWC00000000000000010",
      dateOfBirth: null,
      primaryMobileNumber: "9890601183",
      communicationMobileNumber: "9834391977",
      address: "Wakad Pune",
      cardStatus: "A",
      cardStatusReason: null,
      kycFlag: "COMPLETED",
      productCode: "0",
      cardActivatedOn: "28-12-2022 11:10:58",
      maskCardNumber: "8174 **** **** 0106",
      cardBalance: "3343.00",
      walletNameAndBalance: [
        {
          balance: "1110.00",
          walletName: "TRANSIT",
        },
        {
          balance: "149.00",
          walletName: "EXPENSE",
        },
        {
          balance: "1650.00",
          walletName: "MEAL",
        },
        {
          balance: "156.00",
          walletName: "FUEL",
        },
        {
          balance: "178.00",
          walletName: "REIMBURSE",
        },
        {
          balance: "100.00",
          walletName: "GIFT",
        },
      ],
      channelType: ["ATM", "POS", "ECOM", "CTLS"],
      emailId: "kranti.sherkar@paycraftsol.com",
      pan: "CJYPC8674F",
      cardExpiry: "0223",
      last5Transactions: [
        {
          amount: "90.00",
          dateAndTime: "03-02-2023 11:37:17",
          crDr: "Cr",
          merchantName: "Paycraft MO",
        },
        {
          amount: "30.00",
          dateAndTime: "02-02-2023 17:29:42",
          crDr: "Cr",
          merchantName: "Paycraft MO",
        },
        {
          amount: "98.00",
          dateAndTime: "02-02-2023 11:59:40",
          crDr: "Cr",
          merchantName: "Paycraft MO",
        },
        {
          amount: "76.00",
          dateAndTime: "02-02-2023 11:57:32",
          crDr: "Cr",
          merchantName: "Paycraft MO",
        },
        {
          amount: "89.00",
          dateAndTime: "02-02-2023 11:55:46",
          crDr: "Cr",
          merchantName: "Paycraft MO",
        },
      ],
      cardType: "Physical Card",
      card_limits: [
        {
          channelType: "ATM",
          cardUsage: "dom",
          walletLimitForWalletTypeIdentifierList: [],
          currentDailyLimit: 1000,
          currentMonthlyLimit: 3000,
          currentAnnualLimit: 6000,
          maxDailyLimit: 20000,
          maxMonthlyLimit: 50000,
          maxAnnualLimit: 600000,
        },
        {
          channelType: "POS",
          cardUsage: "dom",
          walletLimitForWalletTypeIdentifierList: [],
          currentDailyLimit: 1200,
          currentMonthlyLimit: 2400,
          currentAnnualLimit: 4800,
          maxDailyLimit: 200000,
          maxMonthlyLimit: 200000,
          maxAnnualLimit: 200000,
        },
        {
          channelType: "ECOM",
          cardUsage: "dom",
          walletLimitForWalletTypeIdentifierList: [],
          currentDailyLimit: 2000,
          currentMonthlyLimit: 4000,
          currentAnnualLimit: 8000,
          maxDailyLimit: 200000,
          maxMonthlyLimit: 200000,
          maxAnnualLimit: 200000,
        },
        {
          channelType: "CTLS",
          cardUsage: "dom",
          walletLimitForWalletTypeIdentifierList: [],
          currentDailyLimit: 4000,
          currentMonthlyLimit: 6000,
          currentAnnualLimit: 9000,
          maxDailyLimit: 200000,
          maxMonthlyLimit: 200000,
          maxAnnualLimit: 200000,
        },
      ],
    },
    kyc_flag: "COMPLETED",
    card_activated_on: "28 Dec'22, 11:10:58 am",
    replacement_request_placed_on: null,
    replacement_request_placed: false,
    tenant_id: null,
    wallets: [
      {
        id: "19270960-6da2-4d6b-9405-44765ce34377",
        wallet_name: "TRANSIT",
        wallet_identifier: "transit",
        balance: "1110.00",
      },
      {
        id: "cc707656-c8ed-4df3-837a-af6936e78292",
        wallet_name: "EXPENSE",
        wallet_identifier: "expense",
        balance: "149.00",
      },
      {
        id: "0f6b24f4-ddfb-48d9-bde3-c79f260f4384",
        wallet_name: "MEAL",
        wallet_identifier: "meal",
        balance: "1650.00",
      },
      {
        id: "671ef569-9017-4a9a-b71d-d1f18186c25d",
        wallet_name: "FUEL",
        wallet_identifier: "fuel",
        balance: "156.00",
      },
      {
        id: "1bb9e05f-eb39-4755-8088-9571d64e176d",
        wallet_name: "REIMBURSE",
        wallet_identifier: "reimburse",
        balance: "178.00",
      },
      {
        id: "52dad796-0779-4302-a594-a60711189a66",
        wallet_name: "GIFT",
        wallet_identifier: "gift",
        balance: "100.00",
      },
    ],
  },
];
const Cards = ({ user, debit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [createCard, setCreateCard] = useState(false);
  const cardsResponse = useSelector((state) => state.cards.cards || {});
  const userCardsResponse = useSelector((state) => state.cards.userCards || {});
  const { data: { response: { cards: cardsList = [] } = {} } = {} } =
    cardsResponse;
  const { data: { response: { cards: userCardsList = [] } = {} } = {} } =
    userCardsResponse;
  const dispatch = useDispatch();
  const is_admin = useSelector(
    (state) => state?.auth?.localVariables?.is_admin
  );
  const [filters, setFilters] = React.useState({
    status: "A",
  });
  const location = useLocation();
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    if (!user !== "") {
      let obj = {};
      if (location?.state?.non_linked) {
        obj.non_linked = location?.state?.non_linked;
      }
      if (location?.state?.prevStatus) obj.status = location?.state?.status;
      else obj.status = "A";
      setFilters(obj);
      !user
        ? dispatch(cards({ non_linked: false, source: "admin", ...obj }))
        : dispatch(userCards({}));
    }
    dispatch(
      headerTitles({
        title: debit ? "Debit Cards" : "Prepaid Cards",
        description: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!user]);

  const columns = [
    {
      title: t("card_number"),
      dataIndex: "card_number",
      key: "card_number",
      width: 150,
      render: (text) => (
        <div>
          <img src={card_icon} width={25} alt="card" className="mr1" />
          {text?.includes("XXX")
            ? text
                ?.replace(/\w{4}(?=.)/g, "$& ")
                ?.replaceAll("X", "*")
                ?.slice(text?.length - 7)
            : text?.slice(text?.length - 9)}
        </div>
      ),
    },
    {
      title: t("card_ref_no"),
      dataIndex: "card_reference_no",
      key: "card_reference_no",
      width: 150,
    },
    // {
    //   title: "Employee ID",
    //   dataIndex: "empid",
    //   key: "empid",
    //   width: 150,
    //   render: (text, record) => <div>{record.employee_details?.employee_id}</div>,
    // },
    {
      title: t("employee_name"),
      dataIndex: "company_name",
      key: "company_name",
      width: 250,
      render: (text, record) => <div>{record.employee_details?.name}</div>,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    //   width: 250,
    //   render: (text, record) => <div>{record.employee_details?.email}</div>,
    // },
    {
      title: debit ? "Spent this month" : t("total_balance"),
      dataIndex: "balance",
      key: "balance",
      width: 150,
      render: (text) => <div>$ {amountFormat(text)}</div>,
    },
    debit
      ? {
          title: t("Type"),
          dataIndex: "type",
          key: "type",
          width: 200,
          render: (text) => <Tag>{text}</Tag>,
        }
      : {
          title: t("activated_on"),
          dataIndex: "card_activated_on",
          key: "card_activated_on",
          width: 200,
          // render: (text) => <div>9 Sep'18</div>,
        },
    {
      title: t("Linked Account"),
      dataIndex: "data",
      key: "data",
      width: 150,
      render: (text) => text.accountNumber,
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      width: 250,
      render: (text) => (
        <div className={`status status_${status[text] ?? text?.toLowerCase()}`}>
          {text === "Generated not issued" ? "Un-Assigned" : text}
        </div>
      ),
    },
    // filters.status === "PHL"
    //   ? {
    //       title: t("reason"),
    //       dataIndex: "reason",
    //       key: "reason",
    //       render: (text, record) => <div>{record?.data?.cardStatusReason}</div>,
    //     }
    //   : {
    //       title: t("Linked Account"),
    //       dataIndex: "accountNumber",
    //       key: "accountNumber",
    //       width: 150,
    //       render: (text) => text,
    //     },
  ];

  const downloadPdf = async (fileName, cards) => {
    if (cards.length > 0) {
      const blob = await pdf(<CardPDF data={cards || []} />).toBlob();
      saveAs(blob, fileName);
    }
  };

  const handleRowClick = (evt, record) => {
    evt.preventDefault();
    if (record) {
      history.push({
        pathname: `/card-details/${record.id}`,
        state: {
          cardData: record,
          status: filters.status,
          non_linked: filters.non_linked,
          debit,
        },
      });
    }
  };

  const handleFilter = (value) => {
    if (value === "true") {
      !user
        ? dispatch(cards({ status: true, source: "admin" }))
        : dispatch(userCards({ status: true }));
    } else {
      if (typeof value === "object") {
        !user
          ? dispatch(cards({ ...value, source: "admin" }))
          : dispatch(userCards(value));

        setFilters({
          ...value,
          search: filters.search,
          source: "admin",
        });
      } else {
        !user
          ? dispatch(cards({ status: value, source: "admin" }))
          : dispatch(userCards({ status: value }));

        setFilters({ status: value });
      }
    }
    setPage(1);
  };

  const handleSearch = (value) => {
    !user
      ? dispatch(cards({ ...filters, search: value, source: "admin" }))
      : dispatch(userCards({ ...filters, search: value }));

    setFilters({
      ...filters,
      search: value,
      source: "admin",
    });
    setPage(1);
  };
  const options = [
    { label: t("all_cards"), filter: "", isActive: filters.status === "" },
    {
      label: t("active"),
      filter: { status: "A", non_linked: false },
      isActive: filters.status === "A",
    },
    {
      label: t("not_activated"),
      filter: { status: "INA", non_linked: false },
      isActive: filters.status === "INA",
    },
    {
      label: t("hot_list"),
      filter: { status: "PHL", non_linked: false },
      isActive: filters.status === "PHL",
    },
    {
      label: t("temporary_hot_list"),
      filter: { status: "THL", non_linked: false },
      isActive: filters.status === "THL",
    },
    {
      label: t("not_assigned"),
      filter: { non_linked: true },
      isActive: filters.non_linked,
    },
  ];

  return (
    <>
      {createCard ? (
        <AddCard setOpenAddNew={setCreateCard} />
      ) : (
        <>
          {!user && (
            <TableHeader
              filterMenu={options}
              handleFilter={handleFilter}
              onSearch={handleSearch}
              noDD
              buttonLabel={"Create Card"}
              hasWriteAccess={true}
              setOpenAddNew={setCreateCard}
            />
          )}
          <div style={{ paddingTop: "15px" }}>
            <Table
              rowKey="id"
              className="tableStyled"
              columns={columns}
              hasCheckbox={false}
              // data={!user ? cardsList : userCardsList}
              data={debit ? debitData : data}
              handleRowClick={handleRowClick}
              placeholder={t("search_by_ref")}
              hasExport={is_admin}
              scroll={{ x: 1400 }}
              onExport={(value) => {
                endpoint
                  .get(apis.cards, {
                    params: {
                      source: "admin",
                      download: true,
                    },
                  })
                  .then((res) => {
                    let cards = res?.data?.response?.cards;
                    if (value === "PDF") {
                      downloadPdf("Cards.pdf", cards);
                    } else if (value === "CSV") {
                      if (cards?.length > 0) {
                        cards?.forEach((card) => {
                          card.employee_name = card.employee_details?.name;
                          delete card.data;
                          delete card.employee_details;
                          delete card.wallets;
                          delete card.id;
                        });
                        let csv = json2csv.parse(cards);
                        var blob = new Blob([csv], {
                          type: "text/csv;charset=utf-8",
                        });
                        saveAs(blob, "Cards.csv");
                      }
                    }
                  });
              }}
              pagination={{
                total: cardsResponse?.data?.total_records || 0,
                current: page,
                onChange: (pagee) => {
                  !user
                    ? dispatch(
                        cards({ page: pagee, source: "admin", ...filters })
                      )
                    : dispatch(userCards({ page: pagee, ...filters }));
                  setPage(pagee);
                },
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Cards;
