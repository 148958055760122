import React, { useEffect } from "react";
import inv from "./assets/inv_icon.png";
import u_edit from "./assets/u_edit.png";
import clock from "./assets/clock.png";
import up from "./assets/up.png";
import { useForm } from "react-hook-form";
import { FormInput, FormSelect } from "../../inputs";
import { Col, Row, Switch, Upload } from "antd";
import DateInput from "../../inputs/DateInput";
import { useSelector } from "react-redux";
import moment from "moment";
const Dragger = Upload.Dragger;

const CreateRequest = ({
  next,
  register,
  handleSubmit,
  setDBAcc,
  control,
  errors,
  watch,
  setValue,
  setMainData,
  mainData,
  setCAcc,
  cancel,
  step,
}) => {
  const BeneficiaryBankDetails = useSelector((state) =>
    state?.customers?.all?.data?.data?.map((ele) => ({
      ...ele,
      label:
        ele?.beneficiary_name + "-" + ele?.bank_accounts?.[0]?.account_number,
      value: ele?.bank_accounts?.[0]?.beneficiary_id,
    }))
  );
  const data1 = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );

  const onSubmit = (data) => {
    setMainData({ ...data, due_date: data?.due_date?.format("YYYY-MM-DD") });
    next();
  };

  useEffect(() => {
    if (mainData?.beneficiary_id) {
      setValue("beneficiary_id", mainData?.beneficiary_id);
      setValue("amount", mainData?.amount);
      setValue("due_date", moment(mainData?.due_date));
      setValue("ourparty_account_id", mainData?.ourparty_account_id);
      setValue("ourparty_display_name", mainData?.ourparty_display_name);
      setValue("enable_card_payments", mainData?.enable_card_payments);
      setValue("description", mainData?.description);
    }
  }, []);

  const due_date = watch("due_date");
  const enable_card_payments = watch("enable_card_payments");
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Create a payment request</h2>

        <div className="sending-inv">
          <img src={inv} width={40} />
          <p>Sending an invoice? Try Zenus Invoicing</p>
        </div>

        <div className="contact-details">
          <h4>Contact details</h4>
          <p>
            Select an existing contact or create one by entering their name and
            email below.
          </p>
        </div>

        <div className="form-body">
          <div className="pp-form-item">
            <FormSelect
              inline
              {...register("beneficiary_id", {
                onChange: (e) => {
                  const benAcc = BeneficiaryBankDetails?.find(
                    (ele) =>
                      ele?.bank_accounts?.[0]?.beneficiary_id === e.target.value
                  );
                  setCAcc(benAcc);
                },
              })}
              label={"Send payment request to"}
              control={control}
              options={BeneficiaryBankDetails}
              hideCreateOption={true}
              placeholder={"Select"}
              required
              errors={errors}
            />
          </div>
          <span className="edit-link">
            <img src={u_edit} width={18} /> Edit Contact Details
          </span>
          <div className="contact-details mb2">
            <h4>Request details</h4>
          </div>
          <Row gutter={[16]}>
            <Col span={12}>
              <FormInput
                control={control}
                prefix={"$"}
                label={"Amount"}
                {...register("amount")}
                placeholder={"Enter Amount"}
                errors={errors}
                number={true}
              />
            </Col>
            <Col span={12}>
              <DateInput
                control={control}
                {...register("due_date")}
                errors={errors}
                required={true}
                label={"Due Date"}
                isTime={false}
                dateFormat={["DD/MM/YYYY"]}
              />
              <p className="helper-text text-right">30 days from now</p>
            </Col>
          </Row>
          <div className="pp-form-item">
            <FormInput
              inline
              {...register("ourparty_display_name", {})}
              label={"Name to show on the request"}
              control={control}
              hideCreateOption={true}
              placeholder={"Enter"}
              errors={errors}
            />
          </div>
          <div className="pp-form-item">
            <FormSelect
              inline
              {...register("ourparty_account_id", {
                onChange: (e) => {
                  setDBAcc(data1?.find((ele) => ele.id === e.target.value));
                },
              })}
              label={"Destination Account"}
              control={control}
              options={data1?.map((ele) => ({
                label: `${ele?.account_name} - ${ele?.account_number}`,
                value: ele?.id,
              }))}
              hideCreateOption={true}
              placeholder={"Select"}
              errors={errors}
            />
            <p className="helper-text">
              Incoming payments are routed through a secure receiving account so
              your account details remain private.
            </p>
          </div>
          <div className="expires">
            <img src={clock} width={15} />
            Payment link expires on{" "}
            {due_date ? due_date?.format("MMMM DD, YYYY") : ""}
          </div>

          <div className="contact-details ">
            <h4>Payment methods</h4>
            <p>
              Payments made via manual ACH, wire, or Pay with Zenus are accepted
              by default and always free.
            </p>
          </div>
          <div className="pp-form-item  p-method">
            <Switch
              checked={enable_card_payments}
              onChange={(e) => {
                console.log({ e });

                setValue("enable_card_payments", e);
              }}
            />{" "}
            <span className="title">Accept card payments.</span>
            <span className="helper-text">
              {" "}
              You’ll pay a<span className="link">Stripe fee.</span>
            </span>
          </div>
          <div className="contact-details">
            <h4>Notes / attachments (optional)</h4>
            <p>These will be visible to the sender via the payment link.</p>
          </div>
          <div className="pp-form-item notes">
            <FormInput
              control={control}
              prefix={"$"}
              label={"Notes"}
              {...register("description")}
              placeholder={"Notes"}
              errors={errors}
              type={"textarea"}
            />
          </div>
          <div className="pp-form-item attachments">
            <label>Attachments</label>
            <Dragger>
              <img src={up} width={50} />
              <div className="texts">
                <strong>To upload, drag and drop or click here</strong>
                <p className="helper-text">
                  We can grab your recipient’s details automatically.
                </p>
              </div>
            </Dragger>
          </div>
        </div>
        <div className="action-btns">
          <button type="button" className="back-btn" onClick={cancel}>
            Cancel
          </button>
          <button className="send-btn">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default CreateRequest;
