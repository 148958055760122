import { call, put, takeEvery } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import endpointPayApi from "../../config/AxiosPayApi";
import { sagaFunctions } from "../../utils";
import {
  ChangePriority,
  CreatePolicy,
  Currencies,
  DeletePolicy,
  EnablePolicy,
  GetPolicies,
  GetPolicy,
  PoliciesMetadata,
  SeedData,
} from "./masterdata";

function* getSeedRecords(req) {
  try {
    let res = null;
    let seed_type = req.payload.seed_type;
    if (seed_type === "payment_terms") {
      delete req.payload.seed_type;
      res = yield call(endpointPayApi.get, apis.paymentTerms, {
        params: req.payload,
      });
    } else if (seed_type === "tds_types") {
      delete req.payload.seed_type;
      res = yield call(endpointPayApi.get, apis.tdsType, {
        params: req.payload,
      });
    } else
      res = yield call(endpointPayApi.get, apis.getSeedRecords, {
        params: req.payload,
      });
    if (res.status < 400) {
      yield put({
        type: SeedData.SUCCESS,
        payload: res.data,
        seed_type: seed_type,
      });
    } else {
      yield put({
        type: SeedData.FAILED,
        payload: res.data,
        seed_type: seed_type,
      });
    }
  } catch (err) {
    yield put({
      type: SeedData.FAILED,
      payload: err?.response?.data,
      seed_type: req.payload.seed_type,
    });
  }
}

function getCurrencies() {
  return sagaFunctions(
    Currencies,
    "get",
    `${apis.getCompanyCurrencyAPIs}`,
    {},
    {},
    true
  )();
}

function createPolicy(req) {
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    CreatePolicy,
    "post",
    `${
      req.payload?.id ? apis.updatePolicies : apis.createPolicies
    }?module_type=${type}`,
    req.payload,
    {},
    true
  )();
}

function enablePolicy(req) {
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    EnablePolicy,
    "post",
    `${apis.enablePolicy}?module_type=${type}`,
    req.payload,
    {},
    true
  )();
}

function deletePolicy(req) {
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    DeletePolicy,
    "post",
    `${apis.deletePolicy}?module_type=${type}`,
    req.payload,
    {},
    true
  )();
}

function getPolicy(req) {
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    GetPolicy,
    "post",
    `${apis.getPolicyByID}?module_type=${type}`,
    req.payload,
    {},
    true
  )();
}
function changePriority(req) {
  let type = req.payload.module_type;
  delete req.payload.module_type;
  return sagaFunctions(
    ChangePriority,
    "post",
    `${apis.changePolicyPriority}?module_type=${type}`,
    req.payload,
    {},
    true
  )();
}

function getPolicies(req) {
  return sagaFunctions(
    GetPolicies,
    "get",
    `${apis.getPolicies}`,
    req.payload,
    {},
    true
  )();
}

function getPoliciesMeta(req) {
  return sagaFunctions(
    PoliciesMetadata,
    "get",
    `${apis.getPolicyMetaDetails}`,
    req.payload,
    {},
    true
  )();
}

export function* masterdataWatcher() {
  yield takeEvery(SeedData.REQUEST, getSeedRecords);
  yield takeEvery(Currencies.REQUEST, getCurrencies);
  yield takeEvery(CreatePolicy.REQUEST, createPolicy);
  yield takeEvery(EnablePolicy.REQUEST, enablePolicy);
  yield takeEvery(DeletePolicy.REQUEST, deletePolicy);
  yield takeEvery(GetPolicy.REQUEST, getPolicy);
  yield takeEvery(GetPolicies.REQUEST, getPolicies);
  yield takeEvery(PoliciesMetadata.REQUEST, getPoliciesMeta);
  yield takeEvery(ChangePriority.REQUEST, changePriority);
}
