import { Checkbox, message } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { amountFormat } from "../../../utils";
import { FormInput, FormSelect } from "../../inputs";
import clock from "./assets/clock.png";
import { gridApis } from "../../../config/GridApis";
import usePayAxios from "../../../config/useAxios";
import { useSelector } from "react-redux";
import moment from "moment";

const Review = ({ next, mainData, prev, cAcc, setMainData }) => {
  const { onCall, loading } = usePayAxios({
    type: "grid",
    api: gridApis.createPaymentLink,
    method: "post",
  });

  const data1 = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mail_to_counterparty: false,
    },
  });
  const onSubmit = (data) => {
    onCall({
      data: { ...mainData, mail_to_counterparty: data.mail_to_counterparty },
    })
      .then((res) => {
        console.log({ res });
        if (res?.data?.url) {
          setMainData((b) => ({ ...b, link: res?.data?.url }));
          next();
        }
      })
      .catch((err) => {
        message.error(
          <span className="messageText">{err?.response?.data?.message}</span>
        );
      });
  };
  useEffect(() => {
    if (mainData?.ourparty_account_id) {
      setValue("ourparty_display_name", mainData?.ourparty_display_name);
      setValue("ourparty_account_id", mainData?.ourparty_account_id);
    }
  }, [mainData]);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Payment Request Review</h2>

        <div className="amt-details">
          <h3>$ {amountFormat(mainData?.amount)}</h3>
          <p>
            To {cAcc?.beneficiary_name}
            <span className="helper-text" style={{ marginTop: 0 }}>
              ({cAcc?.email})
            </span>
          </p>
        </div>
        <div className="due-on">
          Due on {moment(mainData?.due_date)?.format("MMMM DD, YYYY")}
        </div>
        <div className="form-body">
          <div className="pp-form-item">
            <FormInput
              inline
              {...register("ourparty_display_name", {})}
              label={"Name to show on the request"}
              control={control}
              hideCreateOption={true}
              placeholder={"Enter"}
              errors={errors}
              disabled={true}
            />
          </div>
          <div className="pp-form-item">
            <FormSelect
              inline
              {...register("ourparty_account_id")}
              label={"Destination Account"}
              disabled={true}
              control={control}
              options={data1?.map((ele) => ({
                label: `${ele?.account_name} - ${ele?.account_number}`,
                value: ele?.id,
              }))}
              hideCreateOption={true}
              placeholder={"Select"}
              errors={errors}
            />
            <p className="helper-text">
              Incoming payments are routed through a secure receiving account so
              your account details remain private.
            </p>
          </div>
          <div className="expires mt2">
            <img src={clock} width={15} />
            Payment link expires on{" "}
            {mainData?.due_date
              ? moment(mainData?.due_date)?.format("MMMM DD, YYYY")
              : ""}
          </div>

          <div className="pp-form-item p-method">
            <Checkbox />{" "}
            <span className="title">Send email to {cAcc?.email}</span>
          </div>
        </div>
        <div className="action-btns">
          <button type="button" className="back-btn" onClick={prev}>
            Back
          </button>
          <button className="send-btn" disabled={loading}>
            {loading ? "Processing..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Review;