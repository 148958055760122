export const debitData = [
  {
    id: "4b9b3f20-78ea-4e9a-9396-1f95a91df579",
    status: "Activated",
    type: "Virtual",
    card_reference_no: "MWC000000015",
    card_number: "8231 **** **** 0253",
    mobile_no: "9876543210",
    correspondence_mobile: "9876543210",
    employee_details: {
      employee_id: "PS003",
      name: "RAHUL SHARMA",
      email: "rahul.sharma@paycraftsol.com",
      mobile: "9876543210",
    },
    balance: "2450.50",
    expense_wallet: 100,
    reimburse_wallet: 300,
    company_name: "Tech Innovators Pvt Ltd",
    data: {
      firstName: "RAHUL",
      lastName: "SHARMA",
      accountNumber: "	40000004569",
      dateOfBirth: "1992-04-15",
      primaryMobileNumber: "9876543210",
      communicationMobileNumber: "9123456789",
      address: "Sector 5, Noida",
      cardStatus: "A",
      kycFlag: "COMPLETED",
      productCode: "1",
      cardActivatedOn: "01-03-2023 14:20:00",
      maskCardNumber: "8231 **** **** 0253",
      cardBalance: "2450.50",
      walletNameAndBalance: [
        { balance: "900.00", walletName: "TRANSIT" },
        { balance: "250.00", walletName: "EXPENSE" },
        { balance: "1200.50", walletName: "MEAL" },
      ],
      channelType: ["ATM", "POS", "ECOM"],
      emailId: "rahul.sharma@paycraftsol.com",
      pan: "AJTPS8765N",
      cardExpiry: "0325",
      last5Transactions: [
        {
          amount: "150.00",
          dateAndTime: "02-03-2023 09:15:25",
          crDr: "Cr",
          merchantName: "Metro Station",
        },
        {
          amount: "120.00",
          dateAndTime: "01-03-2023 18:30:10",
          crDr: "Dr",
          merchantName: "POS Purchase",
        },
      ],
      cardType: "Virtual Card",
      card_limits: [
        {
          channelType: "ATM",
          cardUsage: "dom",
          currentDailyLimit: 1500,
          maxDailyLimit: 20000,
        },
        {
          channelType: "POS",
          cardUsage: "dom",
          currentDailyLimit: 1000,
          maxDailyLimit: 50000,
        },
      ],
    },
    kyc_flag: "COMPLETED",
    card_activated_on: "01 Mar'23, 02:20:00 pm",
    wallets: [
      {
        id: "5c7f9a7b-9c74-4d1f-987d-1960be872c2b",
        wallet_name: "TRANSIT",
        balance: "900.00",
      },
      {
        id: "abe71245-6452-4318-99d5-fc239d5c826d",
        wallet_name: "EXPENSE",
        balance: "250.00",
      },
      {
        id: "f6ba6a7f-f4d1-4399-b193-6a7f4c8e50bc",
        wallet_name: "MEAL",
        balance: "1200.50",
      },
    ],
  },
  {
    id: "6fbfa9f8-65c5-4a76-840a-7ab85a532319",
    status: "Deactivated",
    type: "Physical",
    card_reference_no: "MWC000000011",
    card_number: "9184 **** **** 0345",
    mobile_no: "9834567890",
    correspondence_mobile: "9834567890",
    employee_details: {
      employee_id: "PS004",
      name: "ANKITA DESHMUKH",
      email: "ankita.deshmukh@paycraftsol.com",
      mobile: "9834567890",
    },
    balance: "1500.00",
    expense_wallet: 500,
    reimburse_wallet: 0,
    company_name: "FinTech Solutions",
    data: {
      firstName: "ANKITA",
      lastName: "DESHMUKH",
      accountNumber: "	40000004570",
      dateOfBirth: "1990-11-02",
      primaryMobileNumber: "9834567890",
      communicationMobileNumber: "9123456789",
      address: "Viman Nagar, Pune",
      cardStatus: "D",
      kycFlag: "IN_PROGRESS",
      productCode: "2",
      cardActivatedOn: "12-05-2022 11:00:00",
      maskCardNumber: "9184 **** **** 0345",
      cardBalance: "1500.00",
      walletNameAndBalance: [
        { balance: "500.00", walletName: "TRANSIT" },
        { balance: "1000.00", walletName: "MEAL" },
      ],
      channelType: ["ATM", "POS"],
      emailId: "ankita.deshmukh@paycraftsol.com",
      pan: "BMPLK8719C",
      cardExpiry: "1223",
      last5Transactions: [
        {
          amount: "200.00",
          dateAndTime: "03-02-2023 10:00:00",
          crDr: "Cr",
          merchantName: "POS Transaction",
        },
      ],
      cardType: "Physical Card",
      card_limits: [
        {
          channelType: "ATM",
          cardUsage: "dom",
          currentDailyLimit: 1000,
          maxDailyLimit: 15000,
        },
        {
          channelType: "POS",
          cardUsage: "dom",
          currentDailyLimit: 2000,
          maxDailyLimit: 25000,
        },
      ],
    },
    kyc_flag: "IN_PROGRESS",
    card_activated_on: "12 May'22, 11:00:00 am",
    wallets: [
      {
        id: "d2b9a7d4-84f5-4c76-9a6b-dcf45a9328f6",
        wallet_name: "TRANSIT",
        balance: "500.00",
      },
      {
        id: "b7e1f2ad-9eae-4c2d-9b4f-c9f1b84780db",
        wallet_name: "MEAL",
        balance: "1000.00",
      },
    ],
  },
  {
    id: "f38d9efb-5d73-4c2f-8c83-7dbf3f79f4be",
    status: "Activated",
    type: "Virtual",
    card_reference_no: "MWC000000018",
    card_number: "5467 **** **** 0798",
    mobile_no: "9765123489",
    correspondence_mobile: "9765123489",
    employee_details: {
      employee_id: "PS005",
      name: "AJAY KUMAR",
      email: "ajay.kumar@paycraftsol.com",
      mobile: "9765123489",
    },
    balance: "2750.75",
    expense_wallet: 50,
    reimburse_wallet: 150,
    company_name: "Blue Ocean Tech",
    data: {
      firstName: "AJAY",
      lastName: "KUMAR",
      accountNumber: "	40000004568",
      dateOfBirth: "1985-07-25",
      primaryMobileNumber: "9765123489",
      communicationMobileNumber: "9087564321",
      address: "Andheri, Mumbai",
      cardStatus: "A",
      kycFlag: "COMPLETED",
      productCode: "3",
      cardActivatedOn: "10-06-2023 09:30:00",
      maskCardNumber: "5467 **** **** 0798",
      cardBalance: "2750.75",
      walletNameAndBalance: [
        { balance: "1800.00", walletName: "TRANSIT" },
        { balance: "300.00", walletName: "FUEL" },
        { balance: "650.75", walletName: "MEAL" },
      ],
      channelType: ["ATM", "POS", "ECOM", "CTLS"],
      emailId: "ajay.kumar@paycraftsol.com",
      pan: "CWQPK8217L",
      cardExpiry: "0826",
      last5Transactions: [
        {
          amount: "350.00",
          dateAndTime: "15-06-2023 12:45:00",
          crDr: "Dr",
          merchantName: "Fuel Station",
        },
      ],
      cardType: "Virtual Card",
      card_limits: [
        {
          channelType: "ATM",
          cardUsage: "dom",
          currentDailyLimit: 3000,
          maxDailyLimit: 25000,
        },
        {
          channelType: "ECOM",
          cardUsage: "dom",
          currentDailyLimit: 5000,
          maxDailyLimit: 50000,
        },
      ],
    },
    kyc_flag: "COMPLETED",
    card_activated_on: "10 Jun'23, 09:30:00 am",
    wallets: [
      {
        id: "2c68f5f3-646a-490e-8ebd-1b02a9e5a3e4",
        wallet_name: "TRANSIT",
        balance: "1800.00",
      },
      {
        id: "b7e3e7f4-ff36-4a68-a984-41e2a9f72fd1",
        wallet_name: "FUEL",
        balance: "300.00",
      },
      {
        id: "1d3b6895-ea67-49a7-9f1d-e365752e0c52",
        wallet_name: "MEAL",
        balance: "650.75",
      },
    ],
  },
];
