import { Input, Switch } from "antd";
import React from "react";

const BalanceAlerts = () => {
  return (
    <div className="balance-alerts">
      <div className="sub-sec-head mb2">
        <h3>Balance Alerts</h3>
        <p>
          Set a custom threshold for each account and get an email alert when
          the balance falls below it.
        </p>
      </div>
      <div className="alert-row">
        <Switch />
        <div>
          <label>Ops / Payroll</label>
          <span className="sub">Send when balance falls below:</span>
          <Input placeholder="0" prefix="$" className="alert-input" />
        </div>
      </div>

      <div className="alert-row">
        <Switch />
        <div>
          <label>AP</label>
          <Input placeholder="0" prefix="$" className="alert-input" />
        </div>
      </div>

      <div className="alert-row">
        <Switch />
        <div>
          <label>AR</label>
          <Input placeholder="0" prefix="$" className="alert-input" />
        </div>
      </div>

      <div className="alert-row">
        <Switch />
        <div>
          <label>Checking ••0297</label>
          <Input placeholder="0" prefix="$" className="alert-input" />
        </div>
      </div>
    </div>
  );
};

export const AccountActivity = () => {
  return (
    <div className="balance-alerts">
      <div className="sub-sec-head mb2">
        <h3>Account activity</h3>
        <p>
          Monitor large transactions, troubleshoot payment issues, and more.
        </p>
      </div>
      {/* Alert Rows */}
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Incoming transaction exceeds a custom dollar amount</label>
          <span className="sub">
            Send when an incoming transaction exceeds:
          </span>
          <Input placeholder="0" prefix="$" className="alert-input" />
        </div>
      </div>

      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Outgoing transactions exceeds a custom dollar amount</label>
          <span className="sub">
            Send when an outgoing transaction exceeds:
          </span>
          <Input placeholder="100" prefix="$" className="alert-input" />
        </div>
      </div>

      {/* <div className="alert-row">
        <Switch />
        <label>Transaction needs my approval</label>
      </div> */}

      <div className="alert-row">
        <Switch />
        <label>Transaction fails</label>
      </div>

      {/* <div className="alert-row">
        <Switch />
        <label>Check payment hasn’t been cashed after 20 days</label>
      </div> */}

      <div className="alert-row">
        <Switch />
        <label>Auto transfer rule is created or modified</label>
      </div>

      {/* <div className="alert-row">
        <Switch defaultChecked />
        <label>Bill Pay invoice has been received and is being processed</label>
      </div>

      <div className="alert-row">
        <Switch />
        <label>
          Bill Pay invoice has finished processing and is ready for review
        </label>
      </div> */}

      {/* <div className="alert-row">
        <Switch />
        <label>2FA reset requests from my team members</label>
      </div> */}
      <div className="sub-sec-head mb2 mt1">
        <h3>Subscription activity</h3>
      </div>
      {/* <div className="alert-row">
        <Switch />
        <label>Upcoming subscription renewal</label>
      </div> */}

      <div className="alert-row">
        <Switch />
        <div>
          <label>Insufficient balance reminders</label>
          <span className="sub">
            We will always alert you of any potential disruptions to your
            subscription
          </span>
        </div>
      </div>
    </div>
  );
};

export const CreditActivity = () => {
  return (
    <div className="balance-alerts">
      <div className="sub-sec-head mb2">
        <h3>Credit</h3>
        <p>
          Track limit changes and make sure IO payments are processing on time.
        </p>
      </div>
      {/* Alert Rows */}
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Credit balance exceeds a custom percentage</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Credit limit increases or decreases</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Early payment warning</label>
          <span className="sub">
            Sent 3 days before your next scheduled IO balance payment.
          </span>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Payment processed due to low available limit</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Backup account was used to process payment</label>
        </div>
      </div>
    </div>
  );
};

export const CardActivity = () => {
  return (
    <div className="balance-alerts">
      <div className="sub-sec-head mb2">
        <h3>My Cards</h3>
        {/* <p>
          Track limit changes and make sure IO payments are processing on time.
        </p> */}
      </div>
      {/* Alert Rows */}
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Card transaction is declined</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Card is used outside of the United States</label>
        </div>
      </div>
      <div className="alert-row">
        <Switch defaultChecked />
        <div>
          <label>Card is used for an online purchase</label>
        </div>
      </div>
    </div>
  );
};

export default BalanceAlerts;
