import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import SignUp from "../OnBoarding/Auth/SignUp";
import "./auth.css";
import ConfirmEmail from "./ConfirmEmail";
import ForgotPassword from "./ForgotPassword";
import LoginForm from "./LoginForm";
import RegisterForDemo from "./RegisterForDemo";
import ResetPassword from "./ResetPassword";
import TwoStepVerify from "./TwoStepVerification";

const Login = () => {
  const history = useHistory();
  const location = useLocation();

  const [displayLoginRight, setDisplayLoginRight] = useState(true);
  const signPage = history?.location?.pathname.includes("sign-up");
  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      !location.pathname.includes("reset-password") &&
      !location.pathname.includes("confirm_email")
    ) {
      history.push("/dashboard");
      localStorage.setItem("activeMenu", "/dashboard");
      localStorage.setItem("activeSubMenu", "");
      localStorage.setItem("showChildren", false);
      localStorage.setItem("childrenMenu", false);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="login-page">
      <div className="login-container">
        {displayLoginRight ? (
          <div className="w50 login-right">
            <div className="login-right-content">
              <div className="right_content">
                <BusinessCard />
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={`${displayLoginRight ? "w50" : "w100 h100vh"} ${
            signPage ? "background-color" : null
          } login-left p-relative`}
        >
          <div className="login-right-section">
            <div className="login-right-container">
              <Switch>
                <Route exact path="/">
                  <LoginForm />
                </Route>
                <Route path="/reset-password">
                  <ForgotPassword />
                </Route>
                <Route exact path="/two-step-verify">
                  <TwoStepVerify />
                </Route>
                <Route path="/set-password">
                  <ForgotPassword />
                </Route>
                <Route path="/confirm_email">
                  <ConfirmEmail />
                </Route>
                <Route exact path="/forgot-password/">
                  <ResetPassword />
                </Route>
                <Route exact path="/request-demo">
                  <RegisterForDemo />
                </Route>
                <Route exact path="/sign-up">
                  <SignUp setDisplayLoginRight={setDisplayLoginRight} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BusinessCard = () => {
  // State to manage active step
  const [activeStep, setActiveStep] = useState(0);

  // Step contents
  const stepsContent = {
    0: {
      title: "Zenus Bank US Business Bank Account",
      description: `Our business banking accounts are designed for small or single director businesses that need an international edge.`,
    },
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setActiveStep((b) => (b + 1) % 4);
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <>
      {/* <div style={{ marginBottom: "10px" }}>
        {[0, 1, 2, 3].map((dot) => (
          <label
            key={dot}
            style={{
              height: "8px",
              width: "8px",
              backgroundColor: activeStep === dot ? "#fff" : "#ccc",
              borderRadius: "50%",
              display: "inline-block",
              margin: "0 4px",
              cursor: "pointer",
            }}
            onClick={() => setActiveStep(dot)}
          />
        ))}
      </div> */}

      {/* Title */}
      <p className="title" style={{ color: "#fff" }}>
        {stepsContent[activeStep].title}
      </p>

      {/* Description */}
      <p className="sub-text" style={{ color: "#fff", fontSize: 18 }}>
        {stepsContent[activeStep].description}
      </p>
    </>
  );
};
export default Login;