import {
  EyeInvisibleFilled,
  MessageFilled,
  EyeFilled,
} from "@ant-design/icons";
import { Col, Divider, Dropdown, Menu, Modal, Row, Timeline } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { cardEnalbeLimit } from "../../actions/cards/cards";
import Close from "../../assets/icons/close.png";
import copy from "../../assets/icons/copy.png";
import rupay from "../../assets/images/visa.png";
import down from "../../assets/icons/down.png";
import zCard from "../../assets/icons/z_card.png";
import managed from "../../assets/images/manage.png";
import saved from "../../assets/images/saved.png";
import upgrade from "../../assets/images/upgrade.png";
import vf from "../../assets/images/vf.png";
import { currencyMapper } from "../../config/helper";
import { DebitCard } from "../Cards/AddCard";
import CardReplacementForm from "../Cards/BlockCardForm";
import "../Cards/card.css";
import cardDetail from "../Cards/card_detail.json";
import CardSettings from "../Cards/CardSettings";
import CardTxns from "../Cards/CardTxns";
import WalletBalances from "../Cards/WalletComponent";
import { amountFormat } from "../../utils";
import NodataComponent from "./NodataComponent";
import LineChart from "./LineAreaForCard";
import CreditCard from "../Cards/CreditCardTop";
import { get_person_accounts } from "../../actions/z_accounts";
import { useDispatch } from "react-redux";
import { LOGO } from "../../config/Images";
import { useLocation } from "react-router-dom";
const menuItems = [
  {
    key: "1",
    icon: (
      <img
        src={saved}
        width={15}
        style={{
          marginBottom: 10,
        }}
      />
    ),
    label: "Manage Saved Card Details",
    description: "Manage apps where your card is saved",
  },
  {
    key: "2",
    icon: (
      <img
        src={managed}
        width={15}
        style={{
          marginBottom: 10,
        }}
      />
    ),
    label: "Manage Autopay",
    description: "View or edit any Auto-pay set on your card",
  },
  {
    key: "3",
    icon: (
      <img
        src={upgrade}
        width={15}
        style={{
          marginBottom: 10,
        }}
      />
    ),
    label: "Upgrade Card",
    description: "Upgrade your card for more features",
  },
  {
    key: "4",
    icon: (
      <img
        src={vf}
        width={15}
        style={{
          marginBottom: 10,
        }}
      />
    ),
    label: "View Features",
    description: "View current features of your card",
  },
];

const CardDetails = ({ hasWriteAccess, debit: db, credit }) => {
  const history = useHistory();
  const location = useLocation();

  const [tab, setTab] = useState("details");
  const [editRow, setEditRow] = useState({});
  const [kycStatus] = useState();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState({});
  const [eyeCheck, setEyeCheck] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [freezeModal, setFreezeModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const dispatch = useDispatch();
  const cardData = cardDetail;
  const debit = db || location?.state?.debit;

  const card_data = "";

  const secs = debit
    ? [
        { label: t("details"), key: "details" },
        { label: t("Card Limits & Settings"), key: "settings" },
        { label: t("Blocked Cards"), key: "blocked" },
        { label: t("Linked Cards"), key: "linked" },
        { label: t("Logs"), key: "events" },
      ]
    : [
        { label: t("details"), key: "details" },
        // { label: t("Wallets"), key: "wallets" },
        { label: t("Card Limits & Settings"), key: "settings" },
        { label: t("transactions"), key: "transactions" },
        { label: t("Events"), key: "events" },
      ];

  const channels = cardData?.data?.card_limits?.map((row) => row.channelType);
  const card_id = card_data?.id;
  const [txnEnable, setTxnEnable] = useState({
    ECOM: true,
    ATM: true,
    POS: true,
    CTLS: false,
    IATM: false,
    CBT: false,
  });

  const spendLimit = 22000;
  const availableBalance = 10000;
  const lastCharged = "24 Sep 2024";
  // Calculate the percentage for the progress bar
  const progressPercentage = (availableBalance / spendLimit) * 100;
  const cardDetails = [
    [
      {
        label: "Card Holder Name",
        value: "Girish Puvvada",
        span: 7,
        copy: true,
      },
      { label: "Mobile Number", value: "+9 9400394990", span: 5, copy: true },
      {
        label: "Card Type",
        value: `Physical - ${credit ? "Credit" : "Debit"} Card`,
        span: 5,
      },
      {
        label: "Network",
        value: <img src={rupay} height={20} />,
        span: 4,
      },
    ],
    [
      {
        label: "Card Number",
        value: "8174 •••• •••• ••••",
        fullValue: "8174 1232 4032 0177",
        span: 7,
        eye: true,
        copy: true,
      },
      { label: "KYC Status", value: "Full KYC", span: 5 },
      {
        label: "CVC",
        value: "•••",
        span: 5,
        eye: true,
        copy: true,
        fullValue: "817",
      },
      {
        label: "Issuer Bank",
        value: <img src={LOGO} height={20} />,
        span: 4,
      },
    ],
    [
      { label: "Address", value: "789 Innovation Blvd, Suite 20...", span: 7 },
      { label: "Location", value: "-", span: 5 },
      debit
        ? { label: "Account Linked", value: "Saving - xxxx 90958", span: 5 }
        : {},
    ],
  ];

  const menu = (
    <Menu>
      {menuItems.map((item) => (
        <Menu.Item key={item.key} icon={item.icon}>
          <div>
            <p style={{ margin: 0 }}>{item.label}</p>
            <p style={{ fontSize: "12px", color: "#888" }}>
              {item.description}
            </p>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
  }, []);

  return (
    <>
      <Modal
        open={freezeModal}
        title="Alert"
        footer={[]}
        onCancel={() => {
          setFreezeModal(false);
        }}
      >
        <div className="freeze-popup">
          <p className="title">Are you sure you want to freeze this card?</p>
          <div className="d-flex align-center gap1 mb2">
            <img src={zCard} width={100} />
            <div>
              <p className="name">Girish Puvvada</p>
              <p className="dim">Physical card •• 5546</p>
            </div>
          </div>
          <div className="action-btns">
            <button
              className="back-btn"
              onClick={() => {
                setFreezeModal(false);
              }}
            >
              Cancel
            </button>
            <button className="send-btn">Freeze Card</button>
          </div>
        </div>
      </Modal>
      <Modal
        open={blockModal}
        title="Block Card"
        footer={[]}
        onCancel={() => {
          setBlockModal(false);
        }}
        className="right-alinged-modal"
      >
        <CardReplacementForm />
      </Modal>
      <div className="debit-card-details">
        <div className="new-report-detail">
          <div className="flex-between">
            <div className="report-head w100">
              <div className="title-sec">
                <div className="d-flex w100 flex-between">
                  <div className="d-flex" style={{ gap: "1.5rem" }}>
                    <h3>{cardData.employee_details?.name}</h3>
                    {
                      <span
                        className={`status status_${cardData.status?.toLowerCase()} v-center`}
                      >
                        {cardData.status}
                      </span>
                    }

                    {/* <span className={`card-nm-btn v-center`}>
                      {cardData.kyc_flag ? cardData.kyc_flag : ""}
                    </span> */}
                  </div>
                  <div className="d-flex" style={{ gap: "1rem" }}>
                    {cardData?.status !== "Activated" ? (
                      <button
                        disabled={!hasWriteAccess}
                        className="card-activate-button"
                      >
                        {t("Activate")}
                      </button>
                    ) : null}
                    <button
                      disabled={!hasWriteAccess}
                      className="card-secondary-button"
                    >
                      {t("Reset Pin")}
                    </button>
                    <Dropdown overlay={menu} trigger={["click"]} arrow>
                      <button
                        disabled={!hasWriteAccess}
                        className="card-secondary-button"
                      >
                        {t("More")}
                        <img src={down} width={12} className="mlHalf" />
                      </button>
                    </Dropdown>
                    <span
                      className="cursor"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <img src={Close} width={20} alt={"close"} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mtHalf">
                <span
                  style={{
                    color: "#2B2B2B",
                    fontSize: "1rem",
                    fontWeight: "normal",
                  }}
                >
                  {cardData.employee_details?.email}{" "}
                  {cardData.employee_details?.email && "|"}{" "}
                  {cardData.employee_details?.employee_id}
                </span>
              </div>
            </div>
          </div>
          <div className="tabs-sec">
            <ul>
              {secs.map(({ label, key }) => (
                <li
                  onClick={() => setTab(key.toLowerCase())}
                  className={`${
                    tab.toLowerCase() === key.toLowerCase() ? "active" : ""
                  }`}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
          {(() => {
            switch (tab) {
              case "details":
                return (
                  <>
                    <section className="card-info p1 card-info-flex-start">
                      <div style={{ width: "100%" }}>
                        <div className="d-flex">
                          <div>
                            <DebitCard cardData={cardData} />
                            <div className="flex-center gap1 fb-btns">
                              <button
                                disabled={!hasWriteAccess}
                                className="card-secondary-button"
                                onClick={() => {
                                  setBlockModal(true);
                                }}
                              >
                                {t("card_block")}
                              </button>
                              <button
                                disabled={!hasWriteAccess}
                                className="card-secondary-button"
                                onClick={() => {
                                  setFreezeModal(true);
                                }}
                              >
                                {t("Freeze Card")}
                              </button>
                            </div>
                          </div>
                          <div className="w100">
                            <div className="card-balance-container">
                              {credit && (
                                <>
                                  <section className="d-flex gap1 mb2">
                                    <CreditCard />
                                    <LineChart />
                                  </section>
                                  <Divider />
                                </>
                              )}
                              {credit && (
                                <>
                                  <div className="spend-limit-wrap">
                                    <div className="spend-limit">
                                      <span>Credit limit</span>
                                      <span className="amount">
                                        {currencyMapper["INR"]}
                                        {amountFormat(350000)}
                                        <small className="ml1">per month</small>
                                      </span>
                                    </div>
                                    <div className="spend-limit">
                                      <span>Available Credit limit</span>
                                      <span className="amount">
                                        {currencyMapper["INR"]}
                                        {amountFormat(212500)}
                                      </span>
                                    </div>
                                  </div>
                                  <Divider />
                                  <div className="spend-limit-wrap">
                                    <div className="spend-limit">
                                      <span>Cash limit</span>
                                      <span className="amount">
                                        {currencyMapper["INR"]}
                                        {amountFormat(35000)}
                                        <small className="ml1">per month</small>
                                      </span>
                                    </div>
                                    <div className="spend-limit">
                                      <span>Due Amount</span>
                                      <span className="amount">
                                        {currencyMapper["INR"]}
                                        {amountFormat(86435)}
                                      </span>
                                    </div>
                                  </div>
                                  <Divider />
                                </>
                              )}
                              {/* Spend Limit */}
                              <div
                                className="other-info"
                                style={{ width: "100%" }}
                              >
                                {cardDetails.map((item, index) => (
                                  <Row
                                    className="flex-wrap"
                                    key={index}
                                    style={{ marginBottom: "2rem" }}
                                    gutter={[16]}
                                  >
                                    {/* Loop through every 4 items */}
                                    {item.map((detail, i) => (
                                      <Col span={detail.span} key={i}>
                                        <p>
                                          <label>{detail.label}</label>
                                        </p>
                                        <p>
                                          {eyeCheck[detail.label]
                                            ? detail.fullValue
                                            : detail.value}{" "}
                                          {detail.eye ? (
                                            <>
                                              {eyeCheck[detail.label] ? (
                                                <EyeFilled
                                                  style={{
                                                    marginLeft: 10,
                                                    marginBottom: 2,
                                                  }}
                                                  onClick={() => {
                                                    setEyeCheck({
                                                      [detail.label]: false,
                                                    });
                                                  }}
                                                />
                                              ) : (
                                                <EyeInvisibleFilled
                                                  style={{
                                                    marginLeft: 10,
                                                    marginBottom: 2,
                                                  }}
                                                  onClick={() => {
                                                    setEyeCheck({
                                                      [detail.label]: true,
                                                    });
                                                  }}
                                                />
                                              )}
                                            </>
                                          ) : null}
                                          {detail.copy ? (
                                            <>
                                              <img
                                                style={{
                                                  marginLeft: 10,
                                                  marginBottom: 2,
                                                }}
                                                src={copy}
                                                width={13}
                                              />
                                            </>
                                          ) : null}
                                        </p>
                                      </Col>
                                    ))}
                                  </Row>
                                ))}
                              </div>

                              {debit && (
                                <div className="spend-limit">
                                  <span>Combined limit on Card</span>
                                  <span className="amount">
                                    {currencyMapper["INR"]}
                                    {amountFormat(200000)}{" "}
                                    <small>per month</small>
                                  </span>
                                  <label
                                    className="link"
                                    onClick={() => {
                                      setTab("settings");
                                    }}
                                  >
                                    View Limits
                                  </label>
                                </div>
                              )}
                              <Divider />
                              {!credit && !debit && (
                                <>
                                  <div className="spend-limit">
                                    <span>Spend limit</span>
                                    <span className="amount">
                                      {currencyMapper["INR"]}
                                      {spendLimit.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                      })}{" "}
                                      <small>per month</small>
                                    </span>
                                  </div>
                                  <Divider />
                                  <div className="spend-limit">
                                    <span>Balance</span>
                                    <div>
                                      <div className="balance-details mb1">
                                        <span>
                                          <span className="label">
                                            Available:
                                          </span>{" "}
                                          {currencyMapper["INR"]}
                                          {availableBalance.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                          )}
                                        </span>
                                        <span className="ml2">
                                          <span className="label">Limit:</span>
                                          {currencyMapper["INR"]}
                                          {spendLimit.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </span>
                                      </div>

                                      {/* Progress Bar */}
                                      <div className="progress-bar">
                                        <div
                                          className="filled-bar"
                                          style={{
                                            width: `${progressPercentage}%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <Divider />
                                </>
                              )}
                              {/* Last Charged */}

                              <div className="last-charged">
                                {credit ? (
                                  <span>Bill Due On</span>
                                ) : (
                                  <span>Last charged</span>
                                )}
                                <span className="last-charged-date">
                                  {lastCharged}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                );
              case "settings":
                return (
                  <>
                    <CardSettings
                      hasWriteAccess={hasWriteAccess}
                      cardData={cardData}
                      setSelectedRow={setSelectedRow}
                      setEditRow={setEditRow}
                      card_data={card_data}
                      cardEnalbeLimit={cardEnalbeLimit}
                      setIsModalVisible={setIsModalVisible}
                      card_id={card_id}
                      txnEnable={txnEnable}
                      setTxnEnable={setTxnEnable}
                      editRow={editRow}
                    />
                  </>
                );
              case "wallets":
                return (
                  <>
                    <WalletBalances />
                  </>
                );
              case "transactions":
                return (
                  <>
                    <CardTxns
                      cardData={cardData}
                      hasWriteAccess={hasWriteAccess}
                    />
                  </>
                );
              case "events":
                return (
                  <div className="p2 events">
                    <CardStatusTimeline />
                  </div>
                );
              case "blocked":
                return (
                  <div className="p2 events">
                    <NodataComponent noMsg title={"No Blocked Cards"} noBtns />
                  </div>
                );
              case "linked":
                return (
                  <div className="p2 events">
                    <NodataComponent noMsg title={"No Linked Cards"} noBtns />
                  </div>
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </>
  );
};

const CardStatusTimeline = () => {
  const cardEvents = [
    { event: "Created", timestamp: "9 Sep 24, 9:48pm" },
    { event: "Card dispatched", timestamp: "10 Sep 24, 11:15am" },
    { event: "Card delivered", timestamp: "11 Sep 24, 2:30pm" },
    { event: "Card activate", timestamp: "11 Sep 24, 5:00pm" },
    { event: "Card freeze", timestamp: "12 Sep 24, 8:20am" },
    { event: "Card unfreeze", timestamp: "12 Sep 24, 3:10pm" },
    { event: "Card freeze", timestamp: "13 Sep 24, 9:30am" },
    { event: "Card unfreeze", timestamp: "13 Sep 24, 1:45pm" },
  ];

  return (
    <Timeline mode="left" className="tm-line">
      {cardEvents.map((item, index) => (
        <Timeline.Item
          key={index}
          dot={<MessageFilled style={{ fontSize: "13px" }} />}
        >
          <label>{item.event}</label>
          <span>{item.timestamp}</span>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default CardDetails;
