import { Button, Checkbox, Col, message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  FetchBillingDetailsById,
  fetchBillingDetailsById,
} from "../../../actions/bills";
import { categories } from "../../../actions/categories";
import {
  CreateInvoice,
  SubmitInvoice,
  create_invoice,
  customers as customers_action,
  invoices,
  submit_invoice,
} from "../../../actions/customers/customers";
import {
  SideMenu,
  get_seed_data,
  headerTitles,
} from "../../../actions/masterdata/masterdata";
import Close from "../../../assets/icons/close.png";
import { apis } from "../../../config/APIs";
import endpointPayApi from "../../../config/AxiosPayApi";
import endpointSettingsApi from "../../../config/AxiosSettingsApi";
import usePayAxios from "../../../config/useAxios";
import useForgoCardsAxios from "../../../config/useForgoCardsAxios";
import useScrollToError from "../../../config/useScrollToError";
import { convertCurrency, showFile, uploadFile } from "../../../utils";
import AddCategory from "../../Common/AddCategory";
import AddPayTermsModal from "../../Common/AddPayTerms";
import AddTaxModal from "../../Common/AddTax";
import { inv_type } from "../../Purchases/AddVendorOtherInformation";
import CustomField from "../../Purchases/Bills/CustomFields";
import ItemsTable from "../../Purchases/Bills/ItemsTable";
import { FormInput, FormSelect } from "../../inputs";
import DateInput from "../../inputs/DateInput";
import UploadField from "../../inputs/UploadField";
import AddCustomer from "../AddCustomer";
import useDocumentAxios from "../../../config/useDocument";

export const reasons = [
  { label: "Sales Return", value: "Sales Return" },
  { label: "Post Sale Discount", value: "Post Sale Discount" },
  { label: "Deficiency in service", value: "Deficiency in service" },
  { label: "Correction in Invoice", value: "Correction in Invoice" },
  { label: "Change in POS", value: "Change in POS" },
  {
    label: "Finalization of Provisional assessment",
    value: "Finalization of Provisional assessment",
  },
  { label: "Others", value: "Others" },
];

const NewInvoice = ({ isEstimates, isSales, isCN }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const customers = useSelector((state) =>
    Array.isArray(state.customers?.all?.data?.data)
      ? state.customers?.all?.data?.data?.map((ele) => ({
          ...ele,
          label: ele.company_name,
          value: ele.id,
        }))
      : []
  );
  const payment_terms = useSelector((state) =>
    state?.seed_data?.payment_terms?.data?.length
      ? state?.seed_data?.payment_terms?.data?.map((ele) => ({
          label: ele.term_name,
          value: parseInt(ele.id),
          number_of_days: ele.number_of_days,
        }))
      : []
  );
  const create_inv = useSelector((state) => state.customers?.create_invoice);
  const submit_inv = useSelector((state) => state.customers?.submit_invoice);
  const [isVisible, setIsVisible] = useState(false);
  const [listFiles, setListFiles] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [removedItemsData, setRemovedItemsData] = useState([]);
  const [isAddTax, setIsAddTax] = useState(false);
  const [exchange, setExchange] = useState({});
  const [hasExchange, setHasExchange] = useState(false);
  const [noExError, setNoExError] = useState("");
  const [cmp_cur_id, setCmpCurId] = useState("");
  const [isAddCategory, setIsAddCategory] = useState(false);
  const { t } = useTranslation();
  const [openAddNew, setOpenAddNew] = useState(false);
  const history = useHistory();
  const categoriesResponse = useSelector(
    (state) => state?.categories?.categories?.data?.data
  );
  const placesSupplies = useSelector((state) =>
    state?.seed_data?.place_of_supply?.data?.length
      ? state?.seed_data?.place_of_supply?.data?.map((ele) => ({
          label: ele.seed_value,
          value: ele.seed_value,
        }))
      : []
  );

  const dataBeneficiary = useSelector(
    (state) => state.bills?.fetchBillingDetailsById?.data?.data || {}
  );
  const location = useLocation();
  const { data, onCall } = usePayAxios({
    api: apis.hierarchyOptions,
    method: "get",
  });
  const { data: prefix, onCall: getPrefix } = usePayAxios({
    api: apis.createPrefixCustomer,
    method: "post",
  });
  const invoices_data = useSelector((state) =>
    Array.isArray(state.customers?.invoices?.data?.data)
      ? state.customers?.invoices?.data?.data?.map((ele) => ({
          label: ele.invoice_no,
          value: ele.id,
        }))
      : []
  );
  const { onCall: customFields, data: custom_fields_values } =
    useForgoCardsAxios({
      api: apis.customFieldValues,
      method: "get",
      payApi: "settings",
    });
  const { onCall: inv_series_check } = usePayAxios({
    api: apis.invSeriesCheck,
    method: "post",
  });
  const [loadingRef, setLoadingRef] = useState(false);
  const { onCall: DocumentDelete } = useDocumentAxios({
    api: apis?.deleteDocument,
    method: "delete",
    baseURL: "document",
  });
  const initialValues = {
    beneficiary_id: "",
    customer_name: "",
    partial_payments: false,
    invoice_date: moment(),
    date: moment(),
    invoice_no: "",
    ref_po_no: "",
    po_due_date: null,
    total_amount: 0,
    total_tax_amount: 0,
    total_discount: 0,
    total_payable_amount: 0,
    delivery_charges: 0,
    payment_terms: "",
    gl_category: "",
    gl_code: "",
    transaction_ref_details: "44ba9c53-0686-4045-86a1-e4737c465b22",
    customer_invoice_items: [],
    customFields: [],
  };
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, submitCount },
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
    shouldUseNativeValidation: false,
  });

  // Use the custom hook
  useScrollToError(Object.keys(errors)[0], errors, submitCount);
  const beneficiary_id = watch("beneficiary_id");
  const searchRef = useRef();

  const onSubmit = (data, event) => {
    if (!event.nativeEvent?.submitter?.id) {
      return;
    }
    if (event.nativeEvent?.submitter?.id === "save") {
      setIsSubmit(false);
    } else {
      setIsSubmit(true);
    }
    if (data.invoice_date instanceof Date)
      data.invoice_date = data.invoice_date?.toISOString();
    if (data.po_due_date instanceof Date)
      data.po_due_date = data.po_due_date?.toISOString();
    data.total_amount = parseFloat(data.total_amount);
    data.total_discount = parseFloat(data?.total_discount);
    data.customer_name = customers?.find(
      (ele) => ele.value === data.beneficiary_id
    )?.beneficiary_name;
    data.total_tax_amount = parseFloat(data.total_tax_amount);
    data.exchange_rate = exchange?.live_exchange_rates?.rate
      ? exchange?.live_exchange_rates?.rate
      : exchange?.manual_exchange_rates?.rate;
    data.currency_datetime = exchange?.live_exchange_rates?.date
      ? exchange?.live_exchange_rates?.date
      : exchange?.manual_exchange_rates?.date;
    data.bc_total_amount = convertCurrency(data.total_amount, exchange);
    data.bc_total_tax_amount = convertCurrency(data.total_tax_amount, exchange);
    data.bc_total_discount = convertCurrency(data.total_discount, exchange);
    data.company_currency_id = cmp_cur_id;
    data.company_id = localStorage.getItem("company_id");
    if (exchange?.convert_currency) data.currency = exchange?.convert_currency;
    delete data.items;
    if (
      !location.state?.isClone &&
      !location.state?.convert &&
      dataBeneficiary?.id
    ) {
      data.isEdit = true;
      data.id = dataBeneficiary?.id;
    }

    if (location.state?.convert && dataBeneficiary?.id) {
      data.convert_id = dataBeneficiary?.id;
      data.from_module = location?.state?.from_module;
    }
    data.payment_terms =
      data.payment_terms === "custom" ? 0 : data.payment_terms;

    delete data.tds_amount;
    data.module_type = "INVOICE";
    if (data.module_type !== "CREDIT") {
      data.customer_invoice_items.forEach((ele) => {
        ele.bc_total_amount = convertCurrency(ele.total_amount, exchange);
        ele.bc_tax_amount = convertCurrency(ele.tax_amount, exchange);
        ele.bc_unit_price = convertCurrency(ele.unit_price, exchange);
        ele.bc_discount_amount = convertCurrency(ele.discount_amount, exchange);
      });
      data.customer_invoice_items = [
        ...data.customer_invoice_items,
        ...removedItemsData,
      ];
    }
    if (isEstimates) {
      data.module_type = "ESTIMATE";
      data.gl_category = "-";
      data.gl_code = "-";
      delete data.payment_terms;
    } else if (isSales) {
      data.module_type = "SALES_ORDER";
      data.gl_category = "-";
      data.gl_code = "-";
    } else if (isCN) {
      data.module_type = "CREDIT";
      delete data.gl_category;
      delete data.gl_code;
      delete data.total_amount;
      data.reference_number = data.ref_po_no;
      data.beneficiary_name = data.customer_name;
      data.line_items = data.customer_invoice_items;
      data.line_items.forEach((ele) => {
        ele.bc_total_amount = convertCurrency(ele.total_amount, exchange);
        ele.bc_tax_amount = convertCurrency(ele.tax_amount, exchange);
        ele.bc_unit_price = convertCurrency(ele.unit_price, exchange);
        ele.bc_discount_amount = convertCurrency(ele.discount_amount, exchange);
      });
      data.line_items = [...data.line_items, ...removedItemsData];
      data.date = data.invoice_date;
      data.credit_debit_note_number = data.invoice_no;
      data.terms_and_conditions = "no terms_and_conditions";
      data.subject_content = "-";
      delete data.customFields;
      delete data.ref_po_no;
      delete data.customer_name;
      delete data.customer_invoice_items;
      delete data.place_of_supply;
      delete data.invoice_date;
      delete data.invoice_no;
    }
    if (data.module_type !== "CREDIT") {
      delete data.sub_total_amount;
      delete data.date;
      delete data.discount_amount;
    }
    let series_check_data = {
      invoice_no: data.invoice_no,
    };
    if (
      !location.state?.convert &&
      !location.state?.isClone &&
      dataBeneficiary?.id
    ) {
      series_check_data.invoice_id = dataBeneficiary.id;
    }
    if (isEstimates || isSales || isCN) {
      delete data.partial_payments;
    }
    if (isCN) {
      dispatch(create_invoice(data));
    } else
      inv_series_check({
        data: series_check_data,
        params: {
          module_type: data.module_type,
        },
      }).then((res) => {
        if (res.data) {
          message.error(
            <span className="messageText">
              {isSales
                ? t("order_salee")
                : isEstimates
                ? t("estimate_hash")
                : t("invoice_hashh")}{" "}
              {t("already_present")}
            </span>
          );
        } else {
          dispatch(create_invoice(data));
        }
      });
  };

  const invoiceResponse = useSelector(
    (state) => state.customers.create_invoice || {}
  );
  const resetAct = () => {
    dispatch({
      type: FetchBillingDetailsById.RESET,
    });
    reset(initialValues);
    dispatch({
      type: SubmitInvoice.RESET,
    });
    dispatch({
      type: CreateInvoice.RESET,
    });
    setNoExError("");
  };
  const fetchInvDetails = () => {
    if (location?.state?.dataBeneficiary?.id) {
      let module_type = "INVOICE";
      if (isEstimates) {
        module_type = "ESTIMATE";
      } else if (isSales) {
        module_type = "SALES_ORDER";
      } else if (isCN) {
        module_type = "CREDIT";
      }

      dispatch(
        fetchBillingDetailsById({
          invoice_id: location?.state?.dataBeneficiary?.id,
          isInvoice: true,
          module_type: location?.state?.convert
            ? location?.state?.from_module
            : module_type,
        })
      );
    }
  };
  console.log(dataBeneficiary, getValues(), location.state);
  useEffect(() => {
    if (prefix?.data && !isCN) setValue("invoice_no", prefix?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefix]);

  useEffect(() => {
    watch("invoice_date");
    watch("invoice_no");
    watch("payment_terms");
    watch("po_due_date");

    let moduleName = "INVOICE_MODULE",
      module_type = "INVOICE";
    let type = "invoices";
    if (isEstimates) {
      moduleName = "ESTIMATE";
      module_type = "ESTIMATE";
      type = "estimates";
    } else if (isSales) {
      type = "sales_order";
      moduleName = "SALES_ORDER_MODULE";
      module_type = "SALES_ORDER";
    }

    customFields({
      params: {
        module_id: location.state?.id,
        module_type: module_type,
        pagination: false,
        custom_flag: true,
      },
    });
    // dispatch(expenseFields({module_type : module_type}));
    if (!location.state?.isEdit || location?.state?.convert)
      getPrefix({
        params: {
          module_type,
        },
      });
    dispatch(categories());
    dispatch(
      customers_action({
        company_id: localStorage.getItem("company_id"),
        type: "customer",
        status: "active",
        isName: true,
      })
    );
    if (location.state.id) {
      const showData = {
        record_type: "invoice",
        record_id: location.state.id,
      };

      try {
        showFile(showData)?.then((dt) => {
          setListFiles(
            Array.isArray(dt.data)
              ? dt?.data?.map((ele, i) => ({
                  uid: ele?.id,
                  name: ele?.filename,
                  url: ele?.link,
                  status: "done",
                  thumbUrl: ele?.link,
                  isEdit: true,
                }))
              : []
          );
        });
      } catch (err) {
        console.error(err.message);
      }
    }

    fetchInvDetails();
    dispatch(
      headerTitles({
        title: isCN
          ? "credit_notes"
          : isSales
          ? "Sales"
          : isEstimates
          ? "Estimate"
          : "Invoices",
        description: isSales
          ? "sale_order_document"
          : isEstimates
          ? "an_estimate"
          : "a_invoice",
      })
    );
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "place_of_supply",
      })
    );
    dispatch(
      get_seed_data({
        company_id: localStorage.getItem("company_id"),
        seed_type: "payment_terms",
      })
    );
    let seedType = "";
    if (isSales) {
      seedType = "sales_order_hierarchy";
    } else if (isEstimates) {
      seedType = "estimates_hierarchy";
    } else {
      seedType = "invoice_hierarchy";
    }
    if (!isCN)
      onCall({
        params: {
          seed_type: seedType,
        },
      });
    return () => {
      resetAct();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCN && beneficiary_id) {
      dispatch(
        invoices({
          type: "INVOICE",
          filters: {
            beneficiary_id: [beneficiary_id],
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiary_id]);

  useEffect(() => {
    if (location?.state?.setSideMenu) {
      let locationSplit = location?.pathname?.split("/");
      setValue("beneficiary_id", location?.state?.beneficiary_id);
      setValue("customer_name", location?.state?.beneficiary_name);
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: isEstimates || isSales ? "/" + locationSplit?.[2] : "",
          showChildren: isEstimates || isSales ? true : false,
          childrenMenu: isEstimates
            ? "/my-estimates"
            : isSales
            ? "/my-sales"
            : "/invoices",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  useEffect(() => {
    if (create_inv?.data?.error) {
      message.error(
        <span className="messageText">{create_inv?.data?.message}</span>
      );
      dispatch({
        type: CreateInvoice.RESET,
      });
    }

    if (create_inv?.data?.error === false) {
      setRemovedItemsData([]);
      // message.success(<span className='messageText'>{"Created Invoice"}</span>)
      dispatch({
        type: CreateInvoice.RESET,
      });
      // if (dataBeneficiary?.id && !isSubmit) {
      //   fetchInvDetails();
      // }

      if (listFiles?.length) {
        const formData = new FormData();
        Array.from(listFiles).forEach((ele) => {
          if (!ele?.isEdit) formData.append("files", ele);
          formData.append("name", ele?.name);
        });
        const arr = listFiles.filter((ele) =>
          File.prototype.isPrototypeOf(ele)
        );
        if (arr?.length > 0) {
          formData.append("record_type", "invoice");
          formData.append("record_id", create_inv?.data?.data);
          uploadFile(formData);
        }
      }
      // && (!dataBeneficiary?.id || location?.state?.convert || location?.state?.isClone)
      if (isSubmit) {
        let module_type = "INVOICE";
        if (isEstimates) {
          module_type = "ESTIMATE";
        } else if (isSales) {
          module_type = "SALES_ORDER";
        } else if (isCN) {
          module_type = "CREDIT";
        }
        dispatch(submit_invoice({ id: create_inv?.data?.data, module_type }));
      } else {
        if (create_inv?.data?.error === false) {
          message.success(
            <span className="messageText">
              {isEstimates
                ? location?.state?.isEdit
                  ? t("updated_estimates")
                  : t("created_estimates")
                : isSales
                ? location?.state?.isEdit
                  ? t("updated_sale_order")
                  : t("created_sale_order")
                : location?.state?.isEdit
                ? t("updated_invoice")
                : t("created_invoice")}
            </span>
          );
        }
        resetAct();
        const tim = setTimeout(() => {
          handleBack(location?.state?.to);
          clearTimeout(tim);
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create_inv]);

  const setExchangeCurrency = (id) => {
    const customer = customers?.find((ele) => ele.value === id);
    setNoExError("");
    if (customer?.currency_info?.id) {
      setHasExchange(
        customer?.company_info?.currency_id !==
          customer?.currency_info?.currency_id
      );
      setCmpCurId(customer?.currency_info?.id);
      endpointSettingsApi
        .get(apis.getCompanyBaseCurrency, {
          params: { company_currency_id: customer?.currency_info?.id },
        })
        .then((res) => {
          if (
            !res?.data?.data?.live_exchange_rates?.rate &&
            !res?.data?.data?.manual_exchange_rates?.rate
          ) {
            message.error(
              <span className="messageText">{res?.data?.message}</span>
            );
            setHasExchange(false);
            setNoExError(res?.data?.message);
          }
          setHasExchange(true);
          setExchange(res?.data?.data);
        })
        .catch((e) => {
          message.error(
            <span className="messageText">{e?.response?.data?.message}</span>
          );
        });
    } else {
      setHasExchange(false);
      setExchange({});
    }
  };

  useEffect(() => {
    if (dataBeneficiary && dataBeneficiary?.beneficiary_id) {
      setExchangeCurrency(dataBeneficiary?.beneficiary_id);
      setValue("beneficiary_id", dataBeneficiary?.beneficiary_id);
      setValue("customer_name", dataBeneficiary?.customer_name);
      if (!location?.state?.convert && !location.state?.isClone)
        setValue("invoice_no", dataBeneficiary?.invoice_no);
      setValue("invoice_date", moment(dataBeneficiary?.invoice_date));
      setValue(
        "ref_po_no",
        location?.state?.convert
          ? dataBeneficiary?.invoice_no
          : dataBeneficiary?.ref_po_no
      );
      setValue("gl_category", dataBeneficiary?.gl_category);
      setValue("gl_code", dataBeneficiary?.gl_code);
      setValue("delivery_charges", dataBeneficiary?.delivery_charges);
      setValue(
        "payment_terms",
        dataBeneficiary?.payment_terms
          ? dataBeneficiary?.payment_terms
          : "custom"
      );
      setValue("place_of_supply", dataBeneficiary?.place_of_supply);
      setValue("payment_status", dataBeneficiary?.payment_status);
      setValue(
        "transaction_ref_details",
        dataBeneficiary?.transaction_ref_details
      );
      setValue("total_amount", dataBeneficiary?.total_amount);
      setValue("total_tax_amount", dataBeneficiary?.total_tax_amount);
      setValue("total_discount", dataBeneficiary?.total_discount);
      setValue("total_payable_amount", dataBeneficiary?.total_payable_amount);
      setValue("tax", dataBeneficiary?.vendor_tds_types?.tax_name);
      setValue("remarks", dataBeneficiary?.remarks);
      setValue(
        "po_due_date",
        dataBeneficiary?.po_due_date
          ? moment(dataBeneficiary?.po_due_date)
          : dataBeneficiary?.po_due_date
      );
      setValue(
        "customer_invoice_items",
        isCN ? dataBeneficiary?.line_items : dataBeneficiary?.invoice_items
      );
      setValue("notes", dataBeneficiary?.notes);
      if (isCN) {
        setValue("reason", dataBeneficiary?.reason);
        setValue("invoice_id", dataBeneficiary?.invoice_id);
        setValue("invoice_ref_number", dataBeneficiary?.invoice_ref_number);
        setValue("invoice_type", dataBeneficiary?.invoice_type);
        setValue("invoice_no", dataBeneficiary?.credit_debit_note_number);
        setValue("ref_po_no", dataBeneficiary?.reference_number);
      }
      if (!isCN && !isEstimates && !isSales) {
        setValue("partial_payments", dataBeneficiary?.partial_payments);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBeneficiary]);

  useEffect(() => {
    if (submit_inv?.data?.error) {
      message.error(
        <span className="messageText">{submit_inv?.data?.message}</span>
      );
      dispatch({
        type: SubmitInvoice.RESET,
      });
    }

    if (submit_inv?.data?.error === false) {
      message.success(
        <span className="messageText">
          {isEstimates
            ? t("create_submitted_estimates")
            : isSales
            ? t("create_submit_sale_order")
            : t("create_submit_invoice")}
        </span>
      );
      dispatch({
        type: SubmitInvoice.RESET,
      });
      // if (dataBeneficiary?.id) {
      //   fetchInvDetails();
      // } else {
      //   dispatch(invoices());
      // }
      resetAct();
      const tim = setTimeout(() => {
        handleBack(location?.state?.to);
        clearTimeout(tim);
      }, 100);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit_inv]);

  const handleBack = (to) => {
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  };
  const scrollToError = (errorFieldName) => {
    const errorField = document.querySelector(`[name=${errorFieldName}]`);
    if (errorField) {
      errorField.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  if (Object.keys(errors).length >= 1) {
    scrollToError(Object.keys(errors)?.[0]);
  }

  return (
    <>
      <AddPayTermsModal
        companyId={localStorage.getItem("company_id")}
        isVisible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
      />
      <AddTaxModal
        companyId={localStorage.getItem("company_id")}
        isVisible={isAddTax}
        onCancel={() => {
          setIsAddTax(false);
        }}
      />
      <AddCategory
        companyId={localStorage.getItem("company_id")}
        isVisible={isAddCategory}
        onCancel={() => {
          setIsAddCategory(false);
        }}
      />
      <AddCustomer
        open={openAddNew}
        onClose={() => {
          setOpenAddNew(false);
        }}
      />

      <form className="new-bill-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="head">
          <label>
            {isCN
              ? location?.state?.isEdit
                ? t("edit_credit_note")
                : t("new_credit_note")
              : isSales
              ? location?.state?.isEdit
                ? t("edit_sales")
                : t("new_sales")
              : isEstimates
              ? location?.state?.isEdit
                ? t("edit_estimates")
                : t("new_estimates")
              : location?.state?.isEdit
              ? t("edit_invoice")
              : t("new_invoice")}
          </label>
          <span className="close cursor mr2">
            <img
              src={Close}
              width={20}
              alt={"close"}
              onClick={() => handleBack()}
            />
          </span>
        </div>
        <div className="w40 pl2">
          <div className="pp-form-item">
            <FormSelect
              inline
              control={control}
              options={customers}
              {...register("beneficiary_id", {
                required: t("customer_info_required"),
                onChange: (ben_id) => {
                  setExchangeCurrency(ben_id.target.value);
                },
              })}
              required
              errors={errors}
              onSearch={(term) => {
                if (searchRef.current) {
                  clearTimeout(searchRef.current);
                }
                searchRef.current = setTimeout(() => {
                  dispatch(
                    customers_action({
                      type: "customer",
                      beneficiary_name: term,
                      status: "active",
                      isName: true,
                    })
                  );
                }, 600);
              }}
              label={t("customer_info")}
              placeholder={t("enter_customer_name")}
              onAddItem={() => {
                setOpenAddNew(true);
              }}
            />
          </div>
          {watch("beneficiary_id") && (
            <div
              style={{
                backgroundColor: "#F5F7FA",
                padding: "30px 27px",
                marginTop: "20px",
                fontSize: "17px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "17px",
                    lineHeight: "26px",
                    color: "#000000",
                  }}
                >
                  {t("customer_Details")}
                </span>

                {/* <a style={{ color: '#ED3B41', fontSize: '17px' }}>Edit</a> */}
              </div>

              <Col style={{ paddingTop: "10px" }}>
                <div>
                  {t("name")} -{" "}
                  {
                    customers?.find(
                      (ele) => ele.value === getValues().beneficiary_id
                    )?.beneficiary_name
                  }
                  <br />
                  {t("email")} -{" "}
                  {
                    customers?.find(
                      (ele) => ele.value === getValues().beneficiary_id
                    )?.email
                  }
                  <br />
                  {/* Address - {customers?.find(ele => ele.value === getValues().beneficiary_id)?.email} */}
                </div>
              </Col>
            </div>
          )}
          {isCN && (
            <div className="pp-form-item">
              <FormSelect
                inline
                control={control}
                {...register("reason", {
                  required: t("select_reason"),
                })}
                errors={errors}
                label={t("reason")}
                required
                placeholder={t("select")}
                options={reasons}
                hideCreateOption
              />
            </div>
          )}
          {beneficiary_id && isCN && (
            <>
              <div className="pp-form-item">
                <FormSelect
                  inline
                  control={control}
                  {...register("invoice_id", {
                    onChange: (e) => {
                      const inv = invoices_data?.find(
                        (ele) => ele?.value === e.target?.value
                      );
                      setValue("invoice_ref_number", inv?.label);
                      endpointPayApi
                        .get(apis.getCustomerInvoiceByID, {
                          params: {
                            invoice_id: inv?.value,
                            module_type: "INVOICE",
                          },
                        })
                        .then((res) => {
                          if (res?.data?.error === false) {
                            setItemsData(res?.data?.data?.invoice_items);
                          }
                        });
                    },
                  })}
                  errors={errors}
                  label={t("invoice_hashh")}
                  placeholder={t("select")}
                  options={invoices_data}
                  hideCreateOption
                />
              </div>
              <div className="pp-form-item">
                <FormSelect
                  control={control}
                  {...register("invoice_type")}
                  errors={errors}
                  label={t("invoice_type")}
                  required
                  hideCreateOption
                  options={inv_type}
                />
              </div>
            </>
          )}
          <div style={{ marginTop: "40px" }}>
            <span className="info">
              {isCN
                ? "CREDIT NOTE"
                : isEstimates
                ? "ESTIMATE"
                : isSales
                ? "SALES"
                : "INVOICE"}{" "}
              INFO
            </span>
            {isEstimates || isSales || isCN ? null : (
              <div
                className="pp-form-item justify-between"
                style={{ width: "140%" }}
              >
                <div className="enable-portal">
                  <span>Enable Partial Payments?</span>
                </div>
                <div className="enable-portal-label">
                  <Checkbox
                    style={{ float: "left" }}
                    checked={watch("partial_payments")}
                    name={"partial_payments"}
                    onChange={(e) => {
                      setValue("partial_payments", e?.target?.checked);
                    }}
                  ></Checkbox>
                  <label>Allow Partial Payments access for this Invoice</label>
                </div>
              </div>
            )}

            <div className="pp-form-item">
              <DateInput
                control={control}
                {...register(isCN ? "date" : "invoice_date", {
                  required: `${
                    isCN
                      ? "Credit Note Date"
                      : isSales
                      ? t("sales_order_date")
                      : isEstimates
                      ? t("estimate_date")
                      : t("invoice_date")
                  } is required`,
                  onChange: () => {
                    setValue("po_due_date", "");
                  },
                })}
                errors={errors}
                isTime={true}
                required
                label={
                  isCN
                    ? t("credit_note_date")
                    : isSales
                    ? t("sales_order_date")
                    : isEstimates
                    ? t("estimate_date")
                    : t("invoice_date")
                }
              />
            </div>

            <div className="pp-form-item">
              <FormInput
                control={control}
                {...register("invoice_no", {
                  required: `${
                    isCN
                      ? t("note_credit")
                      : isSales
                      ? t("order_sale")
                      : isEstimates
                      ? t("estimate")
                      : t("invoice")
                  } is required`,
                })}
                required
                errors={errors}
                label={
                  isCN
                    ? t("credit_note")
                    : isSales
                    ? t("order_salee")
                    : isEstimates
                    ? t("estimate_hash")
                    : t("invoice_hashh")
                }
              />
            </div>

            {!isEstimates && (
              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("ref_po_no", {
                    required: `${
                      isSales || isCN ? t("reference_hash") : t("order_salee")
                    } is required`,
                  })}
                  errors={errors}
                  required={true}
                  label={
                    isSales
                      ? t("estimate_hash")
                      : isCN
                      ? t("reference_hash")
                      : t("order_salee")
                  }
                />
              </div>
            )}
            {isEstimates || isCN ? null : (
              <div className="pp-form-item">
                <FormSelect
                  inline
                  control={control}
                  {...register("payment_terms", {
                    required: t("select_payment_term"),
                    onChange: (e) => {
                      let num = payment_terms?.find(
                        (ele) => ele.value === e.target.value
                      )?.number_of_days;
                      setValue(
                        "po_due_date",
                        moment(getValues().invoice_date).add(num, "days")
                      );
                    },
                  })}
                  errors={errors}
                  required={true}
                  label={t("payment_term")}
                  placeholder={t("select")}
                  options={[
                    ...payment_terms,
                    { label: "Custom", value: "custom" },
                  ]}
                  onAddItem={() => {
                    setIsVisible(true);
                  }}
                />
              </div>
            )}
            {isCN ? (
              <div className="pp-form-item">
                <FormSelect
                  control={control}
                  {...register("place_of_supply")}
                  errors={errors}
                  hideCreateOption={true}
                  label={t("source_of_supply")}
                  options={placesSupplies}
                />
              </div>
            ) : (
              <div className="pp-form-item">
                <DateInput
                  control={control}
                  {...register("po_due_date", {
                    onChange: (date) => {
                      setValue("payment_terms", "custom");
                    },
                  })}
                  errors={errors}
                  minDate={
                    isEstimates
                      ? getValues().invoice_date
                      : isSales
                      ? new Date()
                      : null
                  }
                  isTime={true}
                  label={
                    isSales
                      ? t("expected_shipment_date")
                      : isEstimates
                      ? t("expiry_date")
                      : t("due_date")
                  }
                />
              </div>
            )}
          </div>

          {isCN || isSales || isEstimates ? null : (
            <div style={{ marginTop: "40px" }}>
              <span className="info">{t("general_ledger")}</span>

              <div className="pp-form-item">
                <FormSelect
                  inline
                  control={control}
                  {...register("gl_category", {
                    required: "Category is required",
                  })}
                  required={true}
                  errors={errors}
                  label={t("category")}
                  hideCreateOption={true}
                  placeholder={t("select")}
                  // options={fields ? fields?.find(ele => ele.field_name === "category_id")?.default_values?.map(ele => ({ label: ele.value, value: ele.id })) : []}
                  options={
                    Array.isArray(categoriesResponse)
                      ? categoriesResponse?.map((item) => ({
                          value: item?.id,
                          label: item?.name,
                        }))
                      : []
                  }
                />
              </div>

              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("gl_code")}
                  errors={errors}
                  label="GL Code"
                />
              </div>
            </div>
          )}
          {custom_fields_values?.data?.length && !isCN ? (
            <div style={{ marginTop: "40px" }}>
              <span className="info">{t("custom_fields")}</span>
              <CustomField
                setValue={setValue}
                name={"customFields"}
                watch={watch}
                fields={custom_fields_values?.data}
                register={register}
                control={control}
                errors={errors}
                type={
                  isEstimates ? "ESTIMATE" : isSales ? "SALES_ORDER" : "INVOICE"
                }
                loadingRef={(bool) => setLoadingRef(bool)}
              />
            </div>
          ) : null}
        </div>
        {hasExchange &&
        exchange?.base_currency !== exchange?.convert_currency ? (
          <div className="mt1" style={{ position: "relative" }}>
            <div className="exchange-rate">
              <h5>Currency Exchange Rates: </h5>
              <p key={exchange?.convert_currency}>
                1 {exchange?.convert_currency} = {convertCurrency(1, exchange)}{" "}
                {exchange?.base_currency}
              </p>
            </div>
          </div>
        ) : null}
        <div className="p2">
          <ItemsTable
            control={control}
            exchange={exchange}
            hasExchange={hasExchange}
            errors={errors}
            name={"customer_invoice_items"}
            isCN={isCN}
            register={register}
            vals={getValues()}
            items={
              itemsData?.length
                ? itemsData
                : dataBeneficiary?.[isCN ? "line_items" : "invoice_items"]
            }
            setValue={setValue}
            watch={watch}
            isInvoice={true}
            isEdit={location?.state?.isEdit}
            isConvertOrClone={
              location?.state?.isClone || location?.state?.convert
            }
            setRemovedItemsData={setRemovedItemsData}
            fromReceipts={true}
          />
        </div>
        <div className="w40 pl2 mb2">
          <div className="pp-form-item mb2">
            <FormInput
              control={control}
              {...register("notes")}
              errors={errors}
              label={t("notes")}
              type={"textarea"}
              rows={3}
            />
          </div>
          <UploadField
            title={`Attach File(s) to ${
              isSales ? t("sales") : isEstimates ? t("estimate") : t("invoices")
            }`}
            desc={t("material_desc")}
            subDescripcion={t("inv_desc")}
            acceptType={".jpg,.pdf,.png"}
            onSelected={(fileInfo) => {
              let listAux = [...listFiles];
              listAux.push(fileInfo);
              setListFiles(listAux);
            }}
            filesList={listFiles}
            onRemove={(index) => {
              let listAux = [...listFiles];
              if (listAux[index].isEdit) {
                DocumentDelete({
                  data: {
                    ids: [listAux[index].uid],
                  },
                  params: { record_id: location.state?.id },
                  headers: {
                    "Content-Type": "multipart/formdata",
                  },
                });
              }
              listAux.splice(index, 1);
              setListFiles(listAux);
            }}
          />
        </div>
        <div className="bottom">
          {noExError && <div className="errorMsg">{noExError}</div>}
          <Button
            className="pp-secondary-button"
            style={{ marginLeft: "2rem" }}
            htmlType="button"
            onClick={() => handleBack()}
          >
            <span>{t("cancel")}</span>
          </Button>

          {(data?.data?.[0]?.seed_value !== "without hierarchy" || isCN) && (
            <Button
              type="primary"
              htmlType="submit"
              className="pp-main-button"
              id="save"
              disabled={
                invoiceResponse?.loading ||
                submit_inv?.loading ||
                create_inv?.loading ||
                noExError ||
                loadingRef
              }
            >
              <span>{location?.state?.isEdit ? t("update") : t("save")}</span>
            </Button>
          )}
          {location?.state?.isEdit &&
            data?.data?.[0]?.seed_value === "without hierarchy" && (
              <Button
                type="primary"
                htmlType="submit"
                className="pp-main-button"
                id="save-and-submit"
                disabled={
                  invoiceResponse?.loading ||
                  submit_inv?.loading ||
                  noExError ||
                  create_inv?.loading
                }
              >
                <span>{t("update")}</span>
              </Button>
            )}
          {!location?.state?.isEdit && !isCN && (
            <Button
              type="primary"
              htmlType="submit"
              className="pp-main-button"
              id="save-and-submit"
              disabled={
                create_inv?.loading ||
                invoiceResponse?.loading ||
                submit_inv?.loading ||
                noExError ||
                loadingRef
              }
            >
              <span>{t("save_and_submit")}</span>
            </Button>
          )}
        </div>
      </form>
      {/* </Modal> */}
    </>
  );
};

export default NewInvoice;
