import React, { useEffect, useState } from "react";
import close from "../../assets/icons/close.png";
import BalanceAlerts, {
  AccountActivity,
  CardActivity,
  CreditActivity,
} from "./AlertsNSecurityComps/BalanceAlerts";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
const Alerts = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("balanceAlerts");
  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Alerts",
        description: "Configure alerts",
      })
    );
  }, []);
  return (
    <div className="upload_payment_parent alerts">
      <div className="sec-head">
        <h2>Alerts</h2>
        <p className="alert-info">
          All alerts will be sent to <strong>{localStorage.getItem("user")}</strong>
        </p>
        <img src={close} width={20} className="close" />
      </div>
      <ul className="tab-menu">
        <li
          className={activeTab === "balanceAlerts" ? "active" : ""}
          onClick={() => setActiveTab("balanceAlerts")}
        >
          Balance alerts
        </li>
        <li
          className={activeTab === "accountActivity" ? "active" : ""}
          onClick={() => setActiveTab("accountActivity")}
        >
          Account activity
        </li>
        <li
          className={activeTab === "credit" ? "active" : ""}
          onClick={() => setActiveTab("credit")}
        >
          Credit
        </li>
        <li
          className={activeTab === "myCards" ? "active" : ""}
          onClick={() => setActiveTab("myCards")}
        >
          My cards
        </li>
        {/* <li
          className={activeTab === "zenusUpdates" ? "active" : ""}
          onClick={() => setActiveTab("zenusUpdates")}
        >
          Zenus updates
        </li> */}
      </ul>
      <div className="p2">
        {/* Conditional rendering of tab contents */}
        <div className="tab-content">
          {activeTab === "balanceAlerts" && <BalanceAlerts />}

          {activeTab === "accountActivity" && <AccountActivity />}

          {activeTab === "credit" && <CreditActivity />}

          {activeTab === "myCards" && <CardActivity />}

          {activeTab === "zenusUpdates" && (
            <div>
              <h3>Zenus Updates</h3>
              <p>Zenus updates content goes here...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alerts;
