import { Button, message } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { gridApis } from "../../../../config/GridApis";
import usePayAxios from "../../../../config/useAxios";
import { LeftNavContext } from "../../../../LeftNavProvider";
import { OnboardCreate, onboardCreate } from "../../action/OnBoardForm";
import KYCSafe from "../../assets/icons/kyc-safe-icon.svg";
import BusinessKYCFields from "./BusinessKYCFields";
import { page_names } from "./steps";
import SuccessPage from "./SuccessPage";

const BusinessMainKYC = () => {
  const dispatch = useDispatch();
  const { formStep, setFormStep } = useContext(LeftNavContext);
  const [fileData, setFileData] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const onboardPageDetails = useSelector(
    (state) => state?.on_board?.getOnboardFields
  );

  const onboardCreateResponse = useSelector(
    (state) => state?.on_board?.onboardCreate
  );
  const allCountries = useSelector((state) =>
    state?.companySetting?.getAllCountries?.data?.data?.map((item, index) => ({
      ...item,
      value: item?.country_iso_code,
      label: (
        <span
          style={{
            color: "#212121",
            fontSize: 15,
          }}
        >
          <img
            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_iso_code?.toLowerCase()}.svg`}
            className="mr1"
            width={25}
            style={{
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
            }}
          />
          {`${item?.country_name} (${item?.country_iso_code})`}
        </span>
      ),
    }))
  );

  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {},
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (localStorage.getItem("step")) {
      setFormStep(localStorage.getItem("step"));
    }
  }, []);

  useEffect(() => {
    if (onboardCreateResponse?.data?.error === false) {
      message.success(
        <span className="messageText">
          {onboardCreateResponse?.data?.message}
        </span>
      );
      if (!onboardCreateResponse?.data?.data?.accountDetails)
        dispatch({
          type: OnboardCreate.RESET,
        });
      setFormStep((b) => {
        localStorage.setItem("step", Number(b) + 1);
        return Number(b) + 1;
      });
    }
    if (onboardCreateResponse?.data?.error) {
      message.error(
        <span className="messageText">
          {onboardCreateResponse?.data?.message}
        </span>
      );
      dispatch({
        type: OnboardCreate.RESET,
      });
    }
  }, [onboardCreateResponse]);

  const onSubmit = (data, event) => {
    if (event.nativeEvent?.submitter?.id === "preview") {
      setIsPreview(true);
    }
    data.step = Number(formStep);
    data.page_name = page_names[formStep];
    if (data?.document_expiry) {
      data.document_expiry = moment(data?.document_expiry).format("MM/YY");
    }
    if (formStep == 6) {
      delete data.confirm_password;
      data.account_type = "savings";
    }
    dispatch(
      onboardCreate({
        ...data,
      })
    );
  };
  return (
    <>
      {(onboardPageDetails?.loading || onboardCreateResponse?.loading) && (
        <div className="text-center loadingDiv">
          <PulseLoader color="#fff" />
        </div>
      )}
      <div className="business-main-section">
        <div className="business-main-container">
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`form-section i${formStep}`}>
                {formStep < 7 ? (
                  <BusinessKYCFields
                    control={control}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    watch={watch}
                    setFileData={setFileData}
                    fileData={fileData}
                    clearErrors={clearErrors}
                    allCountries={allCountries}
                    formStep={formStep}
                  />
                ) : (
                  <SuccessPage
                    accountDetails={
                      onboardCreateResponse?.data?.data?.accountDetails
                    }
                  />
                )}

                {formStep > 2 && formStep < 7 ? (
                  <div className="form-button mt5 flex-between">
                    <div className="button-div">
                      {formStep > 3 ? (
                        <button
                          className="back-btn"
                          type="button"
                          onClick={() => {
                            setFormStep((b) => b - 1);
                          }}
                          style={{ marginRight: "2rem" }}
                        >
                          Back
                        </button>
                      ) : null}
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="pp-main-button "
                        id="save"
                        loading={onboardCreateResponse?.loading}
                      >
                        Save & Next
                      </Button>
                    </div>
                    <div className="text-div">
                      <img src={KYCSafe} alt="kyc-safe-icon" />
                      <span className="text fs-20 fw-M">
                        We take data seriously and your info is safe with us
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default BusinessMainKYC;
