import { Button, message } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { gridApis } from "../../../../config/GridApis";
import usePayAxios from "../../../../config/useAxios";
import { FormInput, FormSelect } from "../../../inputs";
import DateInput from "../../../inputs/DateInput";
export const countries = [
  { code: "US", dial_code: "+1", flag: "🇺🇸" },
  { code: "IN", dial_code: "+91", flag: "🇮🇳" },
  { code: "GB", dial_code: "+44", flag: "🇬🇧" },
  { code: "AU", dial_code: "+61", flag: "🇦🇺" },
];
const UserDetailsForm = ({
  register,
  control,
  errors,
  watch,
  onSuccess,
  handleSubmit,
  reset,
  setUserData,
  pageCount = "",
  setValue,
  userData,
}) => {
  useEffect(() => {
    reset();
  }, []);

  const { t } = useTranslation();

  const { onCall: createAccount, loading } = usePayAxios({
    api: gridApis.onBoardUser,
    method: "post",
    type: "zenus",
  });

  const formFields = [
    {
      name: "first_name",
      label: "First Name",
      placeholder: t("enter_first_name"),
      validation: { required: t("first_name_required") },
      width: 50,
    },
    {
      name: "middle_name",
      label: "Middle Name",
      placeholder: "Enter Middle Name",
      validation: { required: "Middle Name is required" },
      width: 50,
    },
    {
      name: "last_name",
      label: t("last_name"),
      placeholder: "Enter Last Name",
      validation: { required: "Last Name is required." },
    },
    {
      name: "email",
      label: t("email"),
      placeholder: t("enter_email"),
      validation: {
        required: t("email_required"),
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: t("enter_valid_Email"),
        },
      },
    },
    {
      name: "dob",
      label: "Date of Birth",
      placeholder: "Select DOB",
      validation: { required: "Date of Birth is required" },
      type: "date",
      width: 50,
    },
    {
      name: "gender",
      label: "Gender",
      placeholder: "Select Gender",
      validation: { required: t("last_name_required") },
      type: "dropdown",
      width: 50,
      options: [
        {
          label: "Male",
          value: "Male",
        },
        {
          label: "Female",
          value: "Female",
        },
        {
          label: "Others",
          value: "Others",
        },
      ],
    },
    {
      name: "primary_phone",
      label: "Mobile Number",
      type: "mobile_number",
      placeholder: "Enter Mobile Number",
      validation: { required: "Mobile Number is required" },
    },
  ];

  const onSubmit = (data) => {
    data.step = 1;
    data.page_name = "USER_DETAILS";
    data.product_type = "savings";
    createAccount({
      data,
      params: {
        device_type: "web",
      },
    })
      .then((res) => {
        if (res.error === false) {
          message.success(<span className="messageText">{res?.message}</span>);
          setUserData({ ...data, otp_token: res?.data?.otp_token });
          onSuccess();
        } else if (res.error) {
          message.error(<span className="messageText">{res?.message}</span>);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message)
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
      });
  };

  useEffect(() => {
    formFields?.forEach((formFieldsElements) => {
      setValue(
        `${formFieldsElements?.name}`,
        userData?.[formFieldsElements?.name]
      );
    });
  }, [pageCount]);
  return (
    <React.Fragment>
      <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
        {formFields.map((field, index) => (
          <div
            key={index}
            className={`pp-form-item ${`item-${index}`}  pt0`}
            style={{ paddingTop: "15px" }}
          >
            {field.type === "date" ? (
              <DateInput
                control={control}
                {...register(field.name, field.validation)}
                errors={errors}
                label={field.label}
                placeholder={field.placeholder}
                required={true}
                isTime={false}
                dateFormat={["DD-MM-YYYY"]}
              />
            ) : field.type === "dropdown" ? (
              <FormSelect
                inline
                control={control}
                {...register(field.name, field.validation)}
                errors={errors}
                label={field.label}
                placeholder={field.placeholder}
                //   onSearch={onVendorSearch}
                options={field.options}
                //   onAddItem={handlerNewVendor}
                hideCreateOption={true}
                required={true}
                noPaddingTop={true}
              />
            ) : (
              <div className={field.type}>
                {field.type === "mobile_number" && (
                  <FormSelect
                    inline
                    control={control}
                    style={{ width: "100%" }}
                    {...register("phone_country_code")}
                    options={countries?.map((item) => ({
                      value: item?.dial_code,
                      label: (
                        <span
                          style={{
                            color: "#212121",
                            fontSize: 13,
                          }}
                        >
                          <img
                            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.code?.toLowerCase()}.svg`}
                            className="mrHalf"
                            width={20}
                            style={{
                              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                            }}
                          />
                          {`(${item?.dial_code})`}
                        </span>
                      ),
                    }))}
                    hideCreateOption={true}
                    noPaddingTop={true}
                    noSearch={true}
                  />
                )}
                <FormInput
                  control={control}
                  {...register(field.name, field.validation)}
                  errors={errors}
                  label={field.label}
                  placeholder={field.placeholder}
                  type={field.type}
                  required={true}
                  number={field.type === "number" ? true : false}
                />
              </div>
            )}
          </div>
        ))}
        <div className={`pp-form-item`}>
          <Button
            type="primary"
            loading={loading}
            className="pp-main-button"
            id="first"
            htmlType="submit"
          >
            Get Started
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default UserDetailsForm;
